<template>
  <div>
     <header class="route-header">
       <div class="route-header--left">
         <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
       </div>
       <div class="route-header--right">
         <h1 class="text-3xl">Enter Live Operations Data</h1>
       </div>
     </header>
 
     <div class="route-header items-center secondary">
          <div class="route-header--box">
            <div class="box">
              <span class="box-header">Location</span>
              <span class="box-label">BDF - Bahrain</span>
            </div>
          </div>
          <div class="route-header--box">
            <div class="box">
              <span class="box-header">Hospital</span>
              <span class="box-label">
                <v-select
                  v-model="selectedHospital"
                  label="speciality"
                  :clearable="false"
                  @input="fetchData"
                  :options="$store.state.prototypedata.hospitals"
                />
              </span>
            </div>
          </div>
          <div class="route-header--box">
            <div class="box">
              <span class="box-header">Date</span>
              <span class="box-label"><datepicker v-model="datetime" placeholder="Select Date" @selected="selected"></datepicker></span>
            </div>
          </div>
          <div class="route-header--box">
            <div class="box">
              <span class="box-header">Total Population</span>
              <span class="box-label">495,000</span>
            </div>
          </div>
        </div>

        <div class="route-content">
        <!-------->
        
   <div
              class="data-entry-stat-cards--item single4 no-hover"
              :class="activeSummary === 'assets' ? 'active' : ''"
            >
              <!-- 2 open -->
              <span class="card-header">Hospital Data</span>
              <div class="card-icon">
                <!-- 3 open -->
                <img src="@/assets/img/icons/icon-patient.svg" alt="Hospital Data" />

                <div class="flex flex-col">
                  <!-- 4 open -->
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <!-- 5 open -->
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <!-- 6 open -->
                      <div class="overflow-hidden">
                        <!-- 7 open-->
                        <table class="min-w-full">
                          <thead class="border-b">
                            <tr>
                              <th scope="col" width="340px" class="text-sm font-medium text-gray-900 text-left">Item</th>
                              <th scope="col" class="text-sm font-medium text-gray-900 text-left">Value</th>                          
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Patient Episodes Surgical</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="text" v-model="pe_surgical" /></td>
                            </tr>                            
                            <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Patient Episodes Cath Lab</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="pe_cath_lab" /></td>
                             </tr>             
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Patient Episodes Endoscopy</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="pe_end" /></td>
                             </tr>  
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Patient Episodes Chemotherapy</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="pe_chemo" /></td>
                             </tr>  
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Patient Episodes Dialysis</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="pe_dial" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Patient Episodes Diagnostics</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="pe_diag" /></td>
                             </tr>  
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Patient Episodes OPD New</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="pe_opd_new" /></td>
                             </tr>  
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Patient Episodes OPD Follow-Up</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="pe_opd_foll" /></td>
                             </tr>          
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Ratio New:Follow-Up</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="rat_new" /></td>
                             </tr>  
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Target Ratio New:Follow-Up</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="tar_rat" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Patient Episodes Medical Admissions</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="pe_med_adm" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ER Wait Current</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="er_wa_cu" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ER Wait Max. (last 30 days)</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="er_wa_ma" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ER Attendances</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="er_att" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ER Admissions</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="er_adm" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Discharges</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="disc" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Delayed Discharges</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="de_di" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Cancellations</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="can" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>DNA</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="dna1" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ICU Beds Available</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="icu_bed_av" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ICU Beds % Utilized</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="icu_bed_ut" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ALOS</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="icu_alos" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>CCU/HDU Beds Available</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="ccu_bed_av" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>CCU/HDU Beds % Utilized</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="ccu_bed_ut" /></td>
                             </tr>                           
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ALOS</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="ccu_alos" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Surgical Beds Available</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="sur_bed_av" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Surgical Beds % Utilized</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="sur_bed_ut" /></td>
                             </tr> 
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ALOS</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="sur_bed_alos" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Day Case Beds Available</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"><t-input value="1" name="currentEpisodes" v-model="day_cas_bed_av" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Day Case Beds % Utilized</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="day_cas_bed_ut" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Burns Beds Available</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="bur_bed_av" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Burns Beds % Utilized</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="bur_bed_ut" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ALOS</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="bur_bed_alos" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Medical Beds Available</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="med_bed_av" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Medical Beds % Utilized</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="med_bed_ut" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ALOS</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="med_bed_alos" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Obstetrics Beds Available</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="obs_bed_av" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Obstetrics  Beds % Utilized</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="obs_bed_ut" /></td>
                             </tr>                          
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ALOS</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="obs_bed_alos" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>L&D Beds Available</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="ld_bed_av" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>L&D Beds Utilized</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="ld_bed_ut" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ALOS</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="ld_bed_alos" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Paediatric Beds Available</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="pae_bed_av" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Paediatric Beds % Utilized</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="pae_bed_ut" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ALOS</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="pae_bed_alos" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>PICU Beds Available</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="pic_bed_av" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>PICU Beds % Utilized</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="pic_bed_ut" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ALOS</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="pic_bed_alos" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>NICU Beds Available</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="nic_bed_av" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>NICU Beds % Utilized</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="nic_bed_ut" /></td>
                             </tr>

                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>ALOS</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="nic_bed_alos" /></td>
                             </tr>
                          </tbody>                         
                        </table>
                      </div>
                      <!-- 7 close-->
                    </div>
                    <!-- 6 close-->
                  </div>
                  <!-- 5 close -->
                </div>
                <!-- 4 close -->
              </div>
              <!-- 3 close -->
              <span class="card-info"></span>
          </div>

          <div
              class="data-entry-stat-cards--item single4 no-hover"
              :class="activeSummary === 'assets' ? 'active' : ''"
            >
              <!-- 2 open -->
              <span class="card-header">Asset and Finance</span>
              <div class="card-icon">
                <!-- 3 open -->
                <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />

                <div class="flex flex-col">
                  <!-- 4 open -->
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <!-- 5 open -->
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <!-- 6 open -->
                      <div class="overflow-hidden">
                        <!-- 7 open-->
                        <table class="min-w-full">
                          <thead class="border-b">
                            <tr>
                              <th scope="col" width="340px" class="text-sm font-medium text-gray-900 text-left">Item</th>
                              <th scope="col" class="text-sm font-medium text-gray-900 text-left">Value</th>                       
                            </tr>
                          </thead>
                          <tbody>
                            <!-- 




                            -->
                            <tr class="border-b">
                               <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Assets Total Beds Operational</div></td>
                               <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="ass_tot_bed_ope" /></td>
                            </tr>  
                            <tr class="border-b">
                               <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Assets Cath Labs Operational</div></td>
                               <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="ass_cat_lab_ope" /></td>
                            </tr>  
                            <tr class="border-b">
                               <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Assets Diagnostics Operational</div></td>
                               <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="ass_diag_ope" /></td>
                            </tr>  
                            <tr class="border-b">
                               <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Asssets Endoscopy Operational</div></td>
                               <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="ass_end_ope" /></td>
                            </tr>  
                            <tr class="border-b">
                               <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Assets Operating Theatre Operational</div></td>
                               <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="ass_ope_the_ope" /></td>
                            </tr>  
                            <tr class="border-b">
                               <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Assets OPD Operational</div></td>
                               <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="ass_opd_ope" /></td>
                            </tr>  
                            <tr class="border-b">
                               <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Assets Dialysis Operational</div></td>
                               <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="ass_dia_ope" /></td>
                            </tr>  
                            <tr class="border-b">
                               <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Assets Chemotherapy Operational</div></td>
                               <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="ass_che_ope" /></td>
                            </tr>  
                            <tr class="border-b">
                               <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Finance Income Private Actual</div></td>
                               <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_inc_pri_act" /></td>
                            </tr>
                            <tr class="border-b">
                                <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Finance Income Private Target</div></td>
                                <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_inc_pri_tar" /></td>
                             </tr>
                             <tr class="border-b">
                                <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Finance Workforce Cost Actual</div></td>
                                <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_cos_act" /></td>
                             </tr>
                             <tr class="border-b">
                                 <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Finance Workforce Cost Target</div></td>
                                 <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_cos_tar" /></td>
                              </tr>
                              <tr class="border-b">
                                <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Finance Consumables Actual</div></td>
                                <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_con_act" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Finance Consumables Target</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_con_tar" /></td>
                             </tr>
                             <tr class="border-b">
                                <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Finance Pharmaceuticals Cost Actual</div></td>
                                <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_pha_cos_act" /></td>
                             </tr>
                             <tr class="border-b">                                
                                <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Finance Pharmaceuticals Cost Target</div></td>
                                <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_pha_cos_tar" /></td>
                             </tr>
                             <tr class="border-b">
                               <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Finance Laundry Cost Actual</div></td>
                               <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_lau_cos_act" /></td>
                             </tr>
                             <tr class="border-b">
                                <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Finance Laundry Cost Target</div></td>
                                <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_lau_cos_tar" /></td>
                             </tr>
                             <tr class="border-b">
                               <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Finance Waste Cost Actual</div></td>
                               <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_was_cos_act" /></td>
                             </tr>
                             <tr class="border-b">
                               <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Finance Waste Cost Target</div></td>
                                <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_was_cos_tar" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce Administration FTE Actual</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_adm_fte_act" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce Administration FTE Target</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_adm_fte_tar" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce Administration Cost Actual</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_adm_cos_act" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce Administration Cost Target</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_adm_cos_tar" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Worforce Medical FTE Actual </div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_med_fte_act" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Worforce Medical FTE Target</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_med_fte_tar" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Worforce Medical Cost Actual</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_med_cos_act" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Worforce Medical Cost Target</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_med_cos_tar" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce Nursing FTE Actual</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_nur_fte_act" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce Nursing FTE Target</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_nur_fte_tar" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce Nursing Cost Actual</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_nur_cos_act" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce Nursing Cost Target</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_nur_cos_tar" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce Radiographer FTE Actual</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_rad_fte_act" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce Radiographer FTE Target</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_rad_fte_tar" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce Radiographer  Cost Actual</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_rad_cos_act" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce Radiographer Cost Target</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_rad_cos_tar" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce AHP FTE Actual</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_ahp_fte_act" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce AHP FTE Target</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_ahp_fte_tar" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce AHP Cost Actual</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_ahp_cos_act" /></td>
                             </tr>
                             <tr class="border-b">
                              <td class="text-sm text-gray-900 font-light py-1 whitespace-nowrap text-left"><div>Workforce AHP Cost Target</div></td>
                              <td class="text-sm text-gray-900 font-light whitespace-nowrap text-right"> <t-input value="1" name="currentEpisodes" v-model="fin_wor_ahp_cos_tar" /></td>
                             </tr>
                          </tbody>                                                  
                        </table>
                      </div>
                      <!-- 7 close-->
                    </div>
                    <!-- 6 close-->
                  </div>
                  <!-- 5 close -->
                </div>
                <!-- 4 close -->
              </div>
              <!-- 3 close -->
              <span class="card-info"></span>
          </div>    
       

          <div class="buttoncontainer" style="border:0px;">
            <button class="btn btn-primary btn-outline mr-4 mb-4" :class="year1To4Table ? 'active' : ''" @click="updateLiveDataNew">Update</button>
          </div>        
      </div>       
    </div>
 </template>
<script>
import Datepicker from 'vuejs-datepicker';

export default {
  name: "app",
  components: {
    Datepicker
  },
  data() 
  {

    return {
      apiPayload: { speciality: "", apiString: "", stringify: false },
      groupId: 12101001,
      savedValue: "",
      state: { date: new Date (1974, 0, 8)},
      selectedHospital: "BDF Hospital",
      format: "d MMMM yyyy",
      disabledDates: {},
      datetime: "",
      dateSelected: false,
      debug: true,
      pe_surgical: "1",  pe_cath_lab: "2",  pe_end: "3",  pe_chemo: "4",  pe_dial: "5",
      pe_diag: "6", pe_opd_new: "7", pe_opd_foll: "8", rat_new: "9", tar_rat: "10",
      pe_med_adm: "11", er_wa_cu: "12", er_wa_ma: "13", er_att: "14", er_adm: "15",
      disc: "16", de_di: "17", can: "18", dna1: "19", icu_bed_av: "20", icu_bed_ut: "21",
      icu_alos: "22", ccu_bed_av: "23", ccu_bed_ut: "24", ccu_alos: "25", sur_bed_av: "26", sur_bed_ut: "27", sur_bed_alos: "28",
      day_cas_bed_av: "29", day_cas_bed_ut: "30", bur_bed_av: "31", bur_bed_ut: "32", bur_bed_alos: "33",
      med_bed_av: "34", med_bed_ut: "35", med_bed_alos: "36", obs_bed_av: "37", obs_bed_ut: "38", obs_bed_alos: "39", ld_bed_av: "40", ld_bed_ut: "41",
      ld_bed_alos: "42", pae_bed_av: "43", pae_bed_ut: "44", pae_bed_alos: "45", pic_bed_av: "46", pic_bed_ut: "47", pic_bed_alos: "48", 
      nic_bed_av: "49", nic_bed_ut: "50", nic_bed_alos: "51", ass_tot_bed_ope: "52", ass_cat_lab_ope: "53", ass_diag_ope: "54",
      ass_end_ope: "55", ass_ope_the_ope: "56", ass_opd_ope: "57",  ass_dia_ope: "58", ass_che_ope: "59",
      fin_inc_pri_act: "60", fin_inc_pri_tar: "61", fin_wor_cos_act: "62", fin_wor_cos_tar: "63", fin_con_act: "64", fin_con_tar: "65",
      fin_pha_cos_act: "66", fin_pha_cos_tar: "67", fin_lau_cos_act: "68", fin_lau_cos_tar: "69", fin_was_cos_act: "70",
      fin_was_cos_tar: "71", fin_wor_adm_fte_act: "72", fin_wor_adm_fte_tar: "73", fin_wor_adm_cos_act: "74", fin_wor_adm_cos_tar: "75",
      fin_wor_med_fte_act: "76", fin_wor_med_fte_tar: "77", fin_wor_med_cos_act: "78", fin_wor_med_cos_tar: "79",
      fin_wor_nur_fte_act: "80", fin_wor_nur_fte_tar: "81", fin_wor_nur_cos_act: "82", fin_wor_nur_cos_tar: "83",
      fin_wor_rad_fte_act: "84", fin_wor_rad_fte_tar: "85", fin_wor_rad_cos_act: "86", fin_wor_rad_cos_tar: "87",
      fin_wor_ahp_fte_act: "88", fin_wor_ahp_fte_tar: "89", fin_wor_ahp_cos_act: "90", fin_wor_ahp_cos_tar: "91",

      latestdatetime: "",
      disabledFn: {
        customPredictor(date) {
          if (date.getDate() % 3 === 0) {
            return true;
          }
        }
      },
    }
  },
  mounted ()
  {
    console.log ("SettingsMain2.vue ==> mounted ");
    this.getTileData ();
  },
  updated ()
  {
    console.log ("SettingsMain2.vue ==> updated");
   
  },
  unmounted()
  {
    console.log ("SettingsMain2.vue ==> unmounted"); 
  },
  watch: {
    selectedHospital: {
      handler: function (val) {
        // hospital
        console.log(
          "selectedHospital: Value Change: ",val);
        // change the time component for the date pulldown as there maybe a long time between entering a 
        // new record 
        
        //console.log("speciality: ", this.specialityApi);
        this.changeDate();
        //this.getData(this.specialityApi);
      },
      deep: true,
    },
  },
  computed: {
    store() {
      console.log ('store', this.$store.state.prototypedata);
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    specialityData() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return true;
      }
      return null;
    },
  },
  methods: {
    openPicker() {
      this.$refs.programaticOpen.showCalendar();
    },
     changeDate ()
    {
      var thisdate1 = new Date();
      thisdate1 = new Date(this.getCurrentTimestamp());
      var tempDate = new Date();

      if (this.debug)
      {
          console.log ("object", typeof thisdate1);
          console.log ("object getTime: ", 'getTime' in thisdate1);
      }

      if (typeof thisdate1 === 'object' && thisdate1 !== null && 'getTime' in thisdate1)
      {
          if (this.debug == true)   
          {
            console.log ("thisdate: ", thisdate1);
            console.log ("this.datetime:", thisdate1.getTime());
          }
          
          // extract the month year and date from the pulldown into tempDate
          tempDate.setMonth (this.datetime.getMonth());
          tempDate.setYear (this.datetime.getFullYear());
          tempDate.setDate (this.datetime.getDate());

          // then put in the current hours minutes and seconds
          tempDate.setHours (thisdate1.getHours());
          tempDate.setMinutes(thisdate1.getMinutes());
          tempDate.setSeconds(thisdate1.getSeconds());

          if (this.debug == true)
          {
            console.log ("this.datetime.year: ", this.datetime.getYear());
            console.log ("this.datetime.month: ", this.datetime.getMonth());
            console.log ("this.datetime.date: ", this.datetime.getDate());

            console.log ("this.datetime.getTime: ", this.datetime.getTime());
            console.log ("thisdate1.time: ", thisdate1.getTime());

            console.log ("thisdate1 hours:  ", thisdate1.getHours());
            console.log ("thisdate1 minutes: ", thisdate1.getMinutes());
            console.log ("thisdate1 seconds: ", thisdate1.getSeconds());
          }

          var tempDate1 = Date (thisdate1.getTime());
           console.log ("pulldown date: ",tempDate1);
           console.log ("current time: ", thisdate1);
           console.log ("tempDate: ", tempDate);
        }

      if (this.debug == true)
      {
        console.log (">> this.datetime: ", this.datetime);
        console.log (">>> this.datetime.year ", this.datetime.getFullYear());
        console.log (">> Temp = ", tempDate);
      }
        this.datetime = tempDate;
    },
    changeDate1 ()
    {
      var thisdate = new Date();
      thisdate = new Date(this.getCurrentTimestamp());

      console.log ("object", typeof thisdate);
      console.log ("object getTime: ", 'getTime' in thisdate);

      if (typeof thisdate === 'object' && thisdate !== null && 'getTime' in thisdate)
      {
        console.log ("thisdate: ", thisdate);
        console.log ("this.datetime:", thisdate.getTime());
        this.datetime.setTime (thisdate.getTime());
      }
      console.log ("this.datetime: ", this.datetime);
    },
    logChangedMonth(date) {
      this.changedMonthLog.push(date)
    },
    getTileData ()
    {
      this.patientEpisodes = "1";
      this.patientOPD = "1.1";
      this.patientSurgery = "1.2";
      this.patientER = "1.3";
      this.patientBeds = "1.4";
      this.assets = "2";
      this.assetsBeds = "2.1";
      this.assetsDiagnostics = "2.2";
      this.assetsOR = "2.3";
      this.assetsCath = "2.4";
      this.assetsEndoscopy = "2.5";
      this.workforce = "3";
      this.workforceMedical = "3.1";
      this.workforceNursing = "3.2";
      this.workforceAHPs = "3.3";
      this.workforceAdministration = "3.4";
      this.finance = "4";
      this.financeBudget = "4.1";
      this.financePrivateIncome = "4.2";
      this.financeDCS = "4.3";
      this.financeWorkforceCost = "4.4";
      this.er_wait = "5";
      this.er_attendances = "6";
      this.discharges = "7";
      this.delayed_discharges = "8";
      this.cancellations = "9";
      this.dna = "10";
      this.beds_available = "11";
      this.icu_available = "12";
      this.bed_occupancy = "13";
      this.alos = "14";
      this.er_admissions = "15";
      this.emergency_stemi = "16";

    },
        getCurrentTimestamp () {
        return Date.now()
    },
    selected(selectValue)
    {
      console.log ("selected: ", selectValue);
      this.dateSelected = true;
    },
    updateLiveDataNew()
    {
        console.log ("updateLiveData");
        console.log ("dateSelected : ", this.dateSelected);
        console.log ("datetime: ", this.datetime); 

        //console.log ('this.$store.state.prototypedata.specialityData:      ', this.$store.state.prototypedata.specialityData);
        //console.log ('this.$store.state.prototypedata.specialityData.alos: ', this.$store.state.prototypedata.specialityData.alos);
        //console.log ('this.$store.state.prototypedata.specialityData.alos: ', this.$store.state.prototypedata.specialityData.datetime);

        let hospital = this.selectedHospital.toString();

        if (typeof this.selectedHospital == "object")
        {
          hospital = this.selectedHospital.speciality.toString();
        }
        //console.log ("this.selectedHospital: ", this.selectedHospital);
        //console.log ("this.selectedHospital.speciality: ", this.selectedHospital.speciality);
        //console.log ("this.selectedHospital.string: ", this.selectedHospital.string);
        //console.log ("hospital: ", hospital);

        //console.log ("patient episodes: ", this.patientEpisodes);
        //console.log ("assets: ", this.assets);
        //console.log ("workforce: ", this.workforce);
        //console.log ("finance: ", this.finance);
        //console.log ("er_wait: ", this.er_wait);
        //console.log ("er_attendances: ", this.er_attendances);
        //console.log ("discharges: ", this.discharges);
        //console.log ("delayed_discharges: ", this.delayed_discharges);
        //console.log ("cancellations: ", this.cancellations);
        //console.log ("dna: ", this.dna);
        //console.log ("beds_available: ", this.beds_available);
        //console.log ("icu_available: ", this.icu_available);
        //console.log ("bed_occupancy: ", this.bed_occupancy);
        //console.log ("alos: ", this.alos);
        //console.log ("er_admissions: ", this.er_admissions);
        //console.log ("emergency_stemi: ", this.emergency_stemi);

        //  { speciality: "BDF Hospital", string: "BDFHospital" },

	console.log ("this.datetime: ", this.datetime);

	if (this.dateSelected == false)
        {
            alert ("date not selected - please select date");
            
        }
        else
        {

        

       const settings = {
     
  datetime : this.datetime,                 
                hospital : hospital,                 
                pe_surgical: this.pe_surgical,
                pe_cath_lab: this.pe_cath_lab, 
                pe_end: this.pe_end,  
                pe_chemo: this.pe_chemo,  
                pe_dial: this.pe_dial,
                pe_diag: this.pe_diag, 
                pe_opd_new: this.pe_opd_new, 
                pe_opd_foll: this.pe_opd_foll, 
                rat_new: this.rat_new, 
                tar_rat: this.tar_rat,
                pe_med_adm: this.pe_med_adm, 
                er_wa_cu: this.er_wa_cu, 
                er_wa_ma: this.er_wa_ma, 
                er_att: this.er_att, 
                er_adm: this.er_adm,
                disc: this.disc, 
                de_di: this.de_di, 
                can: this.can, 
                dna: this.dna1, 
                icu_bed_av: this.icu_bed_av, 
                icu_bed_ut: this.icu_bed_ut,
                icu_alos: this.icu_alos, 
                ccu_bed_av: this.ccu_bed_av,
                ccu_bed_ut: this.ccu_bed_ut,
                ccu_alos: this.ccu_alos, 
                sur_bed_av: this.sur_bed_av, 
                sur_bed_ut: this.sur_bed_ut, 
                sur_bed_alos: this.sur_bed_alos,
                day_cas_bed_av: this.day_cas_bed_av, 
                day_cas_bed_ut: this.day_cas_bed_ut, 
                bur_bed_av: this.bur_bed_av, 
                bur_bed_ut: this.bur_bed_ut, 
                bur_bed_alos: this.bur_bed_alos,
                med_bed_av: this.med_bed_av, 
                med_bed_ut: this.med_bed_ut, 
                med_bed_alos: this.med_bed_alos, 
                obs_bed_av: this.obs_bed_av, 
                obs_bed_ut: this.obs_bed_ut, 
                obs_bed_alos: this.obs_bed_alos, 
                ld_bed_av: this.ld_bed_av, 
                ld_bed_ut: this.ld_bed_ut,
                ld_bed_alos: this.ld_bed_alos, 
                pae_bed_av: this.pae_bed_av, 
                pae_bed_ut: this.pae_bed_ut, 
                pae_bed_alos: this.pae_bed_alos, 
                pic_bed_av: this.pic_bed_av, 
                pic_bed_ut: this.pic_bed_ut, 
                pic_bed_alos: this.pic_bed_alos, 
                nic_bed_av: this.nic_bed_av, 
                nic_bed_ut: this.nic_bed_ut, 
                nic_bed_alos: this.nic_bed_alos, 
                ass_tot_bed_ope: this.ass_tot_bed_ope, 
                ass_cat_lab_ope: this.ass_cat_lab_ope, 
                ass_diag_ope: this.ass_diag_ope,
                ass_end_ope: this.ass_end_ope, 
                ass_ope_the_ope: this.ass_ope_the_ope, 
                ass_opd_ope: this.ass_opd_ope,  
                ass_dia_ope: this.ass_dia_ope, 
                ass_che_ope: this.ass_che_ope,
                fin_inc_pri_act: this.fin_inc_pri_act, 
                fin_inc_pri_tar: this.fin_inc_pri_tar, 
                fin_wor_cos_act: this.fin_wor_cos_act, 
                fin_wor_cos_tar: this.fin_wor_cos_tar, 
                fin_con_act: this.fin_con_act, 
                fin_con_tar: this.fin_con_tar,
                fin_pha_cos_act: this.fin_pha_cos_act, 
                fin_pha_cos_tar: this.fin_pha_cos_tar, 
                fin_lau_cos_act: this.fin_lau_cos_act, 
                fin_lau_cos_tar: this.fin_lau_cos_tar, 
                fin_was_cos_act: this.fin_was_cos_act,
                fin_was_cos_tar: this.fin_was_cos_tar, 
                fin_wor_adm_fte_act: this.fin_wor_adm_fte_act, 
                fin_wor_adm_fte_tar: this.fin_wor_adm_fte_tar, 
                fin_wor_adm_cos_act: this.fin_wor_adm_cos_act, 
                fin_wor_adm_cos_tar: this.fin_wor_adm_cos_tar,
                fin_wor_med_fte_act: this.fin_wor_med_fte_act, 
                fin_wor_med_fte_tar: this.fin_wor_med_fte_tar, 
                fin_wor_med_cos_act: this.fin_wor_med_cos_act, 
                fin_wor_med_cos_tar: this.fin_wor_med_cos_tar,
                fin_wor_nur_fte_act: this.fin_wor_nur_fte_act, 
                fin_wor_nur_fte_tar: this.fin_wor_nur_fte_tar, 
                fin_wor_nur_cos_act: this.fin_wor_nur_cos_act, 
                fin_wor_nur_cos_tar: this.fin_wor_nur_cos_tar,
                fin_wor_rad_fte_act: this.fin_wor_rad_fte_act, 
                fin_wor_rad_fte_tar: this.fin_wor_rad_fte_tar, 
                fin_wor_rad_cos_act: this.fin_wor_rad_cos_act, 
                fin_wor_rad_cos_tar: this.fin_wor_rad_cos_tar,
                fin_wor_ahp_fte_act: this.fin_wor_ahp_fte_act, 
                fin_wor_ahp_fte_tar: this.fin_wor_ahp_fte_tar, 
                fin_wor_ahp_cos_act: this.fin_wor_ahp_cos_act, 
                fin_wor_ahp_cos_tar: this.fin_wor_ahp_cos_tar

       };

        this.$store.dispatch('updateLiveData', settings).then((res) => {
     //   this.$store.dispatch('updateLiveData', this.$store.state.prototypedata.specialityData).then((res) => {
          console.log(res)
        })
        .catch((error) => {
          // catch the error 
          alert(error)
        })
	}
    },
    },
};

</script> 
<style>
input[type=text] {
			width: 100%;
			/*padding: 12px 20px;*/
			box-sizing: border-box;
			border: 2px solid #ccc;
			-webkit-transition: 0.5s;
			transition: 0.5s;
			outline: none;
		}

.buttoncontainer {
   border: 1px solid;
   display: block;
   width: 100%;
}
</style>

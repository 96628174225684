import Vue from 'vue';
import Router from 'vue-router';

import loginRoutes from '../modules/login/router'
import summaryRoutes from '../modules/summary/router'
import specialityRoutes from '../modules/specialities/router'
import summary2Routes from '../modules/summary2/router'
import summary4Routes from '../modules/summary4/router'
import benchmarkingRoutes from '../modules/benchmarking/router'
import acoStrategyRoutes from '../modules/aco-strategy/router'
import locationRoutes from '../modules/locations/router'
import toolkitRoutes from '../modules/toolkit/router'
import settingsRoutes from '../modules/settings/router'
import settingsRoutes2 from '../modules/settings2/router'
import settingsRoutes3 from '../modules/settings3/router'
import actualLocationRoutes from '../modules/actualLocation/router'
import userRoutes from '../modules/user/router'
import JobPlans from '../modules/jobplans/router'
import Tariff from '../modules/tariff/router'
import BuildFM1 from '../modules/buildFM1/router'
import BuildFM2 from '../modules/buildFM2/router'
import BuildFM3 from '../modules/buildFM3/router'
import speciality3Routes from "../modules/specialities3/router";
import summary3Routes from "../modules/summary3/router";
import bpRoutes from '../modules/bpsummary/router';
import stratInpRoutes from '../modules/strategicinput/router';
import StratwfRoutes from '../modules/stratwfplan/router';
import ecrRoutes from '../modules/ecr/router';
import CIPP from '../modules/cipp/router';

Vue.use(Router);

const defaultFallback = {
  path: '/',
  redirect: '/login'
};

const baseRoutes = [defaultFallback];

const routes = baseRoutes.concat(loginRoutes, summary4Routes, summaryRoutes, specialityRoutes, summary2Routes, benchmarkingRoutes, acoStrategyRoutes, locationRoutes, toolkitRoutes, settingsRoutes, actualLocationRoutes, userRoutes, JobPlans, Tariff, BuildFM1, BuildFM2, BuildFM3, speciality3Routes, summary3Routes, bpRoutes, stratInpRoutes, StratwfRoutes, ecrRoutes, CIPP, settingsRoutes2, settingsRoutes3);

const router = new Router({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  const loggedIn = !!window.localStorage.getItem("token");
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !loggedIn)
    next({
      name: "Login"
    });
  next();
});

export default router;

<template>
  <div>
     <header class="route-header">
       <div class="route-header--left">
         <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
       </div>
       <div class="route-header--right">
         <h1 class="text-3xl">Enter Live Operations Data</h1>
       </div>
     </header>
 
     <div class="route-header items-center secondary">
          <div class="route-header--box">
            <div class="box">
              <span class="box-header">Location</span>
              <span class="box-label">BDF - Bahrain</span>
            </div>
          </div>
          <div class="route-header--box">
            <div class="box">
              <span class="box-header">Hospital</span>
              <span class="box-label">
                <v-select
                  v-model="selectedHospital"
                  label="speciality"
                  :clearable="false"
                  @input="fetchData"
                  :options="$store.state.prototypedata.hospitals"
                />
              </span>
            </div>
          </div>
          <div class="route-header--box">
            <div class="box">
              <span class="box-header">Date</span>
              <span class="box-label"><datepicker v-model="datetime" placeholder="Select Date" @selected="selected"></datepicker></span>
            </div>
          </div>
          <div class="route-header--box">
            <div class="box">
              <span class="box-header">Total Population</span>
              <span class="box-label">495,000</span>
            </div>
          </div>
        </div>

        <div class="route-content">
        <!-------->
        
        <div class="stat-cards">
          <div class="stat-cards--item single no-hover">
              <span class="card-header">Patient Episodes</span>
              <div class="card-icon">
                <img
                  src="@/assets/img/icons/icon-patient.svg"
                  alt="Patient Episodes"
                />
                <t-input value="1" name="currentEpisodes" v-model="patientEpisodes" />
              </div>
              <span class="card-info">Total patient episodes</span>
            </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Assets</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <t-input value="1" name="currentEpisodes" v-model="assets" />
            </div>
            <span class="card-info">Total Assets</span>
          </div>

          <div
            class="stat-cards--item single no-hover">
            <span class="card-header">Workforce</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="workforce" />
            </div>
            <span class="card-info"
              >Total Workforce Full Time Equivalent (FTE)</span
            >
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Finance</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <t-input value="1" name="currentEpisodes" v-model="finance" />
            </div>
            <span class="card-info">Budget Requirement</span>
          </div>

          <div
            class="stat-cards--item single no-hover">
            <span class="card-header">Workforce Medical</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="workforceMedical" v-model="workforceMedical" />
            </div>
            <span class="card-info"></span
            >
          </div>

          <div
            class="stat-cards--item single no-hover">
            <span class="card-header">Workforce Nursing</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="workforceNursing" v-model="workforceNursing" />
            </div>
            <span class="card-info"></span
            >
          </div>

          <div
            class="stat-cards--item single no-hover">
            <span class="card-header">Workforce AHP's</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="workforceAHPs" v-model="workforceAHPs" />
            </div>
            <span class="card-info"></span
            >
          </div>

          <div
            class="stat-cards--item single no-hover">
            <span class="card-header">Workforce Administration</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="workforceAdministration" v-model="workforceAdministration" />
            </div>
            <span class="card-info"></span
            >
          </div>


          <div class="stat-cards--item single no-hover">
            <span class="card-header">Assets Beds</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <t-input value="1" name="assetsBeds" v-model="assetsBeds" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Assets Diagnostics</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <t-input value="1" name="assetsDiagnostics" v-model="assetsDiagnostics" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Assets OR</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <t-input value="1" name="assetsOR" v-model="assetsOR" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Assets Cath</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <t-input value="1" name="assetsCath" v-model="assetsCath" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Assets Endoscopy</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <t-input value="1" name="assetsEndoscopy" v-model="assetsEndoscopy" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <!-- div class="stat-cards--item single no-hover" -->
              <span class="card-header">Patient OPD</span>
              <div class="card-icon">
                <img
                  src="@/assets/img/icons/icon-patient.svg"
                  alt="Patient Episodes"
                />
                <t-input value="1" name="patientOPD" v-model="patientOPD" />
              </div>
              <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <!-- div class="stat-cards--item single no-hover" -->
              <span class="card-header">Patient Surgery</span>
              <div class="card-icon">
                <img
                  src="@/assets/img/icons/icon-patient.svg"
                  alt="Patient Episodes"
                />
                <t-input value="1" name="patientSurgery" v-model="patientSurgery" />
              </div>
              <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <!-- div class="stat-cards--item single no-hover" -->
              <span class="card-header">Patient ER</span>
              <div class="card-icon">
                <img
                  src="@/assets/img/icons/icon-patient.svg"
                  alt="Patient Episodes"
                />
                <t-input value="1" name="patientER" v-model="patientER" />
              </div>
              <span class="card-info"></span>
            </div>

            <div class="stat-cards--item single no-hover">
            <!-- div class="stat-cards--item single no-hover" -->
              <span class="card-header">Patient Beds</span>
              <div class="card-icon">
                <img
                  src="@/assets/img/icons/icon-patient.svg"
                  alt="Patient Episodes"
                />
                <t-input value="1" name="patientBeds" v-model="patientBeds" />
              </div>
              <span class="card-info"></span>
            </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Finance Budget</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <t-input value="1" name="financeBudget" v-model="financeBudget" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Finance Private Income</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <t-input value="1" name="financePrivateIncome" v-model="financePrivateIncome" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Finance Daily Consumables Spend</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <t-input value="1" name="financeDCS" v-model="financeDCS" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Finance Workforce Cost</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <t-input value="1" name="financeWorkforceCost" v-model="financeWorkforceCost" />
            </div>
            <span class="card-info"></span>
          </div>
         

          <div class="stat-cards--item single no-hover">
            <span class="card-header">ER Wait (Minutes)</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt=""
              />
              <t-input value="1" name="currentEpisodes" v-model="er_wait" />
            </div>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">ER Attendances</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <t-input value="1" name="currentEpisodes" v-model="er_attendances" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Discharges </span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="discharges" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Delayed Discharges </span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="delayed_discharges" />
            </div>
            <span class="card-info"></span>
          </div>

         
          <!--div v-if="activeSummary == 'assets'" -->
          <div class="stat-cards--item single no-hover">
            <span class="card-header">Cancellations</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <t-input value="1" name="currentEpisodes" v-model="cancellations" />
            </div>
            <span class="card-info"></span>
          </div>
          <!-- /div -->

          <div class="stat-cards--item single no-hover">
            <span class="card-header">DNA</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="dna" />
            </div>
            <span class="card-info"></span>
          </div>


          <div class="stat-cards--item single no-hover">
            <span class="card-header">Beds Available</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="beds_available" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">ICU Available </span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="icu_available" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Bed Occupancy </span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="bed_occupancy" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">ALOS </span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="alos" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">ER Admissiions</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="er_admissions" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Emergency STEMI</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="emergency_stemi" />
            </div>
            <span class="card-info"></span>
          </div>

       

          <div class="buttoncontainer" style="border:0px;">
            <button class="btn btn-primary btn-outline mr-4 mb-4" :class="year1To4Table ? 'active' : ''" @click="updateLiveDataNew">Update</button>
          </div>        
      </div>       
    </div>
 </div> 
 </template>
<script>
import Datepicker from 'vuejs-datepicker';

export default {
  name: "app",
  components: {
    Datepicker
  },
  data() 
  {

    return {
      apiPayload: { speciality: "", apiString: "", stringify: false },
      groupId: 12101001,
      savedValue: "",
      state: { date: new Date (1974, 0, 8)},
      selectedHospital: "BDF Hospital",
      format: "d MMMM yyyy",
      disabledDates: {},
      datetime: "",
      dateSelected: false,
      debug: true,
      latestdatetime: "",
      disabledFn: {
        customPredictor(date) {
          if (date.getDate() % 3 === 0) {
            return true;
          }
        }
      },
    }
  },
  mounted ()
  {
    console.log ("SettingsMain2.vue ==> mounted ");
    this.getTileData ();
  },
  updated ()
  {
    console.log ("SettingsMain2.vue ==> updated");
   
  },
  unmounted()
  {
    console.log ("SettingsMain2.vue ==> unmounted"); 
  },
  watch: {
    selectedHospital: {
      handler: function (val) {
        // hospital
        console.log(
          "selectedHospital: Value Change: ",val);
        // change the time component for the date pulldown as there maybe a long time between entering a 
        // new record 
        
        //console.log("speciality: ", this.specialityApi);
        this.changeDate();
        //this.getData(this.specialityApi);
      },
      deep: true,
    },
  },
  computed: {
    store() {
      console.log ('store', this.$store.state.prototypedata);
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    specialityData() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return true;
      }
      return null;
    },
  },
  methods: {
    openPicker() {
      this.$refs.programaticOpen.showCalendar();
    },
     changeDate ()
    {
      var thisdate1 = new Date();
      thisdate1 = new Date(this.getCurrentTimestamp());
      var tempDate = new Date();

      if (this.debug)
      {
          console.log ("object", typeof thisdate1);
          console.log ("object getTime: ", 'getTime' in thisdate1);
      }

      if (typeof thisdate1 === 'object' && thisdate1 !== null && 'getTime' in thisdate1)
      {
          if (this.debug == true)   
          {
            console.log ("thisdate: ", thisdate1);
            console.log ("this.datetime:", thisdate1.getTime());
          }
          
          // extract the month year and date from the pulldown into tempDate
          tempDate.setMonth (this.datetime.getMonth());
          tempDate.setYear (this.datetime.getFullYear());
          tempDate.setDate (this.datetime.getDate());

          // then put in the current hours minutes and seconds
          tempDate.setHours (thisdate1.getHours());
          tempDate.setMinutes(thisdate1.getMinutes());
          tempDate.setSeconds(thisdate1.getSeconds());

          if (this.debug == true)
          {
            console.log ("this.datetime.year: ", this.datetime.getYear());
            console.log ("this.datetime.month: ", this.datetime.getMonth());
            console.log ("this.datetime.date: ", this.datetime.getDate());

            console.log ("this.datetime.getTime: ", this.datetime.getTime());
            console.log ("thisdate1.time: ", thisdate1.getTime());

            console.log ("thisdate1 hours:  ", thisdate1.getHours());
            console.log ("thisdate1 minutes: ", thisdate1.getMinutes());
            console.log ("thisdate1 seconds: ", thisdate1.getSeconds());
          }

          var tempDate1 = Date (thisdate1.getTime());
           console.log ("pulldown date: ",tempDate1);
           console.log ("current time: ", thisdate1);
           console.log ("tempDate: ", tempDate);
        }

      if (this.debug == true)
      {
        console.log (">> this.datetime: ", this.datetime);
        console.log (">>> this.datetime.year ", this.datetime.getFullYear());
        console.log (">> Temp = ", tempDate);
      }
        this.datetime = tempDate;
    },
    changeDate1 ()
    {
      var thisdate = new Date();
      thisdate = new Date(this.getCurrentTimestamp());

      console.log ("object", typeof thisdate);
      console.log ("object getTime: ", 'getTime' in thisdate);

      if (typeof thisdate === 'object' && thisdate !== null && 'getTime' in thisdate)
      {
        console.log ("thisdate: ", thisdate);
        console.log ("this.datetime:", thisdate.getTime());
        this.datetime.setTime (thisdate.getTime());
      }
      console.log ("this.datetime: ", this.datetime);
    },
    logChangedMonth(date) {
      this.changedMonthLog.push(date)
    },
    getTileData ()
    {
      this.patientEpisodes = "1";
      this.patientOPD = "1.1";
      this.patientSurgery = "1.2";
      this.patientER = "1.3";
      this.patientBeds = "1.4";
      this.assets = "2";
      this.assetsBeds = "2.1";
      this.assetsDiagnostics = "2.2";
      this.assetsOR = "2.3";
      this.assetsCath = "2.4";
      this.assetsEndoscopy = "2.5";
      this.workforce = "3";
      this.workforceMedical = "3.1";
      this.workforceNursing = "3.2";
      this.workforceAHPs = "3.3";
      this.workforceAdministration = "3.4";
      this.finance = "4";
      this.financeBudget = "4.1";
      this.financePrivateIncome = "4.2";
      this.financeDCS = "4.3";
      this.financeWorkforceCost = "4.4";
      this.er_wait = "5";
      this.er_attendances = "6";
      this.discharges = "7";
      this.delayed_discharges = "8";
      this.cancellations = "9";
      this.dna = "10";
      this.beds_available = "11";
      this.icu_available = "12";
      this.bed_occupancy = "13";
      this.alos = "14";
      this.er_admissions = "15";
      this.emergency_stemi = "16";

    },
        getCurrentTimestamp () {
        return Date.now()
    },
    selected(selectValue)
    {
      console.log ("selected: ", selectValue);
      this.dateSelected = true;
    },
    updateLiveDataNew()
    {
        console.log ("updateLiveData");
        console.log ("dateSelected : ", this.dateSelected);
        console.log ("datetime: ", this.datetime); 

        //console.log ('this.$store.state.prototypedata.specialityData:      ', this.$store.state.prototypedata.specialityData);
        //console.log ('this.$store.state.prototypedata.specialityData.alos: ', this.$store.state.prototypedata.specialityData.alos);
        //console.log ('this.$store.state.prototypedata.specialityData.alos: ', this.$store.state.prototypedata.specialityData.datetime);

        let hospital = this.selectedHospital.toString();

        if (typeof this.selectedHospital == "object")
        {
          hospital = this.selectedHospital.speciality.toString();
        }
        //console.log ("this.selectedHospital: ", this.selectedHospital);
        //console.log ("this.selectedHospital.speciality: ", this.selectedHospital.speciality);
        //console.log ("this.selectedHospital.string: ", this.selectedHospital.string);
        //console.log ("hospital: ", hospital);

        //console.log ("patient episodes: ", this.patientEpisodes);
        //console.log ("assets: ", this.assets);
        //console.log ("workforce: ", this.workforce);
        //console.log ("finance: ", this.finance);
        //console.log ("er_wait: ", this.er_wait);
        //console.log ("er_attendances: ", this.er_attendances);
        //console.log ("discharges: ", this.discharges);
        //console.log ("delayed_discharges: ", this.delayed_discharges);
        //console.log ("cancellations: ", this.cancellations);
        //console.log ("dna: ", this.dna);
        //console.log ("beds_available: ", this.beds_available);
        //console.log ("icu_available: ", this.icu_available);
        //console.log ("bed_occupancy: ", this.bed_occupancy);
        //console.log ("alos: ", this.alos);
        //console.log ("er_admissions: ", this.er_admissions);
        //console.log ("emergency_stemi: ", this.emergency_stemi);

        //  { speciality: "BDF Hospital", string: "BDFHospital" },

	console.log ("this.datetime: ", this.datetime);

	if (this.dateSelected == false)
        {
            alert ("date not selected - please select date");
            
        }
        else
        {

        

       const settings = {
          datetime : this.datetime,
          hospital : hospital,
          patient_episodes: this.patientEpisodes,
          assets: this.assets,         
          workforce: this.workforce,
          finance: this.finance,
          er_wait: this.er_wait,
          er_attendances: this.er_attendances,
          discharges: this.discharges,
          delayed_discharges: this.delayed_discharges,
          cancellations: this.cancellations,
          dna: this.dna,
          beds_available: this.beds_available,
          icu_available: this.icu_available,
          bed_occupancy: this.bed_occupancy,
          alos: this.alos,
          er_admissions: this.er_admissions,
          emergency_stemi: this.emergency_stemi,
          patientOPD:  this.patientOPD,
          patientSurgery:  this.patientSurgery,
          patientER: this.patientER,
          patientBeds: this.patientBeds,
          assetsBeds: this.assetsBeds,
          assetsDiagnostics: this.assetsDiagnostics,
          assetsOR: this.assetsOR,
          assetsCath: this.assetsCath,
          assetsEndoscopy: this.assetsEndoscopy,
          financeBudget: this.financeBudget,
          financePI: this.financePrivateIncome,
          financeDCS: this.financeDCS,
          financeWFC: this.financeWorkforceCost,
          workforceMedical: this.workforceMedical,
          workforceNursing: this.workforceNursing,
          workforceAHPs: this.workforceAHPs,
          workforceAdministration: this.workforceAdministration,
       };

        this.$store.dispatch('updateLiveData', settings).then((res) => {
     //   this.$store.dispatch('updateLiveData', this.$store.state.prototypedata.specialityData).then((res) => {
          console.log(res)
        })
        .catch((error) => {
          // catch the error 
          alert(error)
        })
	}
    },
    },
};

</script> 
<style>

.buttoncontainer {
   border: 1px solid;
   display: block;
   width: 100%;
}
</style>

import Shell from '../../../layouts/Shell.vue'
import SettingsMain2 from '../views/SettingsMain2.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/settings2',
            name: 'SettingsMain2',
            component: SettingsMain2,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes

<template>
  <div>
     <header class="route-header">
       <div class="route-header--left">
         <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
       </div>
       <div class="route-header--right">
         <h1 class="text-3xl">Enter Live Operations Data</h1>
       </div>
     </header>
 
     <div class="route-header items-center secondary">
          <div class="route-header--box">
            <div class="box">
              <span class="box-header">Location</span>
              <span class="box-label">BDF - Bahrain</span>
            </div>
          </div>
          <div class="route-header--box">
            <div class="box">
              <span class="box-header">Hospital</span>
              <span class="box-label">
                <v-select
                  v-model="selectedHospital"
                  label="speciality"
                  :clearable="false"
                  @input="fetchData"
                  :options="$store.state.prototypedata.hospitals"
                />
              </span>
            </div>
          </div>
          <div class="route-header--box">
            <div class="box">
              <span class="box-header">Date</span>
              <span class="box-label"><datepicker v-model="patientEpisodes1" placeholder="Select Date"></datepicker></span>
            </div>
          </div>
          <div class="route-header--box">
            <div class="box">
              <span class="box-header">Total Population</span>
              <span class="box-label">495,000</span>
            </div>
          </div>
        </div>

        <div class="route-content">
        <!-------->
        
        <div class="stat-cards">
          <div class="stat-cards--item single no-hover">
            <!-- div class="stat-cards--item single no-hover" -->
              <span class="card-header">Patient Episodes</span>
              <div class="card-icon">
                <img
                  src="@/assets/img/icons/icon-patient.svg"
                  alt="Patient Episodes"
                />
                <!-- {{ patientEpisodeProductivityFilter1 | numeral }} -->
                <!-- span class="card-total">{{  this.getTileData (0) }}</span -->
                <t-input value="1" name="currentEpisodes" v-model="patientEpisodes" />
              </div>
              <span class="card-info">Total patient episodes</span>
            </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Assets</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <t-input value="1" name="currentEpisodes" v-model="assets" />
            </div>
            <span class="card-info">Total Assets</span>
          </div>

          <div
            class="stat-cards--item single no-hover">
            <!-- div class="stat-cards--item single no-hover" -->
            <span class="card-header">Workforce</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="workforce" />
            </div>
            <span class="card-info"
              >Total Workforce Full Time Equivalent (FTE)</span
            >
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Finance</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <t-input value="1" name="currentEpisodes" v-model="finance" />
            </div>
            <span class="card-info">Budget Requirement</span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">ER Wait (Minutes)</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt=""
              />
              <t-input value="1" name="currentEpisodes" v-model="er_wait" />
            </div>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">ER Attendances</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <t-input value="1" name="currentEpisodes" v-model="er_attendances" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Discharges </span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="discharges" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Delayed Discharges </span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="delayed_discharges" />
            </div>
            <span class="card-info"></span>
          </div>

         
          <!--div v-if="activeSummary == 'assets'" -->
          <div class="stat-cards--item single no-hover">
            <span class="card-header">Cancellations</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <t-input value="1" name="currentEpisodes" v-model="cancellations" />
            </div>
            <span class="card-info"></span>
          </div>
          <!-- /div -->

          <div class="stat-cards--item single no-hover">
            <span class="card-header">DNA</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="dna" />
            </div>
            <span class="card-info"></span>
          </div>


          <div class="stat-cards--item single no-hover">
            <span class="card-header">Beds Available</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="beds_available" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">ICU Available </span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="icu_available" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Bed Occupancy </span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="bed_occupancy" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">ALOS </span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="alos" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">ER Admissiions</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="er_admissions" />
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Emergency STEMI</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <t-input value="1" name="currentEpisodes" v-model="emergency_stemi" />
            </div>
            <span class="card-info"></span>
          </div>

       

          <div class="buttoncontainer" style="border:0px;">
            <button class="btn btn-primary btn-outline mr-4 mb-4" :class="year1To4Table ? 'active' : ''" @click="updateLiveData">Update</button>
          </div>        
      </div>       
    </div>
 </div> 
 </template>
<script>
import Datepicker from 'vuejs-datepicker';

export default {
  name: "app",
  components: {
    Datepicker
  },
  data() 
  {

    return {
      apiPayload: { speciality: "", apiString: "", stringify: false },
      groupId: 12101001,
      savedValue: "",
      state: { date: new Date (1974, 0, 8)},
      selectedHospital: "BDF Hospital",
      format: "d MMMM yyyy",
      disabledDates: {},
      patientEpisodes1: "",
      disabledFn: {
        customPredictor(date) {
          if (date.getDate() % 3 === 0) {
            return true;
          }
        }
      },
    }
  },
  mounted ()
  {
    console.log ("SettingsMain.vue ==> mounted ");
    this.getTileData ();
  },
  updated ()
  {
    console.log ("SettingsMain.vue ==> updated");
   
  },
  unmounted()
  {
    console.log ("SettingsMain.vue ==> unmounted"); 
  },
  computed: {
    store() {
      console.log ('store', this.$store.state.prototypedata);
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    specialityData() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return true;
      }
      return null;
    },
  },
  methods: {
    openPicker() {
      this.$refs.programaticOpen.showCalendar();
    },
    logChangedMonth(date) {
      this.changedMonthLog.push(date)
    },
    getTileData ()
    {
      this.patientEpisodes = "1";
      this.assets = "2";
      this.workforce = "3";
      this.finance = "4";
      this.er_wait = "5";
      this.er_attendances = "6";
      this.discharges = "7";
      this.delayed_discharges = "8";
      this.cancellations = "9";
      this.dna = "10";
      this.beds_available = "11";
      this.icu_available = "12";
      this.bed_occupancy = "13";
      this.alos = "14";
      this.er_admissions = "15";
      this.emergency_stemi = "16";

    },
    updateLiveData()
    {
        console.log ("updateLiveData");

        //console.log ('this.$store.state.prototypedata.specialityData:      ', this.$store.state.prototypedata.specialityData);
        //console.log ('this.$store.state.prototypedata.specialityData.alos: ', this.$store.state.prototypedata.specialityData.alos);
        //console.log ('this.$store.state.prototypedata.specialityData.alos: ', this.$store.state.prototypedata.specialityData.datetime);

        let hospital = this.selectedHospital.toString();

        if (typeof this.selectedHospital == "object")
        {
          hospital = this.selectedHospital.speciality.toString();
        }
        //console.log ("this.selectedHospital: ", this.selectedHospital);
        //console.log ("this.selectedHospital.speciality: ", this.selectedHospital.speciality);
        //console.log ("this.selectedHospital.string: ", this.selectedHospital.string);
        //console.log ("hospital: ", hospital);

        //console.log ("patient episodes: ", this.patientEpisodes);
        //console.log ("assets: ", this.assets);
        //console.log ("workforce: ", this.workforce);
        //console.log ("finance: ", this.finance);
        //console.log ("er_wait: ", this.er_wait);
        //console.log ("er_attendances: ", this.er_attendances);
        //console.log ("discharges: ", this.discharges);
        //console.log ("delayed_discharges: ", this.delayed_discharges);
        //console.log ("cancellations: ", this.cancellations);
        //console.log ("dna: ", this.dna);
        //console.log ("beds_available: ", this.beds_available);
        //console.log ("icu_available: ", this.icu_available);
        //console.log ("bed_occupancy: ", this.bed_occupancy);
        //console.log ("alos: ", this.alos);
        //console.log ("er_admissions: ", this.er_admissions);
        //console.log ("emergency_stemi: ", this.emergency_stemi);

        //  { speciality: "BDF Hospital", string: "BDFHospital" },

        

       const settings = {
          datetime : this.patientEpisodes1,
          hospital : hospital,
          patient_episodes: this.patientEpisodes,
          assets: this.assets,
          workforce: this.workforce,
          finance: this.finance,
          er_wait: this.er_wait,
          er_attendances: this.er_attendances,
          discharges: this.discharges,
          delayed_discharges: this.delayed_discharges,
          cancellations: this.cancellations,
          dna: this.dna,
          beds_available: this.beds_available,
          icu_available: this.icu_available,
          bed_occupancy: this.bed_occupancy,
          alos: this.alos,
          er_admissions: this.er_admissions,
          emergency_stemi: this.emergency_stemi,
       };

        this.$store.dispatch('updateLiveData', settings).then((res) => {
     //   this.$store.dispatch('updateLiveData', this.$store.state.prototypedata.specialityData).then((res) => {
          console.log(res)
        })
        .catch((error) => {
          // catch the error 
          alert(error)
        })
    },
    },
};

</script> 
<style>

.buttoncontainer {
   border: 1px solid;
   display: block;
   width: 100%;
}
</style>

<template>
  <main class="shell">
    <aside class="shell-nav" :class="sidebarOpen ? 'open' : 'closed'">
      <div class="nav-trigger" @click="openSidebar()">
        <jam-menu v-if="!sidebarOpen"/>
        <jam-arrow-left v-if="sidebarOpen"/>
      </div>
      <nav class="nav-list">
        <ul>
          <!-- li><router-link to="summary"><jam-grid/><span>Stage 1</span></router-link></li -->
          <!-- li><router-link to="summary2"><jam-grid/><span>Stage 2</span></router-link></li -->
          <!-- li><router-link to="summary3"><jam-grid/><span>Stage 3</span></router-link></li -->
          <!-- li><router-link to="summary4"><jam-grid/><span>Stage 4</span></router-link></li --> 
          <!-- li><router-link to="bpsummary"><jam-tools /><span>Strategic Business Plans</span></router-link></li -->
          <!-- li><router-link to="tariff"><jam-coin/><span>Tariffs and Pricing</span></router-link></li -->
          <!-- li><router-link to="stratwfplan"><jam-tools /><span>Strategic Workforce Plan</span></router-link></li -->
          <!-- li><router-link to="JobPlans"><jam-calendar/><span>Consultant Job Plans</span></router-link></li -->
          <!-- li><router-link to="strategicinput"><jam-tools/><span>Strategic Inputs</span></router-link></li -->
          <!-- li><router-link to="ecr"><jam-tools/><span>Elective Care Recovery (ECR)</span></router-link></li -->
          <!-- li><router-link to="cipp"><jam-tools/><span>Contigency, Incident & Pandemic Planning</span></router-link></li -->
          <!-- li><router-link to="benchmarking"><jam-tools/><span>Benchmark Data</span></router-link></li -->
          <li><router-link to="settings"><jam-tools /><span>Settings - BDFRMS</span></router-link></li>
          <li><router-link to="settings2"><jam-tools /><span>Settings - BDFRMS Updated</span></router-link></li>
          <li><router-link to="settings3"><jam-tools /><span>New Settings - BDFRMS</span></router-link></li>
        </ul>
      </nav>
      <button class="nav-help"><jam-help/></button>
    </aside>
    <div class="shell-container">
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
export default {
  data () {
    return {
      sidebarOpen: false
    }
  },
  methods: {
    openSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    }
  }
}
</script>

<style lang="scss">
  @import "../assets/scss/modules/base.scss";
</style>


<template>
 <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Summary Build and Property Costs - Stage 1</h1>
      </div>
      <div class="route-header--box">
         <div class="box">
          <!-- img src="@/assets/img/logo-full.svg" alt="" style="width: 55vw; min-width: 330px;" / -->
         </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">United Kingdom</span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Total Population</span>
          <span class="box-label">1,199,870</span>
        </div>
      </div>
    </div>

    <div class="route-content">

    <div v-if="specialityData != null">  <!-- 1 open -->
    <table class="table-primary">
    <thead>
      <tr>
        <th class="border px-4 py-2">Area</th>
        <th class="border px-4 py-2">Total Floor Space</th>
        <th class="border px-4 py-2">Full Development Cost</th>
        <th class="border px-4 py-2">Net Build Cost</th>
        <th class="border px-4 py-2">Avg FM Cost</th>
        <th class="border px-4 py-2">FM Cost</th>
      </tr>
    </thead>
    <tbody>
        <tr>
          <td class="border">
            <div
              v-for="( jobPlan,
                      index) in areaAreaFilter"
                      :key="index">
                  {{ jobPlan }}
            </div>
          </td>

          <td class="border">
             <div
              v-for="(jobPlanPatients,
                      index) in areaTFSFilter"
                      :key="index">
                  {{ jobPlanPatients }}
              </div>
          </td>

          <td class="border">
             <div
              v-for="(jobPlanperSessions,
                      index) in areaNBCFilter"
                      :key="index">
                  {{ jobPlanperSessions }}
              </div>
          </td>

          <td class="border">
             <div
              v-for="(jobPlanannSessions,
                      index) in areaFDCFilter"
                      :key="index">
                  {{ jobPlanannSessions }}
              </div>
          </td>

          <td class="border">
             <div
              v-for="(jobPlanweekSessions,
                      index) in areaAFMCostFilter"
                      :key="index">
                  {{ jobPlanweekSessions }}
              </div>
          </td>

          <td class="border">
             <div
              v-for="(jobPlanweekSessions,
                      index) in areaFMCostFilter"
                      :key="index">
                  {{ jobPlanweekSessions }}
              </div>
          </td>
        </tr>
    </tbody>
    </table>
  </div>
  </div>    
</div> 
</template>
<script>
export default {
  data() {
    return {
      //tariffSummary: "OPD",
      //selectedSpeciality: "fm_build1_collection",
     };
  },
  mounted() {
    console.log (".Vue: mounted");
    const speciality = {
      speciality: "",
      apiString: "fm_build1_collection",
    };
    this.fetchData(speciality);
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    specialityData() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return true;
      }
      return null;
    },
    getAreaIndex()
    {
      var i = 0;
      var index = 0;

      for (i = 0; i<this.$store.state.prototypedata.specialityData.length; i++)
      {
          if ('total_floor_space' in this.$store.state.prototypedata.specialityData[i])
          {
            index = i;
          }
      }  

      return index;
    },
    getGFAIndex()
    {
      var i = 0;
      var index = 0;

      for (i = 0; i<this.$store.state.prototypedata.specialityData.length; i++)
      {
          if ('gfa' in this.$store.state.prototypedata.specialityData[i])
          {
            index = i;
          }
      }  

      return index;
    },
    areaAreaFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .area;
      }
      return null;
    },
    areaTFSFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .total_floor_space;
      }
      return null;
    },
    areaNBCFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .net_build_cost;
      }
      return null;
    },
    areaFDCFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .full_dev_cost;
      }
      return null;
    },
    areaAFMCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .avg_fm_cost;
      }
      return null;
    },
    areaFMCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .fm_cost;
      }
      return null;
    },
    gfaFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getGFAIndex]
          .gfa;
      }
      return null;
    },
  },
  methods: {
    fetchData(speciality) 
    {
      console.log ("BuildFM1.Vue: fetchData (",speciality);
      this.specialityHeader(speciality.speciality);
      this.$store
        .dispatch("attemptFetchSpecialityData", speciality)
        .then((data) => {
          console.log("data",data);
        });
    },
    specialityHeader(header) {
      return header;
    },
  },
};
</script>

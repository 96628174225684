import Shell from '../../../layouts/Shell.vue'
import SpecialitiesMain from '../views/SpecialitiesMain.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/specialities',
            name: 'SpecialitiesMain',
            component: SpecialitiesMain,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes
import Shell from '../../../layouts/Shell.vue'
import SettingsMain3 from '../views/SettingsMain3.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/settings3',
            name: 'SettingsMain3',
            component: SettingsMain3,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes


<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Specialities</h1>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">United Kingdom</span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Speciality</span>
          <span class="box-label">
            <v-select
              v-model="selectedSpeciality"
              label="speciality"
              :clearable="false"
              @input="fetchData"
              :options="$store.state.prototypedata.specialities"
            />
          </span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Total Population</span>
          <span class="box-label">1,199,870</span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Export Data</span>
          <button class="btn btn-primary btn-outline btn-small mr-4">
            CSV
          </button>
          <button class="btn btn-primary btn-outline btn-small mr-4">
            PDF
          </button>
          <button class="btn btn-primary btn-outline btn-small">XML</button>
        </div>
      </div>
    </div>

    <nav class="route-nav">
      <ul>
        <li @click="editActiveSummary('patientEpisodes')" :class="activeSummary == 'patientEpisodes' ? 'active' : ''">
          <span>Patient Episodes</span>
        </li>
        <li @click="editActiveSummary('spaceProperty')" :class="activeSummary == 'spaceProperty' ? 'active' : ''">
          <span>Space & Property</span>
        </li>
        <li @click="editActiveSummary('finance')" :class="activeSummary == 'finance' ? 'active' : ''">
          <span>Finance</span>
        </li>
        <li @click="editActiveSummary('workforce')" :class="activeSummary == 'workforce' ? 'active' : ''">
          <span>Workforce</span>
        </li>
      </ul>
    </nav>

    <div class="route-content">
      <div v-if="activeSummary == 'patientEpisodes'">
        <h1 class="text-3xl my-6 active">Patient Episodes</h1>

        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Patient Episodes</th>
              <th class="border px-4 py-2">Patient Episodes Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(patientEpisodes,
                  index) in specialityPatientEpisodesFilter"
                  :key="index"
                >
                  {{ patientEpisodes }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(patientEpisodesQuantity,
                  index) in specialityPatientEpisodesTotalFilter"
                  :key="index"
                >
                  {{ patientEpisodesQuantity | numeral }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="activeSummary == 'spaceProperty'">
        <h1 class="text-3xl my-6 active">Space & Property</h1>

        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Space & Property</th>
              <th class="border px-4 py-2">Quantity</th>
              <th class="border px-4 py-2">Capital Cost Per Item</th>
              <th class="border px-4 py-2">Total Capital Cost</th>
              <th class="border px-4 py-2">Life Cycle</th>
              <th class="border px-4 py-2">Annualised Cost</th>
              <th class="border px-4 py-2">Annualised Maintenance Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(spaceAndProperty,
                  index) in specialitySpaceAndPropertyFilter"
                  :key="index"
                >
                  {{ spaceAndProperty }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(spaceAndPropertyQuantity,
                  index) in specialitySpaceAndPropertyQuantityFilter"
                  :key="index"
                >
                  {{ spaceAndPropertyQuantity | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(capitalCostPerItem,
                  index) in specialityCapitalCostPerItemFilter"
                  :key="index"
                >
                    {{ capitalCostPerItem | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(totalCapitalCost,
                  index) in specialityTotalCapitalCostFilter"
                  :key="index"
                >
                    {{ totalCapitalCost | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(lifeCycle, index) in specialityLifeCycleFilter"
                  :key="index"
                >
                  {{ lifeCycle }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(annualisedCost,
                  index) in specialityAnnualisedCostFilter"
                  :key="index"
                >
                    {{ annualisedCost | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(annualisedMaintenanceCost,
                  index) in specialityAnnualisedMaintenanceCostFilter"
                  :key="index"
                >
                    {{ annualisedMaintenanceCost | numeral }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="activeSummary == 'finance'">
        <h1 class="text-3xl my-6 active">Finance</h1>
        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Finance</th>
              <th class="border px-4 py-2">Finance Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(patientEpisodes,
                  index) in specialityFinanceNameFilter"
                  :key="index"
                >
                  {{ patientEpisodes }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(financeName, index) in specialityFinanceTotalFilter"
                  :key="index"
                >
                  {{ financeName | numeral }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="activeSummary == 'workforce'">
        <h1 class="text-3xl my-6 active">Workforce</h1>
        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Workforce</th>
              <th class="border px-4 py-2">Workforce Quantity</th>
              <th class="border px-4 py-2">Cost Per Staff</th>
              <th class="border px-4 py-2">Total Workforce Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(workforceName,
                  index) in specialityWorkforceNameFilter"
                  :key="index"
                >
                  {{ workforceName }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(financeName,
                  index) in specialityWorkforceQuantityFilter"
                  :key="index"
                >
                  {{ financeName | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(workforceCostPerStaff,
                  index) in specialityWorkforceCostPerStaffFilter"
                  :key="index"
                >
                  {{ workforceCostPerStaff | numeral }}
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(workforceTotalCost,
                  index) in specialityWorkforceTotalWorkforceCostFilter"
                  :key="index"
                >
                  {{ workforceTotalCost | numeral }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeSummary: "patientEpisodes",
      selectedSpeciality: "AHPs",
      indexPatient: 0,
      indexFinance: 0,
      indexWorkforce: 0,
      indexSpace: 0
    };
  },
  mounted() {
    console.log ("SpecialitiesMain.Vue: mounted");
    const speciality = {
      speciality: "AHPs",
      apiString: "ahpsone-collection",
    };
    this.fetchData(speciality);
  },
  computed: {
    //console.log ("SpecialitiesMain.Vue: computed");
    store() {
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    specialityData() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return true;
      }
      return null;
    },
    specialityWorkforceCostPerStaffFilter() {
        if (this.$store.state.prototypedata.specialityData.length > 0) {
            return this.$store.state.prototypedata.specialityData[this.indexWorkforce].cost_per_staff;
      }
      return null;
    },
    specialityWorkforceTotalWorkforceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexWorkforce]
          .total_workforce_cost;
      }
      return null;
    },
    specialityWorkforceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexWorkforce].workforce;
      }
      return null;
    },
    specialityWorkforceQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexWorkforce]
          .workforce_quantity;
      }
      return null;
    },
    specialitySpaceAndPropertyFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexSpace]
          .space_and_property;
      }
      return null;
    },
    specialitySpaceAndPropertyQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexSpace]
          .space_and_property_quantity;
      }
      return null;
    },
    specialityCapitalCostPerItemFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexSpace]
          .capital_cost_per_item;
      }
      return null;
    },
    specialityTotalCapitalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexSpace]
          .total_capital_cost;
      }
      return null;
    },
    specialityLifeCycleFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexSpace].life_cycle;
      }
      return null;
    },
    specialityAnnualisedCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexSpace]
          .annualised_cost;
      }
      return null;
    },
    specialityAnnualisedMaintenanceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexSpace]
          .annualised_maintenance_cost;
      }
      return null;
    },
    specialityPatientEpisodesFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexPatient]
          .patient_episodes;
      }
      return null;
    },
    specialityPatientEpisodesTotalFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexPatient]
          .patient_episodes_total;
      }
      return null;
    },
    specialityFinanceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexFinance].finance;
      }
      return null;
    },
    specialityFinanceTotalFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexFinance].finance_total;
      }
      return null;
    },
  },
  methods: {
    fetchData(speciality) 
    {
      console.log ("SpecialitiesMain.Vue: fetchData (",speciality);
      this.specialityHeader(speciality.speciality);
      this.$store
        .dispatch("attemptFetchSpecialityData", speciality)
        .then((data) => {
          console.log("data",data);
        });

      if ('patient_episodes' in this.$store.state.prototypedata.specialityData[0])
      {
         this.indexPatient = 0;
      }

      if ('patient_episodes' in this.$store.state.prototypedata.specialityData[1])
      {
         this.indexPatient = 1;
      }

      if ('patient_episodes' in this.$store.state.prototypedata.specialityData[2])
      {
         this.indexPatient = 2;
      }

      if ('patient_episodes' in this.$store.state.prototypedata.specialityData[3])
      {
         this.indexPatient = 3;
      }

      if ('space_and_property' in this.$store.state.prototypedata.specialityData[0])
      {
          this.indexSpace = 0;
      }

      if ('space_and_property' in this.$store.state.prototypedata.specialityData[1])
      {
         this.indexSpace = 1;
      }

      if ('space_and_property' in this.$store.state.prototypedata.specialityData[2])
      {
         this.indexSpace = 2;
      }

      if ('space_and_property' in this.$store.state.prototypedata.specialityData[3])
      {
         this.indexSpace = 3;
      }

      if ('workforce' in this.$store.state.prototypedata.specialityData[0])
      {
         this.indexWorkforce = 0;
      }

      if ('workforce' in this.$store.state.prototypedata.specialityData[1])
      {
         this.indexWorkforce = 1;
      }

      if ('workforce' in this.$store.state.prototypedata.specialityData[2])
      {
         this.indexWorkforce = 2;
      }

      if ('workforce' in this.$store.state.prototypedata.specialityData[3])
      {
         this.indexWorkforce = 3;
      }

      if ('finance' in this.$store.state.prototypedata.specialityData[0])
      {
         this.indexFinance = 0;
      }

      if ('finance' in this.$store.state.prototypedata.specialityData[1])
      {
         this.indexFinance = 1;
      }

      if ('finance' in this.$store.state.prototypedata.specialityData[2])
      {
         this.indexFinance = 2;
      }

      if ('finance' in this.$store.state.prototypedata.specialityData[3])
      {
         this.indexFinance = 3;
      }

      console.log ('indexPatient    : ',this.indexPatient);
      console.log ('indexWorkforce  : ',this.indexWorkforce);
      console.log ('indexSpace      : ',this.indexSpace);
      console.log ('indexFinance    : ',this.indexFinance);
    },
    specialityHeader(header) {
      return header;
    },
    editActiveSummary(activeSummary) {
      this.activeSummary = activeSummary
    }
  },
};
</script>

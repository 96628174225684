<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">
          5 Year Optimization
        </h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Surrey - United Kingdom</span>
        </div>
      </div>      
    </div>

    <nav class="route-nav">
      <ul>
        <li
          @click="editStageSummary('stage')"
          :class="stageSummary == 'stage' ? 'active' : ''"
        >
          <span>Stage 3</span>
        </li>
        <li
          @click="editStageSummary('specialities')"
          :class="stageSummary == 'specialities' ? 'active' : ''"
        >
          <span>Specialities</span>
        </li>
        <li
          @click="editStageSummary('buildingsFM')"
          :class="stageSummary == 'buildingsFM' ? 'active' : ''"
        >
          <span>Buildings and FM</span>
        </li>
        <li
          @click="editStageSummary('opImprov')"
          :class="stageSummary == 'opImprov' ? 'active' : ''"
        >
          <span>Operational Improvement</span>
        </li>  
      </ul>
    </nav>

    <div v-if="stageSummary == 'stage'">
      <div class="route-content">

      <div class="stat-cards" v-if="workForceDataThree !== null">

          <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Population (2023)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total"> {{ this.getPopulation2023() }}</span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'patientEpisode' ? 'active' : ''"  @click="fillData('patientEpisode')">
          <span class="card-header">Patient Episode Productivity (2023)</span>
          <div class="card-icon">
            <img
              src="@/assets/img/icons/icon-patient.svg"
              alt="Patient Episodes"
            />
            <span class="card-total">{{ getTileData(0) }}</span>
          </div>
          <span class="card-info">Total patient episodes</span>
        </div>

        <div class="stat-cards--item single" style="width:300px" :class="activeSummary === 'assets' ? 'active' : ''" @click="fillData('assets')">
          <span class="card-header">Assets (2023)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
            <span class="card-total">{{ getTileData(1) }}</span>
          </div>
          <span class="card-info">Total Assets</span>
        </div>

        <div class="stat-cards--item single" style="width:300px" :class="activeSummary === 'workforceCost' ? 'active' : ''" @click="fillData('workforceCost')">
          <span class="card-header">Workforce (2023)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
            <span class="card-total">{{ getTileData(2) }}</span>
          </div>
          <span class="card-info">Total Workforce Full Time Equivalent (FTE)</span>
        </div>

        <div class="stat-cards--item single" style="width:300px" :class="activeSummary === 'finance' ? 'active' : ''" @click="fillData('finance')">
          <span class="card-header">Finance (2023)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
            <span class="card-total">{{ getTileData(3) }}</span>
          </div>
          <span class="card-info">Budget Requirement</span>
        </div>

        <!-- line 2 -->
        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Population (2024)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total"> {{ this.getPopulation2024() }} </span>
          </div>
        </div>

        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Patient Episode Productivity (2024)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total">{{ getTileData(4) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Assets (2024)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
            <span class="card-total">{{ getTileData(5) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Workforce (2024)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
            <span class="card-total">{{ getTileData(6) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Finance (2024)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
            <span class="card-total">{{ getTileData(7) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <!-- line 3 -->
        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Population (2025)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total"> {{ this.getPopulation2025() }}</span>
          </div>
        </div>

        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Patient Episode Productivity (2025)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total">{{ getTileData(8) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Assets (2025)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
            <span class="card-total">{{ getTileData(9) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Workforce (2025)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
            <span class="card-total">{{ getTileData(10) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Finance (2025)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
            <span class="card-total">{{ getTileData(11) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <!-- line 4 -->
        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Population (2026)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total"> {{ this.getPopulation2026() }}</span>
          </div>
        </div>

        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Patient Episode Productivity (2026)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total">{{ getTileData(12) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Assets (2026)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
            <span class="card-total">{{ getTileData(13) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Workforce (2026)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
            <span class="card-total">{{ getTileData(14) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Finance (2026)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
            <span class="card-total">{{ getTileData(15) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <!-- line 5 -->
        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Population (2027)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total"> {{ this.getPopulation2027() }}</span>
          </div>
        </div>


        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Patient Episode Productivity (2027)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total">{{ getTileData(16) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Assets (2027)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
            <span class="card-total">{{ getTileData(17) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Workforce (2027)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
            <span class="card-total">{{ getTileData(18) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Finance (2027)</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
            <span class="card-total">{{ getTileData(19) }}</span>
          </div>
          <span class="card-info"></span>
        </div>
      </div>

        <div v-if="activeSummary == 'workforceCost'">
          <h1 class="text-3xl my-6 active">Workforce Summary</h1>

          <div class="chartcontainer3">
            <div class="title_text3">WORKFORCE</div>
            <div clas="chart_view3">
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
            </div>
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary" v-if="stageThree !== null">
            <thead>
              <tr>
                <th class="border px-4 py-2">Workforce</th>
                <th class="border px-4 py-2">Quantity</th>
                <th class="border px-4 py-2">Staff Cost</th>
                <th class="border px-4 py-2">Total Workforce Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div
                    v-for="(workforce, index) in workforceSummaryTableWorkforceFilter"
                    :key="index"
                  >
                    {{ workforce }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(quantity, index) in workforceSummaryTableQuantityFilter"
                    :key="index"
                  >
                    {{ quantity | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(costPerStaff, index) in workforceSummaryTableQuantityFilter"
                    :key="index"
                  >
                    {{ costPerStaff | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      totalWorkforceCost, index
                    ) in workforceSummaryTableCostPerStaffFilter"
                    :key="index"
                  >
                    {{ totalWorkforceCost | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="activeSummary == 'assets'">
          <!-- start -->
          <h1 class="text-3xl my-6 active">Assets Summary</h1>

          <div class="chartcontainer3">
            <div class="title_text3">ASSETS</div>
            <div class="chart_view3">
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
            </div>
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary" v-if="stageThree !== null">
            <thead>
              <tr>
                <th class="border px-4 py-2">Assets</th>
                <th class="border px-4 py-2">Quantity</th>
                <th class="border px-4 py-2">Capital Cost Per Item</th>
                <th class="border px-4 py-2">Total Capital Cost</th>
                <th class="border px-4 py-2">Life Cycle</th>
                <th class="border px-4 py-2">Annual Cost</th>
                <th class="border px-4 py-2">Annual Maintenance Cost</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="border">
                  <div v-for="(assets, index) in assetsFilter" :key="index">
                    {{ assets }}
                  </div>
                </td>
                <td class="border">
                  <div
                    v-for="(assetsQuantity, index) in assetsQuantityFilter"
                    :key="index"
                  >
                    {{ assetsQuantity | numeral }}
                  </div>
                </td>
                <td class="border">
                  <div
                    v-for="(
                      capitalCostPerItem, index
                    ) in assetsCapitalCostPerItemFilter"
                    :key="index"
                  >
                    {{ capitalCostPerItem | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      totalCapitalCost, index
                    ) in assetsTotalCapitalCostFilter"
                    :key="index"
                  >
                    {{ totalCapitalCost | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(lifeCycle, index) in assetsLifeCycleFilter"
                    :key="index"
                  >
                    {{ lifeCycle }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      annualisedCost, index
                    ) in assetsAnnualisedCostFilter"
                    :key="index"
                  >
                    {{ annualisedCost | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      annualisedMaintenanceCost, index
                    ) in assetsAnnualisedMaintenanceCostFilter"
                    :key="index"
                  >
                    {{ annualisedMaintenanceCost | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- finish -->

        <div v-if="activeSummary == 'patientEpisode'">
          <h1 class="text-3xl my-6 active">Patient Episodes</h1>
          <!-- start -->
          <div class="chartcontainer3">
            <div class="title_text3">PATIENT EPISODES</div>
            <div class="chart_view3">
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
            </div>
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary" v-if="workForceDataThree !== null">
            <thead>
              <tr>
                <th class="border px-4 py-2">Patient Episodes</th>
                <th class="border px-4 py-2">Patient Episodes Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div
                    v-for="(
                      patientEpisodes, index
                    ) in specialityPatientEpisodesFilter"
                    :key="index"
                  >
                    {{ patientEpisodes }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      patientEpisodesQuantity, index
                    ) in specialityPatientEpisodesTotalFilter"
                    :key="index"
                  >
                    {{ patientEpisodesQuantity | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /div -->

        <div v-if="activeSummary == 'finance'">
          <h1 class="text-3xl my-6 active">Finance</h1>

          <!-- start -->

          <div class="chartcontainer3">
            <div class="title_text3">FINANCE</div>
            <div class="chart_view3">
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
          </div>
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary" v-if="stageThree !== null">
            <thead>
              <tr>
                <th class="border px-4 py-2">Finance</th>
                <th class="border px-4 py-2">Finance Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div
                    v-for="(
                      patientEpisodes, index
                    ) in specialityFinanceNameFilter"
                    :key="index"
                  >
                    {{ patientEpisodes }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(financeName, index) in specialityFinanceTotalFilter"
                    :key="index"
                  >
                    {{ financeName | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- finish -->
      </div>
    </div>

    <div v-if="stageSummary == 'opImprov'">
      <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">
          

          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Improvement Area</span>
              <span class="box-label">
                <v-select
                  v-model="improvementSpecialityArea"
                  label="label"
                  :clearable="false"
                  :options="$store.state.prototypedata.dataTypes2"
                />
              </span>
            </div>
          </div>
        </div>

        <!-- now check to see if any of the specialities areas have changed -->
        <div v-if="improvementType == 'diagnostics'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 3</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Daily Diagnostics Coordination</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Daily Diagnostics Coordination <div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: by Modality</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: by Modality<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm General Management oversight of daily lists</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm General Management oversight of daily lists<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Daily Reporting of Actual versus TOM per clinical specialty for IP and DC</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Reporting of Actual versus TOM per clinical specialty for IP and DC<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Commence uplift of Day-Case Activity - reduction of IP and ALOS</td>
                    <td class="border" style="padding: 10px;">20</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Commence uplift of Day-Case Activity - reduction of IP and ALOS<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Trauma Diagnostics 24/7 capacity, including day-case over-bookings</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Trauma Diagnostics 24/7 capacity, including day-case over-bookings<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                </tbody>
              </table>          
            <!-- /div -->
          </div>
        </div>

           <!-- now check to see if any of the specialities areas have changed -->
           <div v-if="improvementType == 'OPD'">
            <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 3</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Daily OPD Coordination</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Daily OPD Coordination <div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: by Pre-Ops</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: by Pre-Ops<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">OPD Coordination and Activity linked to TOM</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">OPD Coordination and Activity linked to TOM<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm General Management oversight of daily lists</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm General Management oversight of daily lists<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Daily Reporting of Actual versus TOM per clinical specialty</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Reporting of Actual versus TOM per clinical specialty<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>


        <div v-if="improvementType == 'OR'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 3</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>                                   
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Daily OR Coordination </td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Daily OR Coordination<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Pre-Ops</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Pre-Ops<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Pre-Session Theatre Briefing (WHO)</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Pre-Session Theatre Briefing (WHO)<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Early Knife-Skin</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Early Knife-Skin<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Reception - OR - Recovery Turnaround Timing</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Reception - OR - Recovery Turnaround Timing<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Ward - OR - Ward</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Ward - OR - Ward<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">OR Coordination and Activity linked to TOM</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">OR Coordination and Activity linked to TOM<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm General Management oversight of daily lists</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm General Management oversight of daily lists<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Daily Reporting of Actual versus TOM per clinical specialty for IP and DC</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Reporting of Actual versus TOM per clinical specialty for IP and DC<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Commence uplift of Day-Case Activity - reduction of IP and ALOS</td>
                    <td class="border" style="padding: 10px;">20</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Commence uplift of Day-Case Activity - reduction of IP and ALOS<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Trauma OR 24/7 capacity, including day-case over-bookings</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Trauma OR 24/7 capacity, including day-case over-bookings<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>

        <div v-if="improvementType == 'ER'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 3</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Daily ER Coordination</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Daily ER Coordination <div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Minor Escalation</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Minor Escalation<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Major Escalation</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Major Escalation<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">ER Coordination and Activity linked to TOM</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">ER Coordination and Activity linked to TOM<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Confirm General Management oversight of daily lists</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm General Management oversight of daily lists<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Daily Reporting of Actual versus TOM per clinical specialty  for Trauma Surgery</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Reporting of Actual versus TOM per clinical specialty  for Trauma Surgery<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>                                                                     
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>

        <div v-if="improvementType == 'bedflow'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 2</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Daily Ward-Based Discharge Planning Process pre-Ward Rounds </td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Daily Ward-Based Discharge Planning Process pre-Ward Rounds<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Operational BedFLow SOPs</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Operational BedFLow SOPs<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Confirm Multi-disciplinary Specialist Consultant ER attendance</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Multi-disciplinary Specialist Consultant ER attendance<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Confirm Consultant Coverage per Specialty per Bed</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Consultant Coverageg per Specialty per Bed<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">ER Coordination and Activity linked to TOM</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">ER Coordination and Activity linked to TOM<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Consultant of the Week or similar cover per Bed, 7 days per week</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Consultant of the Week or similar cover per Bed, 7 days per week<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm General Management oversight of daily lists</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm General Management oversight of daily lists<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>    <tr>
                    <td class="border" style="padding: 10px;">Daily Reporting of Actual versus TOM per clinical specialty  for Trauma Surgery</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Reporting of Actual versus TOM per clinical specialty  for Trauma Surgery<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
      
                </tbody>
              </table>
            <!-- /div -->
          </div>  
        </div>
      </div>
    </div>

    <div v-if="stageSummary == 'specialities'">
      <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">
          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Speciality</span>
              <span class="box-label">
                <v-select
                  v-model="selectedSpeciality"
                  label="speciality"
                  :clearable="false"
                  @input="setSpeciality"
                  :options="$store.state.prototypedata.specialities2"
                />
              </span>
            </div>
          </div>

          <div class="route-speciality--box">
          <div class="box">
          <span class="box-header">Year</span>
          <span class="box-label">
            <v-select
              v-model="selectedYear"
              label="year"
              :clearable="false"
              @input="setYear"
              :options="$store.state.prototypedata.years"
            />
          </span>
        </div>
        </div>

          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Speciality Area</span>
              <span class="box-label">
                <v-select
                  v-model="selectedSpecialityArea"
                  label="label"
                  :clearable="false"
                  :options="$store.state.prototypedata.dataTypes"
                />
              </span>
            </div>
          </div>
        </div>

        <!-- now check to see if any of the specialities areas have changed -->
        <div v-if="specialityType == 'patientEpisodes'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Patient Episodes</th>
                    <th class="border px-4 py-2">Patient Episodes Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodes, index
                        ) in specialityPatientEpisodesFilter"
                        :key="index"
                      >
                        {{ patientEpisodes }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodesQuantity, index
                        ) in specialityPatientEpisodesTotalFilter"
                        :key="index"
                      >
                        {{ patientEpisodesQuantity | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'finance'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Finance</th>
                    <th class="border px-4 py-2">Finance Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodes, index
                        ) in specialityFinanceNameFilter"
                        :key="index"
                      >
                        {{ patientEpisodes }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          financeName, index
                        ) in specialityFinanceTotalFilter"
                        :key="index"
                      >
                        {{ financeName | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'space'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Space & Property</th>
                    <th class="border px-4 py-2">Quantity</th>
                    <th class="border px-4 py-2">Capital Cost Per Item</th>
                    <th class="border px-4 py-2">Total Capital Cost</th>
                    <th class="border px-4 py-2">Life Cycle</th>
                    <th class="border px-4 py-2">Annualised Cost</th>
                    <th class="border px-4 py-2">
                      Annualised Maintenance Cost
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          spaceAndProperty, index
                        ) in specialitySpaceAndPropertyFilter"
                        :key="index"
                      >
                        {{ spaceAndProperty }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          spaceAndPropertyQuantity, index
                        ) in specialitySpaceAndPropertyQuantityFilter"
                        :key="index"
                      >
                        {{ spaceAndPropertyQuantity | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          capitalCostPerItem, index
                        ) in specialityCapitalCostPerItemFilter"
                        :key="index"
                      >
                        {{ capitalCostPerItem | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          totalCapitalCost, index
                        ) in specialityTotalCapitalCostFilter"
                        :key="index"
                      >
                        {{ totalCapitalCost | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(lifeCycle, index) in specialityLifeCycleFilter"
                        :key="index"
                      >
                        {{ lifeCycle }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          annualisedCost, index
                        ) in specialityAnnualisedCostFilter"
                        :key="index"
                      >
                        {{ annualisedCost | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          annualisedMaintenanceCost, index
                        ) in specialityAnnualisedMaintenanceCostFilter"
                        :key="index"
                      >
                        {{ annualisedMaintenanceCost | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'workforce'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Workforce</th>
                    <th class="border px-4 py-2">Workforce Quantity</th>
                    <th class="border px-4 py-2">Cost Per Staff</th>
                    <th class="border px-4 py-2">Total Workforce Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          workforceName, index
                        ) in specialityWorkforceNameFilter"
                        :key="index"
                      >
                        {{ workforceName }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          financeName, index
                        ) in specialityWorkforceQuantityFilter"
                        :key="index"
                      >
                        {{ financeName | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          workforceCostPerStaff, index
                        ) in specialityWorkforceCostPerStaffFilter"
                        :key="index"
                      >
                        {{ workforceCostPerStaff | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          workforceTotalCost, index
                        ) in specialityWorkforceTotalWorkforceCostFilter"
                        :key="index"
                      >
                        {{ workforceTotalCost | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div v-if="stageSummary == 'buildingsFM'">
      <div class="route-content">
        <div v-if="specialityData != null">
          <!-- 1 open -->
          <table class="table-primary">
            <thead>
              <tr>
                <th class="border px-4 py-2">Area</th>
                <th class="border px-4 py-2">Total Floor Space</th>
                <th class="border px-4 py-2">Full Development Cost</th>
                <th class="border px-4 py-2">Net Build Cost</th>
                <th class="border px-4 py-2">Avg FM Cost</th>
                <th class="border px-4 py-2">FM Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div v-for="(jobPlan, index) in areaAreaFilter" :key="index">
                    {{ jobPlan }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanPatients, index) in areaTFSFilter"
                    :key="index"
                  >
                    {{ jobPlanPatients }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanperSessions, index) in areaNBCFilter"
                    :key="index"
                  >
                    {{ jobPlanperSessions }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanannSessions, index) in areaFDCFilter"
                    :key="index"
                  >
                    {{ jobPlanannSessions }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in areaAFMCostFilter"
                    :key="index"
                  >
                    {{ jobPlanweekSessions }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in areaFMCostFilter"
                    :key="index"
                  >
                    {{ jobPlanweekSessions }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "bar",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stageSummary: "stage",
      selectedSpeciality: "AHPs",
      selectedYear: "2026",
      improvementSpecialityArea: "Diagnostics",
      apiPayload: { speciality: "", apiString: "", year: "" },
      activeSummary: "workforceCost",
      activeSpeciality: "AHPs",
      improvementType: "Diagnostics",
      activeYear: "2023",
      selectedSpecialityArea: "Patient Episodes",
      specialityType: "Patient Episodes",
      specialityApi: "ahps_surrey_stage_3_2023_collection",
      tileInformation: {},
      firstUpdate: false,
    };
  },
  beforeMount() {
    console.log("Summary3.vue before mounted");
    this.apiPayload.apiString = "grandsummarytwo-collection";
  
    
    this.fetchDataSummaries ();


  },
  mounted() {
    const speciality = {
      speciality: "Grand Summary 3",
      apiString: "grandsummarythreeyearone-collection",
    };
    this.fetchData(speciality);

    this.apiPayload.speciality = "AHPs";
    this.apiPayload.apiString = "grandsummarythreeyearone-collection";
    this.apiPayload.year = "2023";

    console.log ("mounted - apiPayload", this.apiPayload);


    this.fetchDataSummaries();

    this.fillData("assets");
  },
  watch: {
    selectedSpecialityArea: {
      handler: function (val) {
        console.log(
          "selectedSpecialityArea: Value Change: ",
          val.label,
          val.string
        );
        this.editSpecialityType(val.label);
        console.log("speciality: ", this.specialityApi);
        this.apiPayload.apiString = this.specialityApi;
        this.getMongoDBData();
      },
      deep: true,
    },
    improvementSpecialityArea: {
      handler: function (val) {
        console.log(
          "improvementArea: Value Change: ",
          val.label,
          val.string
        );
        this.editImprovementType(val.label);
        //console.log("speciality: ", this.specialityApi);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      },
      deep: true,
    },
    activeSummary: {
      handler: function (newvalue) {
        console.log("The new activeSummary value is ", newvalue);
        this.fillData (newvalue);
      },
      deep: true,
    },
    stageSummary: {
      handler: function (newvalue) {
        console.log("The new stageSummary value is ", newvalue);      
      },
      deep: true,
    },
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    workForceDataThree() {
      console.log(
        "this.$store.state.prototypedata.stageThree.length: ",
        this.$store.state.prototypedata.stageThree.length
      );
      if (this.$store.state.prototypedata.stageThree.length > 0) {
        return true;
      }
      return null;
    },
    specialityData() {
      console.log(
        "specialityData: ",
        this.$store.state.prototypedata.specialityData.length
      );
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return true;
      }
      return null;
    },
    stageData()
    {
       if (this.$store.state.prototypedata.stageThreeYearOne.length > 0)
       {
        return true;
       }

       return null;
    },
    getAreaIndex1() {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "total_floor_space" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getWorkforceIndex() {
      var i = 0;
      var index = 0;

      for (i = 0; i < this.$store.state.prototypedata.stageThree.length; i++) {
        if ("cost_per_staff" in this.$store.state.prototypedata.stageThree[i]) {
          index = i;
        }
      }

      return index;
    },
    getAreaIndex() {
      var i = 0;
      var index = 0;

      console.log ("stageThree length: ", this.$store.state.prototypedata.stageThree.length);
      console.log ("speciality length: ", this.$store.state.prototypedata.stageThree.length);

      for (i = 0; i < this.$store.state.prototypedata.stageThree.length; i++) {
        if ("total_floor_space" in this.$store.state.prototypedata.stageThree[i]) {
          index = i;
        }
      }

      return index;
    },
    getWorkforceIndex1() {
      var i = 0;
      var index = 0;

      if (this.$store.state.prototypedata.specialityData.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          if (
            "workforce" in this.$store.state.prototypedata.specialityData[i]
          ) {
            index = i;
          }
        }
      }

      return index;
    },
    getPatientIndex() {
      var i = 0;
      var index = 0;

      for (i = 0; i < this.$store.state.prototypedata.stageThree.length; i++) {
        if (
          "patient_episodes_total" in
          this.$store.state.prototypedata.stageThree[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getPatientIndex1() {
      var i = 0;
      var index = 0;

      if (this.$store.state.prototypedata.specialityData.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          if (
            "patient_episodes" in
            this.$store.state.prototypedata.specialityData[i]
          ) {
            index = i;
          }
        }
      }

      return index;
    },
    getSpaceIndex() {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "space_and_property" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getAssetIndex() {
      var i = 0;
      var index = 0;

      for (i = 0; i < this.$store.state.prototypedata.stageThree.length; i++) {
        if (
          "annualised_cost" in this.$store.state.prototypedata.stageThree[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getFinanceIndex() {
      var i = 0;
      var index = 0;

      for (i = 0; i < this.$store.state.prototypedata.stageThree.length; i++) {
        if ("finance_total" in this.$store.state.prototypedata.stageThree[i]) {
          index = i;
        }
      }

      return index;
    },
    getFinanceIndex1() {
      var i = 0;
      var index = 0;


      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if ("finance" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },
    
    getPopulationIndex() {
      var i = 0;
      var index = 0;

      //console.log("getFinanceIndex");

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if ("population" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },
    getPopulation() {
      var i = this.getPopulationIndex;

      console.log ("population index ", i);
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getPopulationIndex
        ].population[0];
      }
      return null;
    },
    specialityWorkforceCostPerStaffFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex].cost_per_staff;
      }
      return null;
    },
    specialityWorkforceTotalWorkforceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex].total_workforce_cost;
      }
      return null;
    },
    // Workforce Summary Table -- Workforce column
    specialityWorkforceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex].workforce;
      }
      return null;
    },
    
    // Workforce Table -- Quantity column
    specialityWorkforceQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].workforce_quantity;
      }
      return null;
    },
    workforceSummaryTableWorkforceFilter() {
      console.log("workforceSummaryTableWorkforceFilter");
      if (this.$store.state.prototypedata.stageThree.length > 0) {
        var i = this.getWorkforceIndex

        if (i >= 0) {
          return this.$store.state.prototypedata.stageThree[i].workforce;
        }
      }
      return null;
    },
    workforceSummaryTableQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableQuantityFilter)
      );

      return str;
    },
    assetsTotalCapitalCostFilter() {
      if (this.$store.state.prototypedata.stageThree.length > 0) {
        return this.$store.state.prototypedata.stageThree[this.getAssetIndex]
          .total_capital_cost;
      }
      return null;
    },
    assetsCapitalCostPerItemFilter() {
      if (this.$store.state.prototypedata.stageThree.length > 0) {
        return this.$store.state.prototypedata.stageThree[this.getAssetIndex]
          .capital_cost_per_item;
      }
      return null;
    },
    workforceSummaryTableQuantityFilter() {
      if (this.$store.state.prototypedata.stageThree.length > 0) {
        return this.$store.state.prototypedata.stageThree[
          this.getWorkforceIndex].workforce_quantity;
      }
      return null;
    },
    workforceSummaryTableCostPerStaffFilter() {
      if (this.$store.state.prototypedata.stageThree.length > 0) {
        return this.$store.state.prototypedata.stageThree[
          this.getWorkforceIndex].cost_per_staff;
      }
      return null;
    },
    workforceSummaryTableCostPerStaffFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableCostPerStaffFilter)
      );

      return str;
    },
    workforceSummaryTableTotalWorkforceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("cost_per_staff")
        ].total_workforce_cost;
      }
      return null;
    },
    workforceSummaryTableTotalWorkforceCostFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableTotalWorkforceCostFilter)
      );

      return str;
    },
    specialityPatientEpisodesTotalFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getPatientIndex
        ].patient_episodes_total;
      }
      return null;
    },
    specialitySpaceAndPropertyFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].space_and_property;
      }
      return null;
    },
    specialitySpaceAndPropertyQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].space_and_property_quantity;
      }
      return null;
    },
    specialityCapitalCostPerItemFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].capital_cost_per_item;
      }
      return null;
    },
    specialityTotalCapitalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].total_capital_cost;
      }
      return null;
    },
    specialityLifeCycleFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].life_cycle;
      }
      return null;
    },
    specialityAnnualisedCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].annualised_cost;
      }
      return null;
    },
    specialityAnnualisedMaintenanceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].annualised_maintenance_cost;
      }
      return null;
    },
    areaAreaFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .area;
      }
      return null;
    },
    areaTFSFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .total_floor_space;
      }
      return null;
    },
    areaNBCFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .net_build_cost;
      }
      return null;
    },
    areaFDCFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .full_dev_cost;
      }
      return null;
    },
    areaAFMCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .avg_fm_cost;
      }
      return null;
    },
    areaFMCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .fm_cost;
      }
      return null;
    },
    gfaFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getGFAIndex]
          .gfa;
      }
      return null;
    },
    /** Finance - Specialities */
    specialityFinanceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getFinanceIndex
        ].finance;
      }
      return null;
    },
    specialityFinanceTotalFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getFinanceIndex
        ].finance_total;
      }
      return null;
    },
    specialityPatientEpisodesFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getPatientIndex
        ].patient_episodes;
      }

      return null;
    },

    specialityPatientEpisodesTotalFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.specialityPatientEpisodesTotalFilter)
      );

      return str;
    },
    assetsFilter() {
      if (this.$store.state.prototypedata.stageThree.length > 0) {
        return this.$store.state.prototypedata.stageThree[this.getAssetIndex]
          .assets;
      }
      return null;
    },
    assetsLifeCycleFilter() {
      if (this.$store.state.prototypedata.stageThree.length > 0) {
        return this.$store.state.prototypedata.stageThree[
          this.getAssetIndex].life_cycle;
      }
      return null;
    },
    assetsFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].assets;
      }
      return null;
    },
    assetsQuantityFilter() {
      if (this.$store.state.prototypedata.stageThree.length > 0) {
        return this.$store.state.prototypedata.stageThree[this.getAssetIndex]
          .assets_quantity;
      }
      return null;
    },
    assetsQuantityFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].assets_quantity;
      }
      return null;
    },
    assetsQuantityFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsQuantityFilter1)
      );

      return str;
    },
    
    assetsCapitalCostPerItemFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].capital_cost_per_item;
      }
      return null;
    },
    assetsCapitalCostPerItemFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsCapitalCostPerItemFilter1)
      );

      return str;
    },
    assetsTotalCapitalCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].total_capital_cost;
      }
      return null;
    },
    assetsTotalCapitalCostFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsTotalCapitalCostFilter1)
      );

      return str;
    },
  
    assetsLifeCycleFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsLifeCycleFilter1)
      );

      return str;
    },
    assetsAnnualisedCostFilter() {
      if (this.$store.state.prototypedata.stageThree.length > 0) {
        return this.$store.state.prototypedata.stageThree[
          this.getAssetIndex].annualised_cost;
      }
      return null;
    },
    assetsAnnualisedCostFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsAnnualisedCostFilter)
      );

      return str;
    },
    assetsAnnualisedMaintenanceCostFilter() {
      if (this.$store.state.prototypedata.stageThree.length > 0) {
        return this.$store.state.prototypedata.stageThree[
          this.getAssetIndex].annualised_maintenance_cost;
      }
      return null;
    },
    assetsAnnualisedMaintenanceCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].annualised_maintenance_cost;
      }
      return null;
    },
    assetsAnnualisedMaintenanceCostFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsAnnualisedMaintenanceCostFilter1)
      );

      return str;
    },
    assetsTotalCapitalCostOverallFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateAssetsTotalCapitalCost(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("annualised_cost")
          ].total_capital_cost
        );
      }
      return null;
    },
    assetsAnnualisedCostOverallFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateAssetsAnnualisedCost(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("annualised_cost")
          ].annualised_cost
        );
      }
      return null;
    },
    assetsAnnualisedMaintenanceCostOverallFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateAssetsAnnualisedMaintenanceCost(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("annualised_cost")
          ].annualised_maintenance_cost
        );
      }
      return null;
    },

    //-----------------------------------------------------------------------------------
    // SUMMARY ASSET
    //-----------------------------------------------------------------------------------
    // Grand Summary
    // Summary Asset Table
    // Asset Filter
    summaryAssetFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_assets")
        ].summary_assets;
      }
      return null;
    },
    // Grand Summary
    // Summary Asset Table
    // Assets Quantity Filter
    summaryAssetQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        var i = this.getDataSetIndex("summary_assets");

        if (i >= 0) {
          return this.$store.state.prototypedata.specialityData[i]
            .summary_assets_quantity;
        }
      }
      return null;
    },
    summaryAssetQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryAssetQuantityFilter)
      );

      return str;
    },
    // Grand Summary
    // Summary Asset Table
    // Asset Total Capital Cost Filter
    summaryAssetTotalCapitalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_assets")
        ].summary_assets_total_capital_cost;
      }
      return null;
    },
    summaryAssetTotalCapitalCostFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryAssetTotalCapitalCostFilter)
      );

      return str;
    },

    // Grand Summary
    // Summary Patient Table
    // Patient Episodes Filter
    summaryPatientFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 7) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_patient_episodes")
        ].summary_patient_episodes;
      }
      return null;
    },
    // Grand Summary
    // Summary Patient Table
    // Patient Quantity Filter
    summaryPatientQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 7) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_patient_episodes")
        ].summary_patient_quantity;
      }
      return null;
    },
    summaryPatientQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryPatientQuantityFilter)
      );

      return str;
    },
    calculateAssetsTotalCapitalCost(assetsTotalCapitalCostArray) {
      let assetsTotalCapitalCostFloatArray = new Array();
      for (var i = 0; i < assetsTotalCapitalCostArray.length; i++) {
        assetsTotalCapitalCostFloatArray.push(
          parseFloat(assetsTotalCapitalCostArray[i])
        );
      }

      const total = assetsTotalCapitalCostFloatArray.reduce(function (a, b) {
        return a + b;
      });
      return total;
    },
  },
  methods: {
    getTileData(tileNumber) {
      //console.log("--------------------------------------");
      //console.log("SummaryMain3: getTileData", tileNumber);

      let defaultValues = new Array();
      let index = -1;

      let currentvalue = "";

      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", "2023")));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("assets", "2023")));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("workforce", "2023")));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("finance", "2023")));
      defaultValues.push(
        this.numberWithCommas(
          this.calculateSummaryValue("patientEpisodes", "2024")
        )
      );
      defaultValues.push(
        this.numberWithCommas(this.calculateSummaryValue("assets", "2024"))
      );
      defaultValues.push(
        this.numberWithCommas(this.calculateSummaryValue("workforce", "2024"))
      );

      defaultValues.push(
        this.numberWithCommas(this.calculateSummaryValue("finance", "2024"))
      );
      defaultValues.push(
        this.numberWithCommas(
          this.calculateSummaryValue("patientEpisodes", "2025")
        )
      );
      defaultValues.push(
        this.numberWithCommas(this.calculateSummaryValue("assets", "2025"))
      );
      defaultValues.push(
        this.numberWithCommas(this.calculateSummaryValue("workforce", "2025"))
      );
      defaultValues.push(
        this.numberWithCommas(this.calculateSummaryValue("finance", "2025"))
      );
      defaultValues.push(
        this.numberWithCommas(
          this.calculateSummaryValue("patientEpisodes", "2026")
        )
      );
      defaultValues.push(
        this.numberWithCommas(this.calculateSummaryValue("assets", "2026"))
      );
      defaultValues.push(
        this.numberWithCommas(this.calculateSummaryValue("workforce", "2026"))
      );
      defaultValues.push(
        this.numberWithCommas(this.calculateSummaryValue("finance", "2026"))
      );
      defaultValues.push(
        this.numberWithCommas(
          this.calculateSummaryValue("patientEpisodes", "2027")
        )
      );
      defaultValues.push(
        this.numberWithCommas(this.calculateSummaryValue("assets", "2027"))
      );
      defaultValues.push(
        this.numberWithCommas(this.calculateSummaryValue("workforce", "2027"))
      );
      defaultValues.push(
        this.numberWithCommas(this.calculateSummaryValue("finance", "2027"))
      );

      index = this.getDataSetIndex("summary_overview");

      //console.log("GetTileData - getDataSetIndex", index);

      currentvalue = defaultValues[tileNumber];

      //console.log(
      //  "length: ",
      //  this.$store.state.prototypedata.stageThree[index].summary_overview
      //    .length
      //);

      if (index >= 0) {
        //console.log("index > 0");
        if (
          tileNumber <=
          this.$store.state.prototypedata.stageThree[index].summary_overview
            .length
        ) {
          //console.log("tileNumber");
          if (
            this.$store.state.prototypedata.stageThree[index].summary_overview[
              tileNumber
            ] == null ||
            this.$store.state.prototypedata.stageThree[index].summary_overview[
              tileNumber
            ].length == 0
          ) {
            currentvalue = defaultValues[tileNumber];
          } else {
            currentvalue =
              this.$store.state.prototypedata.stageThree[index]
                .summary_overview[tileNumber];
          }
        }
      }

      //console.log("currentvalue ", currentvalue);
      //console.log("--------------------------------------");
      return currentvalue;
    },
    setYear()
    {
       this.specialityYear (this.selectedYear.year);

       this.apiPayload.year = this.activeYear;
       this.apiPayload.speciality = this.activeSpeciality;
       this.apiPayload.apiString = "";

       this.fetchSpecialityData();

    },
    setSpeciality ()
    {
        this.specialityName (this.selectedSpeciality.speciality);

        this.apiPayload.year = this.activeYear;
        this.apiPayload.speciality = this.activeSpeciality;
        this.apiPayload.apiString = "";

        this.fetchSpecialityData ();

    },
    fetchSpecialityData() {
      console.log ("api ==================================");
      console.log("api.speciality = ", this.apiPayload.speciality);
      console.log("api.year = ", this.apiPayload.year);
      console.log("api.apiString = ", this.apiPayload.apiString);
      //console.log("api.apiString =", this.apiPayload.apiString);

      this.$store
        .dispatch("GetSpecialityDataByYear", this.apiPayload)
        .then((data) => {
          console.log("data", data);
        });
    },
    retrieveTileInformation ()
    {
        var debugVal = false;
        this.tileInformation = new Array ();

        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", "2023", debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("assets", "2023", debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("workforce", "2023", debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("finance", "2023", debugVal)));
      
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", "2024", debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("assets", "2024", debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("workforce", "2024", debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("finance", "2024", debugVal)));

        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", "2025", debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("assets", "2025", debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("workforce", "2025", debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("finance", "2025", debugVal)));

        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", "2026", debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("assets", "2026", debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("workforce", "2026", debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("finance", "2026", debugVal)));

        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", "2027", debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("assets", "2027", debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("workforce", "2027", debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("finance", "2027", debugVal)));

        console.log ("this.tileinformation: ", this.tileInformation);

    }
    ,
    getTileData1(tileNumber) {
      console.log("--------------------------------------");
      console.log("SummaryMain3: getTileData", tileNumber);
      let currentValue = "";

      //if (this.tileInformation.length > 0)
      //{
        // console.log ("getTileInformation: Tile Information: ",this.tileInformation.length)
      ///}

      if (this.tileInformation.length >= tileNumber)
      {
          currentValue = this.tileInformation[tileNumber];
         // console.log ("currentValue = ". currentValue);

      }

       console.log("currentvalue ", currentValue);
      //console.log("--------------------------------------");
      return currentValue;
    },
    logData() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        console.log(
          "logData: length: ",
          this.$store.state.prototypedata.specialityData.length
        );
        for (
          var i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          console.log(
            "item[",
            i,
            "]=",
            this.$store.state.prototypedata.specialityData[i]
          );
        }
      }
    },
    fetchData(speciality) {
      this.$store
        .dispatch("attemptFetchStageThree", speciality)
        .then((data) => {
          //console.log("stage 3 - fetched data:");
          console.log(data);

          this.fillData(this.activeSummary);
        });
    }, 
    fetchData10(speciality) {
      this.$store
        .dispatch("attemptFetchStageThree", speciality)
        .then((data) => {
          // TODO: fix this - data needs to be done with something!
          console.log(data);

          this.fillData(this.activeSummary);
        });
    },
    fetchDataSummaries() {
      this.$store.dispatch("getStageThreeSummaries").then((data) => {
        console.log("data: ", data);

        this.fillData(this.activeSummary);
      });
    },
    getMongoDBData() {
      console.log ("SummaryMain3.vue: getMongoDBData: this.apiPayload: ", this.apiPayload);
      this.$store.dispatch("GetData", this.apiPayload).then((data) => {
        console.log("data", data);
      });
    },
    specialityHeader(header) {
      return header;
    },
    editActiveStageSummary(activeSummary) {
      this.activeSummary = activeSummary;
    },
    editSpecialityType(specialityTypeName) {
      if (specialityTypeName == "Patient Episodes") {
        this.specialityType = "patientEpisodes";
      }
      if (specialityTypeName == "Finance") {
        this.specialityType = "finance";
      }
      if (specialityTypeName == "Workforce") {
        this.specialityType = "workforce";
      }
      if (specialityTypeName == "Space & Property") {
        this.specialityType = "space";
      }

      console.log(
        "editSpecialityType (",
        specialityTypeName,
        ")=",
        this.specialityType
      );
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    getIndex(dataStruct, item) {
      var id = -1;
      var i = 0;

      if (typeof dataStruct != "undefined") {
        //console.log("datastruct", dataStruct);
        if (dataStruct.length > 0) {
          for (i = 0; i < dataStruct.length; i++) {
            if (item in dataStruct[i]) {
              id = i;
            }
          }
        }
      } else {
        //console.log("GetIndex: dataStruct is undefined");
      }

      return id;
    },
    searchIndex(dataStruct, item) {
      var id = -1;
      var i = 0;

      if (typeof dataStruct != "undefined") {
        //console.log("datastruct", dataStruct);
        if (dataStruct.length > 0) {
          for (i = 0; i < dataStruct.length; i++) {
            //console.log("dataStruc[i]", dataStruct[i]);
            if (item == dataStruct[i]) {
              //console.log("item == dataStruct!");
              id = i;
            }
          }
        }
      } else {
        //console.log("GetIndex: dataStruct is undefined");
      }

      return id;
    },
    fillData(tileValue) {
      this.activeSummary = tileValue;

      this.datacollection = {
        labels: this.getBarGraphLabels(),
        datasets: [
          {
            label: "2023",
            data: this.getBarGraphDataValues2023(),
            backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
            borderColor: ["rgb(255, 99, 132)"],
            borderWidth: 1,
          },
          {
            label: "2024",
            data: this.getBarGraphDataValues2024(),
            backgroundColor: ["rgba(75, 192, 192, 0.2)"], // line 4
            borderColor: ["rgb(75, 192, 192)"],
            borderWidth: 1,
          },
          {
            label: "2025",
            data: this.getBarGraphDataValues2025(),
            backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 5
            borderColor: ["rgb(54, 162, 235)"],
            borderWidth: 1,
          },
          {
            label: "2026",
            data: this.getBarGraphDataValues2026(),
            backgroundColor: ["rgba(255, 159, 64, 0.22)"], // line 2
            borderColor: ["rgb(255, 159, 64)"],
            borderWidth: 1,
          },
          {
            label: "2027",
            data: this.getBarGraphDataValues2027(),
            backgroundColor: ["rgba(255, 205, 86, 0.2)"],
            borderColor: ["rgb(255, 205, 86)"], // line 3
            borderWidth: 1,
          },
        ],
      };
    },
    getBarGraphDataValues2023() {
      var data = new Array();    
      var debugVal = false;

      if (this.activeSummary == "workforceCost") {
        data.push(this.calculateSummaryValue("workforce", "2023",debugVal));
      } else if (this.activeSummary == "assets") {
        data.push(this.calculateSummaryValue("assets", "2023",debugVal));
      } else if (this.activeSummary == "finance") {
        data.push(this.calculateSummaryValue("finance", "2023",debugVal));
      } else if (this.activeSummary == "patientEpisode") {
        data.push(this.calculateSummaryValue("patientEpisodes", "2023",debugVal));
      }

      return data;
    },
    getBarGraphDataValues2024() {
      var data = new Array();
      var debugVal = false;

      if (this.activeSummary == "workforceCost") {
        data.push(this.calculateSummaryValue("workforce", "2024",debugVal));
      } else if (this.activeSummary == "assets") {
        data.push(this.calculateSummaryValue("assets", "2024",debugVal));
      } else if (this.activeSummary == "finance") {
        data.push(this.calculateSummaryValue("finance", "2024",debugVal));
      } else if (this.activeSummary == "patientEpisode") {
        data.push(this.calculateSummaryValue("patientEpisodes", "2024",debugVal));
      }

      return data;
    },
    getBarGraphDataValues2025() {
      var data = new Array();
      var debugVal = false;

      if (this.activeSummary == "workforceCost") {
        data.push(this.calculateSummaryValue("workforce", "2025",debugVal));
      } else if (this.activeSummary == "assets") {
        data.push(this.calculateSummaryValue("assets", "2025",debugVal));
      } else if (this.activeSummary == "finance") {
        data.push(this.calculateSummaryValue("finance", "2025",debugVal));
      } else if (this.activeSummary == "patientEpisode") {
        data.push(this.calculateSummaryValue("patientEpisodes", "2025",debugVal));
      }

      //console.log ('data:', data);

      return data;
    },
    getBarGraphDataValues2026() {
      var data = new Array();
      var debugVal = false;

      if (this.activeSummary == "workforceCost") {
        data.push(this.calculateSummaryValue("workforce", "2026",debugVal));
      } else if (this.activeSummary == "assets") {
        data.push(this.calculateSummaryValue("assets", "2026",debugVal));
      } else if (this.activeSummary == "finance") {
        data.push(this.calculateSummaryValue("finance", "2026",debugVal));
      } else if (this.activeSummary == "patientEpisode") {
        data.push(this.calculateSummaryValue("patientEpisodes", "2026",debugVal));
      }

      return data;
    },
    getBarGraphDataValues2027() {
      var data = new Array();
      var debugVal = false;

      if (this.activeSummary == "workforceCost") {
        data.push(this.calculateSummaryValue("workforce", "2027",debugVal));
      } else if (this.activeSummary == "assets") {
        data.push(this.calculateSummaryValue("assets", "2027",debugVal));
      } else if (this.activeSummary == "finance") {
        data.push(this.calculateSummaryValue("finance", "2027",debugVal));
      } else if (this.activeSummary == "patientEpisode") {
        data.push(this.calculateSummaryValue("patientEpisodes", "2027",debugVal));
      }

      return data;
    },
    getBarGraphLabels() {
      var data = new Array();

      if (this.activeSummary == "workforceCost") {
        data.push("Workforce");
      } else if (this.activeSummary == "assets") {
        data.push("Assets");
      } else if (this.activeSummary == "finance") {
        data.push("Finance");
      } else if (this.activeSummary == "patientEpisode") {
        data.push("Patient Episodes");
      }

      return data;
    },
    getGraphTitle() {
      var str;

      if (this.activeSummary == "workforceCost") {
        str = "Workforce Quantity";
      } else if (this.activeSummary == "assets") {
        str = "Asset Quantity";
      } else if (this.activeSummary == "finance") {
        str = "Finance Quantity";
      } else if (this.activeSummary == "patientEpisode") {
        str = "Patient Episodes Quantity";
      }
      return str;
    },
    calculateSummaryValue(summary, year, debug) {
      if (debug)
      {
          console.log("calculateSummaryValue :", summary + " " + year);
      }
      
      let summaryValues = new Array();
      let index = -1;
      let totalValue = "";

      if (summary == "population" && year == "2023") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          "population"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearOne[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == "2024") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          "population"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearTwo[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == "2025") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          "population"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearThree[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == "2026") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          "population"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFour[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == "2027") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          "population"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFive[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "workforce" && year == "2023") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          "summary_workforce"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearOne[index]
              .summary_workforce_quantity;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "workforce" && year == "2024") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          "summary_workforce"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearTwo[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforced yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "workforce" && year == "2025") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          "summary_workforce"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearThree[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "workforce" && year == "2026") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          "summary_workforce"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFour[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
              console.log("workforce yr4 = ", totalValue);
            }
          }
        }
      }

      if (summary == "workforce" && year == "2027") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          "summary_workforce"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFive[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr5 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == "2023") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          "summary_assets"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearOne[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr1 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == "2024") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          "summary_assets"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearTwo[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == "2025") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          "summary_assets"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearThree[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == "2026") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          "summary_assets"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFour[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr4 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == "2027") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          "summary_assets"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFive[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr5 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == "2023") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          "summary_patient_quantity"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearOne[index]
              .summary_patient_quantity;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr1 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == "2024") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          "summary_patient_quantity"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearTwo[index]
              .summary_patient_quantity;

        if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == "2025") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          "summary_patient_quantity"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearThree[index]
              .summary_patient_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == "2026") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          "summary_patient_quantity"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFour[index]
              .summary_patient_quantity;

       
          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr4 = ", totalValue);
            }
       
          }
        }
      }

      if (summary == "patientEpisodes" && year == "2027") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          "summary_patient_quantity"
        );

        if (index > 0) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFive[index]
              .summary_patient_quantity;

         
          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr5 = ", totalValue);
            }
          
          }
        }
      }

      if (summary == "finance" && year == "2023") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          "finance_total"
        );

        if (index > 0) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageThreeYearOne[index].finance,
            "Income Requirement"
          );

          temp_val =
            this.$store.state.prototypedata.stageThreeYearOne[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance yr1 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == "2024") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          "finance_total"
        );

        if (index > 0) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageThreeYearTwo[index].finance,
            "Income Requirement"
          );

          temp_val =
            this.$store.state.prototypedata.stageThreeYearTwo[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == "2025") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          "finance_total"
        );

        if (index > 0) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageThreeYearThree[index].finance,
            "Income Requirement"
          );

          temp_val =
            this.$store.state.prototypedata.stageThreeYearThree[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == "2026") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          "finance_total"
        );

        if (index > 0) {
          var temp_val = 0;
          var finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageThreeYearFour[index].finance,
            "Income Requirement"
          );


          temp_val =
            this.$store.state.prototypedata.stageThreeYearFour[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

         if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr4 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == "2027") {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          "finance_total"
        );

        if (index > 0) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageThreeYearFive[index].finance,
            "Income Requirement"
          );

         temp_val =
            this.$store.state.prototypedata.stageThreeYearFive[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);
   
          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr5 = ", totalValue);
            }
            }
        }
      }

      return totalValue;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    calculateArray(data) {
      let arrayValuesFloatArray = new Array();
      let str = "";
      let parse = false;

      if (typeof data != "undefined") {
        str = "";
        parse = false;

        for (var i = 0; i < data.length; i++) {
          str = String(data[i]);
          str = str.replaceAll(",", "");

          if (str.includes(".")) {
            parse = true;
          }

          arrayValuesFloatArray.push(parseFloat(str));
        }
      }

      const total = arrayValuesFloatArray.reduce(function (a, b) {
        return a + b;
      });
      if (parse == true) {
        return total.toFixed(2);
      } else {
        return total;
      }
    },
    getDataSetIndex(item) {
      var i = 0;
      var id = -1;

      if (this.$store.state.prototypedata.stageThree.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.stageThree.length;
          i++
        ) {
          if (item in this.$store.state.prototypedata.stageThree[i]) {
            id = i;
          }
        }
      }

      //console.log("getDataSetIndex", item, id);

      return id;
    },
    getPopulation2023()
    {
      var item = "";
      item = this.numberWithCommas(this.calculateSummaryValue("population", "2023"));

      console.log ("getPopulation2023", item);

      return item;
    },
    getPopulation2024()
    {
      var item = "";
      item = this.numberWithCommas(this.calculateSummaryValue("population", "2024"));

      console.log ("getPopulation2023", item);

      return item;
    },
    getPopulation2025()
    {
      var item = "";
      item = this.numberWithCommas(this.calculateSummaryValue("population", "2025"));

      console.log ("getPopulation2023", item);

      return item;
    },
    getPopulation2026()
    {
      var item = "";
      item = this.numberWithCommas(this.calculateSummaryValue("population", "2026"));

      console.log ("getPopulation2023", item);

      return item;
    },
    getPopulation2027()
    {
      var item = "";
      item = this.numberWithCommas(this.calculateSummaryValue("population", "2027"));

      console.log ("getPopulation2023", item);

      return item;
    },
    summaryTotalQuantityFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateTotalQuantity(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("cost_per_staff")
          ].workforce_quantity
        );
      }
      return null;
    },
    // Summary Overview
    // Controls the information on the boxes within the Stage
    specialityOverviewFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_overview")
        ].summary_overview;
      }
      return null;
    },
    specialityName(specialityName) {
      this.activeSpeciality = specialityName;
    },
    specialityYear(specialityYear) {
      this.activeYear = specialityYear;
    },
    editImprovementType(specialityTypeName) {
      console.log ("editImprovement: ", specialityTypeName);
      if (specialityTypeName == "Diagnostics") {
        this.improvementType = "diagnostics";
      }
      if (specialityTypeName == "Outpatient Clinics") {
        this.improvementType = "OPD";
      }
      if (specialityTypeName == "OR") {
        this.improvementType = "OR";
      }
      if (specialityTypeName == "Emergency Room (ER)") {
        this.improvementType = "ER";
      }

      if (specialityTypeName == "Bed Flow") {
        this.improvementType = "bedflow";
      }

      console.log(
        "editImprovementType (",
        specialityTypeName,
        ")=",
        this.improvementType
      );
    },
    editStageSummary(stageSummary) {
      this.stageSummary = stageSummary;

      if (this.stageSummary == "stage") {
        console.log ("SummaryMain3.vue: Stage");
        this.fetchDataSummaries();
        this.retrieveTileInformation ();
        console.log ("tileInformation: ", this.tileInformation);
        this.apiPayload.apiString = "grandsummarytwo-collection";

        //console.log("stage: getData");

        this.getMongoDBData();
        this.fillData("workforceCost");
      }

      if (this.stageSummary == "buildingsFM") {
        this.apiPayload.apiString = "fm_build3_collection";
        this.getMongoDBData();
      }

      if (this.stageSummary == "specialities") {
        console.log(
          " this.selectedSpecialityArea.selected",
          this.selectedSpecialityArea
        );

        //this.editSpeciality
        this.editSpecialityType(this.selectedSpecialityArea);
        this.apiPayload.apiString = this.specialityApi;
        this.getMongoDBData();
      }

      if (this.stageSummary == "opImprov") {
        console.log(
          " this.improvementSpecialityAra.selected",
          this.improvementSpecialityArea
        );

        this.editImprovementType(this.improvementSpecialityArea);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      }
    },
  },
};
</script>
<style>
.chartcontainer3 {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 450px;
  display: inline-block;
  width: 600px; /* was 26 rem */
  margin-right: 20px;
}
.title_text3 {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.chart_view3 {
  width: 600px;
  height: 400px;
  /*border: solid #5b6dcd 10px;*/
  box-sizing: content-box;
}
</style>

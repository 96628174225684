import axios from "../../plugins/axios";

export default {
  state: {
    namespaced: true,
    test: "Surrey",
    specialityName: "",
    locationName: "",
    contingencies: [
      { label: "Major Incident Plan", string: "mip"},
      { label: "Disaster Medical Advisory Teams (DMAT) SOPs", string: "DMAT"},
      { label: "Incident Assessment Team SOPs", string: "IAT"},
      { label: "Trigger Point SOPs", string: "TPS"},
      { label: "Pandemic Extreme Scenario SOPs", string: "PES"},
      { label: "Mass Fatality SOPs", string: "MFS"},
      { label: "System Resilience SOPs", string: "SRS"},
      { label: "System Advanced Logistics Planning SOPs", string: "SALP"},
    ],
    hospitals: [
      { speciality: "BDF Hospital", string: "BDFHospital" },
      { speciality: "KHUH", string: "KHUH" },
      { speciality: "MKCC", string: "MKCC"},
      { speciality: "BOC", string : "BOC"},
      { speciality: "Consolidated Group", string: "Consolidated"},
    ],
    dataTypes: [
      { label: "Patient Episodes", string: "patientEpisodes" },
      { label: "Space & Property", string: "space" },
      { label: "Finance", string: "finance" },
      { label: "Workforce", string: "workforce" },
    ],
    dataTypes1: [
      { label: "Patient Episodes", string: "patientEpisodes" },
      { label: "Space & Property", string: "space" },
      { label: "Finance", string: "finance" },
      { label: "Workforce", string: "workforce" },
      { label: "Tactical Assumptions", string: "tactical"}
    ],
    specialities: [
      { speciality: "AHPs", apiString: "ahpsone-collection" },
      {
        speciality: "Cardiac Surgery",
        apiString: "cardiacsurgeryone-collection",
      },
      { speciality: "Cardiology", apiString: "cardiologyone-collection" },
      { speciality: "Dermatology", apiString: "dermatologyone-collection" },
      { speciality: "Diabetes", apiString: "diabetesone-collection" },
      { speciality: "Endocrinology", apiString: "endocrinologyone-collection" },
      { speciality: "ENT", apiString: "entone-collection" },
      { speciality: "ER & Acute", apiString: "eracuteone-collection" },
      {
        speciality: "Gastroenterology",
        apiString: "gastroenterologyone-collection",
      },
      {
        speciality: "General Surgery",
        apiString: "generalsurgeryone-collection",
      },
      { speciality: "Gynaecology", apiString: "gynaecologyone-collection" },
      { speciality: "Haematology", apiString: "haematologyone-collection" },
      { speciality: "Home Care", apiString: "homecareone-collection" },
      {
        speciality: "Infectious Diseases",
        apiString: "infectiousone-collection",
      },
      {
        speciality: "Kidney Services",
        apiString: "kidneyservicesone-collection",
      },
      { speciality: "Labs", apiString: "labsone-collection" },
      { speciality: "Long Term Care", apiString: "longtermone-collection" },
      { speciality: "MaxilloFacial", apiString: "maxfaxone-collection" },
      { speciality: "Medical Oncology", apiString: "oncologyone-collection" },
      { speciality: "Mental Health", apiString: "mentalhealthone-collection" },
      { speciality: "Neurology", apiString: "neurologyone-collection" },
      { speciality: "Neurosurgery", apiString: "neurosurgeryone-collection" },
      { speciality: "Obstetrics", apiString: "obstetricsone-collection" },
      { speciality: "Ophthalmology", apiString: "opthalmologyone-collection" },
      {
        speciality: "Orthopaedics & Trauma",
        apiString: "orthopaedicsone-collection",
      },
      { speciality: "Paediatrics", apiString: "paediatricsone-collection" },
      {
        speciality: "Plastic Surgery",
        apiString: "plasticsurgeryone-collection",
      },
      { speciality: "Primary Care", apiString: "primarycareone-collection" },
      { speciality: "Radiology", apiString: "radiologyone-collection" },
      { speciality: "Respiratory", apiString: "respiratoryone-collection" },
      { speciality: "Rheumatology", apiString: "rheumatologyone-collection" },
      {
        speciality: "Transplant Services",
        apiString: "transplantservicesone-collection",
      },
      { speciality: "Urology", apiString: "urologyone-collection" },
      { speciality: "Vascular", apiString: "vascularone-collection" },
    ],
    specialities2: [
      { speciality: "AHPs", apiString: "ahpstwo-collection" },
      {
        speciality: "Cardiac Surgery",
        apiString: "cardiacsurgerytwo-collection",
      },
      { speciality: "Cardiology", apiString: "cardiologytwo-collection" },
      { speciality: "Dermatology", apiString: "dermatologytwo-collection" },
      { speciality: "Diabetes", apiString: "diabetestwo-collection" },
      { speciality: "Endocrinology", apiString: "endocrinologytwo-collection" },
      { speciality: "ENT", apiString: "enttwo-collection" },
      { speciality: "ER & Acute", apiString: "eracutetwo-collection" },
      {
        speciality: "Gastroenterology",
        apiString: "gastroenterologytwo-collection",
      },
      {
        speciality: "General Surgery",
        apiString: "generalsurgerytwo-collection",
      },
      { speciality: "Gynaecology", apiString: "gynaecologytwo-collection" },
      { speciality: "Haematology", apiString: "haematologytwo-collection" },
      { speciality: "Home Care", apiString: "homecaretwo-collection" },
      {
        speciality: "Infectious Diseases",
        apiString: "infectioustwo-collection",
      },
      {
        speciality: "Kidney Services",
        apiString: "kidneyservicestwo-collection",
      },
      { speciality: "Labs", apiString: "labstwo-collection" },
      { speciality: "Long Term Care", apiString: "longtermtwo-collection" },
      { speciality: "MaxilloFacial", apiString: "maxfaxtwo-collection" },
      { speciality: "Medical Oncology", apiString: "oncologytwo-collection" },
      { speciality: "Mental Health", apiString: "mentalhealthtwo-collection" },
      { speciality: "Neurology", apiString: "neurologytwo-collection" },
      { speciality: "Neurosurgery", apiString: "neurosurgerytwo-collection" },
      { speciality: "Obstetrics", apiString: "obstetricstwo-collection" },
      { speciality: "Ophthalmology", apiString: "opthalmologytwo-collection" },
      {
        speciality: "Orthopaedics & Trauma",
        apiString: "orthopaedicstwo-collection",
      },
      { speciality: "Paediatrics", apiString: "paediatricstwo-collection" },
      {
        speciality: "Plastic Surgery",
        apiString: "plasticsurgerytwo-collection",
      },
      { speciality: "Primary Care", apiString: "primarycaretwo-collection" },
      { speciality: "Radiology", apiString: "radiologytwo-collection" },
      { speciality: "Respiratory", apiString: "respiratorytwo-collection" },
      { speciality: "Rheumatology", apiString: "rheumatologytwo-collection" },
      {
        speciality: "Transplant Services",
        apiString: "transplantservicestwo-collection",
      },
      { speciality: "Urology", apiString: "urologytwo-collection" },
      { speciality: "Vascular", apiString: "vasculartwo-collection" },
    ],
    clinical: [
      { label: "Primary", string: "primary" },
      { label: "Acute", string: "acute" },
      { label: "Tertiary", string: "tertiary" },
      { label: "Mental Health", string: "mental" },
      { label: "Virtual and Telemedicine", string: "virt" },
      { label: "Long-Term Care", string: "long" },
    ],
    dataTypes2: [
      { label: "Diagnostics", string: "diagnostics" },
      { label: "Outpatient Clinics", string: "OPD" },
      { label: "OR", string: "OR" },
      { label: "Emergency Room (ER)", string: "ER" },
      { label: "Bed Flow", string: "bedflow"}
    ],
    specialityData: [],
    stageTwo: [],
    stageThree: [],
    stageThreeYearOne: [],
    stageThreeYearTwo: [],
    stageThreeYearThree: [],
    stageThreeYearFour: [],
    stageThreeYearFive: [],
    annualTotalsDataOne: [],
    annualTotalsDataTwo: [],
    annualTotalsData: [],
    years: [
      { year: "2023" },
      { year: "2024" },
      { year: "2025" },
      { year: "2026" },
      { year: "2027" },
    ],
  },
  getters: {
    GET_SPECIALITY_DATA(state) {
      return state.test;
    },
  },
  mutations: {
    SAVE_SPECIALITY_NAME(state, specialityName) {
      state.specialityName = specialityName;
    },
    SAVE_SPECIALITY_DATA(state, specialityData) {
      state.specialityData = specialityData;
    },
    SAVE_SPECIALITY_STAGE_TWO(state, specialityData) {
      state.stageTwo = specialityData;
    },
    SAVE_SPECIALITY_STAGE_THREE(state, specialityData) {
      state.stageThree = specialityData;
    },
    SAVE_SPECIALITY_STAGE_THREE_YEAR_ONE(state, specialityData) {
      state.stageThreeYearOne = specialityData;
    },
    SAVE_SPECIALITY_STAGE_THREE_YEAR_TWO(state, specialityData) {
      state.stageThreeYearTwo = specialityData;
    },
    SAVE_SPECIALITY_STAGE_THREE_YEAR_THREE(state, specialityData) {
      state.stageThreeYearThree = specialityData;
    },
    SAVE_SPECIALITY_STAGE_THREE_YEAR_FOUR(state, specialityData) {
      state.stageThreeYearFour = specialityData;
    },
    SAVE_SPECIALITY_STAGE_THREE_YEAR_FIVE(state, specialityData) {
      state.stageThreeYearFive = specialityData;
    },
    SAVE_ANNUALTOTALS_DATA_ONE(state, annualTotalsDataOne) {
      state.annualTotalsDataOne = annualTotalsDataOne;
    },
    SAVE_ANNUALTOTALS_DATA_TWO(state, annualTotalsDataTwo) {
      state.annualTotalsDataTwo = annualTotalsDataTwo;
    },
  },
  actions: {
     attemptFetchSpecialityData({ commit }, speciality) {
                //console.log ("-------");
                //console.log ("actions: prototypedata.js: attemptFetchSpecialityData");
                //console.log ("speciality.speciality: ",speciality.speciality);
                //console.log ("speciality.apiString: ",speciality.apiString);
                //console.log ("-------");
                return axios
                .get(speciality.apiString)
                .then(specialityData => {
                    commit('SAVE_SPECIALITY_NAME', speciality.speciality)
                    commit('SAVE_SPECIALITY_DATA', specialityData)
                    //console.log("specialityData",specialityData);
                    return specialityData
                })
                .catch(res => {
                    //console.log ("catch data");
                    //console.log(res.response.data)
                    throw res.response.data
                })
        },
    GetSpecialityDataByYear({ commit }, apiPayload) {
      var apiString = "";
      var newSpeciality = "";

      console.log("GetSpecialityDataByYear ---------");
      console.log("--Speciality", apiPayload.speciality);
      console.log("--Year", apiPayload.year);
      console.log("---------------------------------");

      console.log("apiPay speciality", apiPayload.speciality.toLowerCase());

      newSpeciality = apiPayload.speciality.toLowerCase();

      if (apiPayload.speciality.toLowerCase() == "diabetes") {
        newSpeciality = "diabetesmetbariatric";
      }

      if (apiPayload.speciality.toLowerCase() == "cardiac surgery") {
        newSpeciality = "cardiac_surgery";
      }

      if (apiPayload.speciality.toLowerCase() == "er & acute") {
        newSpeciality = "er_and_acute";
      }

      if (apiPayload.speciality.toLowerCase() == "general surgery") {
        newSpeciality = "general_surgery";
      }

      if (apiPayload.speciality.toLowerCase() == "home care") {
        newSpeciality = "home_care";
      }

      if (apiPayload.speciality.toLowerCase() == "infectious diseases") {
        newSpeciality = "infectious_diseases";
      }

      if (apiPayload.speciality.toLowerCase() == "kidney services") {
        newSpeciality = "kidney_services";
      }

      if (apiPayload.speciality.toLowerCase() == "long term care") {
        newSpeciality = "long_term_care";
      }

      // Mental Health

      if (apiPayload.speciality.toLowerCase() == "mental health") {
        newSpeciality = "mental_health";
      }

      if (apiPayload.speciality.toLowerCase() == "medical oncology") {
        newSpeciality = "oncology";
      }

      // Orthopaedics & Trauma

      if (apiPayload.speciality.toLowerCase() == "orthopaedics & trauma") {
        newSpeciality = "orthopaedics";
      }

      if (apiPayload.speciality.toLowerCase() == "primary care") {
        newSpeciality = "primary_care";
      }

      if (apiPayload.speciality.toLowerCase() == "plastic surgery") {
        newSpeciality = "plastic_surgery";
      }

      if (apiPayload.speciality.toLowerCase() == "transplant services") {
        newSpeciality = "transplant_services";
      }

      console.log("new speciality = ", newSpeciality);

      apiString =
        newSpeciality + "_surrey_stage_3_" + apiPayload.year + "_collection";

      apiString = apiString.toLowerCase();

      console.log("apiString = ", apiString);

      console.log(
        "GetSpecialityDataByYear",
        apiPayload.year,
        apiPayload.speciality
      );

      return axios
        .get(apiString)
        .then((specialityData) => {
          commit("SAVE_SPECIALITY_DATA", specialityData);
          //console.log("specialityData",specialityData);
          return specialityData;
        })
        .catch((res) => {
          //console.log ("catch data");
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    GetData({ commit }, apiPayload) {
      console.log("GetData", apiPayload.apiString, apiPayload.speciality);

      return axios
        .get(apiPayload.apiString)
        .then((specialityData) => {
          commit("SAVE_SPECIALITY_DATA", specialityData);
          console.log("GetData", specialityData);
          return specialityData;
        })
        .catch((res) => {
          //console.log ("catch data");
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    fetchSpecialityData({ commit }, speciality) {
      console.log("-------");
      console.log("actions: prototypedata.js: fetchSpecialityData");
      console.log("speciality.speciality: ", speciality.speciality);
      console.log("speciality.apiString: ", speciality.apiString);
      console.log("-------");
      return axios
        .get(speciality.apiString)
        .then((specialityData) => {
          commit("SAVE_SPECIALITY_NAME", speciality.speciality);
          commit("SAVE_SPECIALITY_DATA", speciality);
          console.log("specialityData", specialityData);
          return specialityData;
        })
        .catch((res) => {
          //console.log ("catch data");
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    attemptFetchStageTwo({ commit }, speciality2) {
      return axios
        .get(speciality2.apiString)
        .then((stageTwo) => {
          commit("SAVE_SPECIALITY_NAME", speciality2.speciality);
          commit("SAVE_SPECIALITY_STAGE_TWO", stageTwo);
          //console.log(stageTwo)
          return stageTwo;
        })
        .catch((res) => {
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    attemptFetchStageThree({ commit }, speciality) {
      return axios
        .get(speciality.apiString)
        .then((stageThree) => {
          commit("SAVE_SPECIALITY_NAME", speciality.speciality);
          commit("SAVE_SPECIALITY_STAGE_THREE", stageThree);
          return stageThree;
        })
        .catch((res) => {
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    getStageThreeSummaries({ commit }) {
      console.log("prototypedata: getStageThreeSummaries");

      axios
        .get("grandsummarythreeyearone-collection")
        .then((stageThreeYearOne) => {
          commit("SAVE_SPECIALITY_STAGE_THREE_YEAR_ONE", stageThreeYearOne);
          console.log("stageThreeYearOne", stageThreeYearOne);
          return stageThreeYearOne;
        })
        .catch((res) => {
          throw res.response.data;
        });

      axios
        .get("grandsummarythreeyeartwo-collection")
        .then((stageThreeYearTwo) => {
          commit("SAVE_SPECIALITY_STAGE_THREE_YEAR_TWO", stageThreeYearTwo);
          console.log("stageThreeYearTwo", stageThreeYearTwo);
          return stageThreeYearTwo;
        })
        .catch((res) => {
          throw res.response.data;
        });

      axios
        .get("grandsummarythreeyearthree-collection")
        .then((stageThreeYearThree) => {
          commit("SAVE_SPECIALITY_STAGE_THREE_YEAR_THREE", stageThreeYearThree);
          console.log("stageThreeYearThree", stageThreeYearThree);
          return stageThreeYearThree;
        })
        .catch((res) => {
          throw res.response.data;
        });

      axios
        .get("grandsummarythreeyearfour-collection")
        .then((stageThreeYearFour) => {
          commit("SAVE_SPECIALITY_STAGE_THREE_YEAR_FOUR", stageThreeYearFour);
          console.log("stageThreeYearFour", stageThreeYearFour);
          return stageThreeYearFour;
        })
        .catch((res) => {
          throw res.response.data;
        });

      axios
        .get("grandsummarythreeyearfive-collection")
        .then((stageThreeYearFive) => {
          commit("SAVE_SPECIALITY_STAGE_THREE_YEAR_FIVE", stageThreeYearFive);
          console.log("stageThreeYearFive", stageThreeYearFive);
          return stageThreeYearFive;
        })
        .catch((res) => {
          throw res.response.data;
        });
    },
    attemptFetchAnnualTotalsOne({ commit }) {
      return axios
        .get("annualtotals-collection")
        .then((annualTotalsDataOne) => {
          commit("SAVE_ANNUALTOTALS_DATA_ONE", annualTotalsDataOne);
          //console.log(annualTotalsDataOne)
          return annualTotalsDataOne;
        })
        .catch((res) => {
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    attemptFetchAnnualTotalsTwo({ commit }) {
      return axios
        .get("annualtotals-collection")
        .then((annualTotalsDataTwo) => {
          commit("SAVE_ANNUALTOTALS_DATA_TWO", annualTotalsDataTwo);
          //console.log(annualTotalsDataTwo)
          return annualTotalsDataTwo;
        })
        .catch((res) => {
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    updateLiveData(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/updateLiveData", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    },
    attemptPostBlog(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/blogs", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    },
  },
};

import Shell from '../../../layouts/Shell.vue'
import BuildFM1Main from '../views/BuildFM1.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/buildFM1',
            name: 'BuildFM1Main',
            component: BuildFM1Main,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes

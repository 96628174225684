<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">
          Beneficiary Population Health Benchmark
        </h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Surrey - United Kingdom</span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Total Population</span>
          <span class="box-label"> {{  this.population }} </span>
        </div>
      </div>
    </div>

    <nav class="route-nav">
      <ul>
        <li
          @click="editStageSummary('stage')"
          :class="stageSummary == 'stage' ? 'active' : ''"
        >
          <span>Stage 2</span>
        </li>
        <li
          @click="editStageSummary('specialities')"
          :class="stageSummary == 'specialities' ? 'active' : ''"
        >
          <span>Specialities</span>
        </li>
        <li
          @click="editStageSummary('buildingsFM')"
          :class="stageSummary == 'buildingsFM' ? 'active' : ''"
        >
          <span>Buildings and FM</span>
        </li>
        <li
          @click="editStageSummary('opImprov')"
          :class="stageSummary == 'opImprov' ? 'active' : ''"
        >
          <span>Operational Improvement</span>
        </li>   
      </ul>
    </nav>

    <div v-if="stageSummary == 'stage'">
      <div class="route-content">
        <div class="stat-cards" v-if="specialityData !== null">
          <div
            class="stat-cards--item single"
            :class="activeSummary === 'patientEpisode' ? 'active' : ''"
            @click="fillData('patientEpisode')"
          >
            <span class="card-header">Patient Episode Productivity</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-patient.svg"
                alt="Patient Episodes"
              />
              <!-- {{ patientEpisodeProductivityFilter | numeral }} -->
              <span class="card-total">{{ getTileData(0) }}</span>
            </div>
            <span class="card-info">Total patient episodes</span>
          </div>

          <div
            class="stat-cards--item single"
            :class="activeSummary === 'assets' ? 'active' : ''"
            @click="fillData('assets')"
          >
            <span class="card-header">Assets</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <span class="card-total">{{ getTileData(1) }}</span>
            </div>
            <span class="card-info">Total Assets</span>
          </div>

          <div
            class="stat-cards--item single"
            :class="activeSummary === 'workforceCost' ? 'active' : ''"
            @click="fillData('workforceCost')"
          >
            <span class="card-header">Workforce</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <span class="card-total">{{ getTileData(2) }}</span>
            </div>
            <span class="card-info"
              >Total Workforce Full Time Equivalent (FTE)</span
            >
          </div>

          <div
            class="stat-cards--item single"
            :class="activeSummary === 'finance' ? 'active' : ''"
            @click="fillData('finance')"
          >
            <span class="card-header">Finance</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <span class="card-total">{{ getTileData(3) }}</span>
            </div>
            <span class="card-info">Budget Requirement</span>
          </div>

          <!-- line 2 -->

          <div class="stat-cards--item single no-hover">
            <span class="card-header"
              >Patient Episode Productivity (Stage 1)</span
            >
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-patient.svg"
                alt="SupplyChain"
              />
              <span class="card-total">{{ getTileData(4) }}</span>
            </div>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Assets (Stage 1)</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <span class="card-total">{{ getTileData(5) }}</span>
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Workforce (Stage 1)</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <span class="card-total">{{ getTileData(6) }}</span>
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Finance (Stage 1)</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <span class="card-total"> {{ getTileData(7) }}</span>
            </div>
            <span class="card-info"></span>
          </div>

          <!-- end of line 2 -->

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Current Episodes</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-patient.svg"
                alt="SupplyChain"
              />
              <span class="card-total"> {{ getTileData(8) }}</span>
            </div>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Current Assets</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <span class="card-total"> {{ getTileData(9) }}</span>
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Current Workforce</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <span class="card-total">{{ getTileData(10) }}</span>
            </div>
            <span class="card-info"></span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Current Budget</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <span class="card-total">{{ getTileData(11) }}</span>
            </div>
            <span class="card-info"></span>
          </div>
        </div>

        <div v-if="activeSummary == 'workforceCost'">
          <h1 class="text-3xl my-6 active">Workforce Summary</h1>

          <div class="chartcontainer">
            <div class="title_text">WORKFORCE</div>
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary" v-if="specialityData !== null">
            <thead>
              <tr>
                <th class="border px-4 py-2">Workforce</th>
                <th class="border px-4 py-2">Quantity</th>
                <th class="border px-4 py-2">Staff Cost</th>
                <th class="border px-4 py-2">Total Workforce Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div
                    v-for="(workforce, index) in workforceSummaryTableWorkforceFilter"
                    :key="index"
                  >
                    {{ workforce }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(quantity, index) in workforceSummaryTableQuantityFilter"
                    :key="index"
                  >
                    {{ quantity | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(costPerStaff, index) in workforceSummaryTableQuantityFilter"
                    :key="index"
                  >
                    {{ costPerStaff | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      totalWorkforceCost, index
                    ) in workforceSummaryTableCostPerStaffFilter"
                    :key="index"
                  >
                    {{ totalWorkforceCost | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="activeSummary == 'assets'">
          <!-- start -->
          <h1 class="text-3xl my-6 active">Assets Summary</h1>

          <div class="chartcontainer">
            <div class="title_text">ASSETS</div>
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary" v-if="specialityData !== null">
            <thead>
              <tr>
                <th class="border px-4 py-2">Assets</th>
                <th class="border px-4 py-2">Quantity</th>
                <th class="border px-4 py-2">Capital Cost Per Item</th>
                <th class="border px-4 py-2">Total Capital Cost</th>
                <th class="border px-4 py-2">Life Cycle</th>
                <th class="border px-4 py-2">Annual Cost</th>
                <th class="border px-4 py-2">Annual Maintenance Cost</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="border">
                  <div v-for="(assets, index) in assetsFilter1" :key="index">
                    {{ assets }}
                  </div>
                </td>
                <td class="border">
                  <div
                    v-for="(assetsQuantity, index) in assetsQuantityFilter1"
                    :key="index"
                  >
                    {{ assetsQuantity | numeral }}
                  </div>
                </td>
                <td class="border">
                  <div
                    v-for="(
                      capitalCostPerItem, index
                    ) in assetsCapitalCostPerItemFilter1"
                    :key="index"
                  >
                    {{ capitalCostPerItem | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      totalCapitalCost, index
                    ) in assetsTotalCapitalCostFilter1"
                    :key="index"
                  >
                    {{ totalCapitalCost | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(lifeCycle, index) in assetsLifeCycleFilter1"
                    :key="index"
                  >
                    {{ lifeCycle }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      annualisedCost, index
                    ) in assetsAnnualisedCostFilter1"
                    :key="index"
                  >
                    {{ annualisedCost | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      annualisedMaintenanceCost, index
                    ) in assetsAnnualisedMaintenanceCostFilter1"
                    :key="index"
                  >
                    {{ annualisedMaintenanceCost | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- finish -->

        <div v-if="activeSummary == 'patientEpisode'">
          <h1 class="text-3xl my-6 active">Patient Episodes</h1>
          <!-- start -->
          <div class="chartcontainer">
            <div class="title_text">PATIENT EPISODES</div>
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary" v-if="specialityData !== null">
            <thead>
              <tr>
                <th class="border px-4 py-2">Patient Episodes</th>
                <th class="border px-4 py-2">Patient Episodes Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div
                    v-for="(
                      patientEpisodes, index
                    ) in specialityPatientEpisodesFilter"
                    :key="index"
                  >
                    {{ patientEpisodes }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      patientEpisodesQuantity, index
                    ) in specialityPatientEpisodesTotalFilter"
                    :key="index"
                  >
                    {{ patientEpisodesQuantity | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /div -->

        <div v-if="activeSummary == 'finance'">
          <h1 class="text-3xl my-6 active">Finance</h1>

          <!-- start -->

          <div class="chartcontainer">
            <div class="title_text">FINANCE</div>
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary" v-if="specialityData !== null">
            <thead>
              <tr>
                <th class="border px-4 py-2">Finance</th>
                <th class="border px-4 py-2">Finance Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div
                    v-for="(
                      patientEpisodes, index
                    ) in specialityFinanceNameFilter"
                    :key="index"
                  >
                    {{ patientEpisodes }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(financeName, index) in specialityFinanceTotalFilter"
                    :key="index"
                  >
                    {{ financeName | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- finish -->
      </div>
    </div>

    <div v-if="stageSummary == 'opImprov'">
      <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">
          

          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Improvement Area</span>
              <span class="box-label">
                <v-select
                  v-model="improvementSpecialityArea"
                  label="label"
                  :clearable="false"
                  :options="$store.state.prototypedata.dataTypes2"
                />
              </span>
            </div>
          </div>
        </div>

        <!-- now check to see if any of the specialities areas have changed -->
        <div v-if="improvementType == 'diagnostics'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 2</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Diagnostic Planning Process - Weekly </td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Diagnostic Planning Process - Weekly <div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Diagnostic Daily Management and Coordination Plan</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Diagnostic Daily Management and Coordination Plan<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Coach Diagnostics Daily Management and Coordination plan</td>
                    <td class="border" style="padding: 10px;">10</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Coach Diagnostics Daily Management and Coordination plan<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Daily Coordination and Management Demonstration, Imitation and Practice</td>
                    <td class="border" style="padding: 10px;">10</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Coordination and Management Demonstration, Imitation and Practice<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Link Target Operating Model by Specialty into Diagnostics Planning Schedules</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Link Target Operating Model by Specialty into Diagnostics Planning Schedules<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Daily & Weekly Target Capacity Planning</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Daily & Weekly Target Capacity Planning<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Diagnostics List Planning Elective, Trauma, Non-Elective</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Diagnostics List Planning Elective, Trauma, Non-Elective<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Workforce Target Operating Model for Diagnostics by Modality</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Workforce Target Operating Model for Diagnostics by Modality<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Workforce Demand</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Workforce Demand<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Asset Target Operating Model for Diagnostics as above.</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Asset Target Operating Model for Diagnostics as above<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Assets Utilization Optimization Requirement (1) linked to the Diagnostics Target Operating Model</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Assets Utilization Optimization Requirement (1) linked to the Diagnostics Target Operating Model<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>

           <!-- now check to see if any of the specialities areas have changed -->
           <div v-if="improvementType == 'OPD'">
            <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 2</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish OPD Planning Process - Weekly </td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish OPD Planning Process - Weekly <div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish OPD Daily Management and Coordination Plan</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish OPD Daily Management and Coordination Plan<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish and Coach OPD Daily Management and Coordination plan</td>
                    <td class="border" style="padding: 10px;">10</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish and Coach OPD Daily Management and Coordination plan<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Daily Coordination and Management Demonstration, Imitation and Practice</td>
                    <td class="border" style="padding: 10px;">10</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Coordination and Management Demonstration, Imitation and Practice<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Link Target Operating Model by Specialty into OR Planning Schedules</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Link Target Operating Model by Specialty into OR Planning Schedules<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Daily & Weekly Target Capacity Planning</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Daily & Weekly Target Capacity Planning<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">OPD List Planning Day-Case, Elective, Trauma, Non-Elective</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">OPD List Planning Day-Case, Elective, Trauma, Non-Elective<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Workforce Target Operating Model for OPD</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Workforce Target Operating Model for OPD<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Workforce Demand</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Workforce Demand<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Asset Target Operating Model for OPD, as above.</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Asset Target Operating Model for OPD<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Assets Utilization Optimization Requirement (1) linked to the OPD Target Operating Model</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Assets Utilization Optimization Requirement (1) linked to the OPD Target Operating Model<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>


        <div v-if="improvementType == 'OR'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 2</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish OR Planning Process - Weekly</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish OR Planning Process - Weekly<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish OR Daily Management and Coordination Plan</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish OR Daily Management and Coordination Plan<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish and Coach OR Daily Management and Coordination plan</td>
                    <td class="border" style="padding: 10px;">10</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish and Coach OR Daily Management and Coordination plan<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                   <tr>
                    <td class="border" style="padding: 10px;">Daily Coordination and Management Demonstration, Imitation and Practice</td>
                    <td class="border" style="padding: 10px;">10</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Coordination and Management Demonstration, Imitation and Practice<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Link Target Operating Model by Specialty into OR Planning Schedules</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com"><div style="height:100%;width:100%">Link Target Operating Model by Specialty into OR Planning Schedules</div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Daily & Weekly Target Capacity Planning</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Daily & Weekly Target Capacity Planning<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">OR List Planning Day-Case, Elective, Trauma, Non-Elective</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">OR List Planning Day-Case, Elective, Trauma, Non-Elective<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Workforce Target Operating Model for OR, covering DC, IP and CPOD</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Workforce Target Operating Model for OR, covering DC, IP and CPOD<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Workforce Demand</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Workforce Demand<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Asset Target Operating Model for OR, as above</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Asset Target Operating Model for OR, as above<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Assets Utilization Optimization Requirement (1) linked to the OR Target Operating Model</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Assets Utilization Optimization Requirement (1) linked to the OR Target Operating Model<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>

        <div v-if="improvementType == 'ER'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 2</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish ER Planning Process - Weekly</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish ER Planning Process - Weekly<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish ER Daily Management and Coordination Plan</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish ER Daily Management and Coordination Plan<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Coach ER Daily Management and Coordination plan</td>
                    <td class="border" style="padding: 10px;">10</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Coach ER Daily Management and Coordination plan<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                   <tr>
                    <td class="border" style="padding: 10px;">Daily Coordination and Management Demonstration, Imitation and Practice</td>
                    <td class="border" style="padding: 10px;">10</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Coordination and Management Demonstration, Imitation and Practice<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Link Target Operating Model by Specialty into ER Planning Schedules</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com"><div style="height:100%;width:100%">Link Target Operating Model by Specialty into ER Planning Schedules</div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Daily & Weekly Target Capacity Planning</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Daily & Weekly Target Capacity Planning<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Identify key support specialties and daily cooperation timings (Cardiology, Gastroenterology, Respiratory, Elderly Care)</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Identify key support specialties and daily cooperation timings (Cardiology, Gastroenterology, Respiratory, Elderly Care)<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Workforce Target Operating Model for ER, covering Acute Medicine Beds</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Workforce Target Operating Model for ER, covering Acute Medicine Beds<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Workforce Demand</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Workforce Demand<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Asset Target Operating Model for ER and Acute Medicine, as above</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Asset Target Operating Model for ER and Acute Medicine<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Assets Utilization Optimization Requirement (1) linked to the ER Target Operating Model</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Assets Utilization Optimization Requirement (1) linked to the OR Target Operating Model<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>                  
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>

        <div v-if="improvementType == 'bedflow'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 2</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Bed Planning Process - Weekly </td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Bed Planning Process - Weekly<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Bed Daily Management and Coordination Plan</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Bed Daily Management and Coordination Plan<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr> <tr>
                    <td class="border" style="padding: 10px;">Coach Bed Daily Management and Coordination plan</td>
                    <td class="border" style="padding: 10px;">10</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Coach Bed Daily Management and Coordination plan<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr> <tr>
                    <td class="border" style="padding: 10px;">Daily Coordination and Management Demonstration, Imitation and Practice</td>
                    <td class="border" style="padding: 10px;">10</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Coordination and Management Demonstration, Imitation and Practice<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr> <tr>
                    <td class="border" style="padding: 10px;">Establish TOM for ALOS for Elective and Non-Elective Beds</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish TOM for ALOS for Elective and Non-Elective Beds<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr> <tr>
                    <td class="border" style="padding: 10px;">Establish pre-admission criteria for Non-Elective per specialty</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish pre-admission criteria for Non-Elective per specialty<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr> <tr>
                    <td class="border" style="padding: 10px;">Confirm Target Operating Occupancy at 85%</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Target Operating Occupancy at 85%<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr> <tr>
                    <td class="border" style="padding: 10px;">Confirm Discharge Routines per specialty</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Discharge Routines per specialty<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr> <tr>
                    <td class="border" style="padding: 10px;">Confirm TTO and Transport routines per specialty</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm TTO and Transport routines per specialty<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr> <tr>
                    <td class="border" style="padding: 10px;">Confirm detailed discharge plan per patient type</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm detailed discharge plan per patient type<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                   <tr>
                    <td class="border" style="padding: 10px;">Coach and train all ward medical and nusring staff in detailed discharge planning</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Coach and train all ward medical and nusring staff in detailed discharge planning<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                   <tr>
                    <td class="border" style="padding: 10px;">Confirm Elective ALOS per case type</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com"><div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
      
                </tbody>
              </table>
            <!-- /div -->
          </div>  
        </div>
      </div>
    </div>

    <div v-if="stageSummary == 'specialities'">
      <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">
          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Speciality</span>
              <span class="box-label">
                <v-select
                  v-model="selectedSpeciality"
                  label="speciality"
                  :clearable="false"
                  @input="fetchData"
                  :options="$store.state.prototypedata.specialities2"
                />
              </span>
            </div>
          </div>

          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Speciality Area</span>
              <span class="box-label">
                <v-select
                  v-model="selectedSpecialityArea"
                  label="label"
                  :clearable="false"
                  :options="$store.state.prototypedata.dataTypes"
                />
              </span>
            </div>
          </div>
        </div>

        <!-- now check to see if any of the specialities areas have changed -->
        <div v-if="specialityType == 'patientEpisodes'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Patient Episodes</th>
                    <th class="border px-4 py-2">Patient Episodes Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodes, index
                        ) in specialityPatientEpisodesFilter"
                        :key="index"
                      >
                        {{ patientEpisodes }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodesQuantity, index
                        ) in specialityPatientEpisodesTotalFilter"
                        :key="index"
                      >
                        {{ patientEpisodesQuantity | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'finance'">
          <div class="route-content">
            <div v-if="specialityData !== null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Finance</th>
                    <th class="border px-4 py-2">Finance Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodes, index
                        ) in specialityFinanceNameFilter"
                        :key="index"
                      >
                        {{ patientEpisodes }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          financeName, index
                        ) in specialityFinanceTotalFilter"
                        :key="index"
                      >
                        {{ financeName | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'space'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary" v-if="specialityData !== null">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Space & Property</th>
                    <th class="border px-4 py-2">Quantity</th>
                    <th class="border px-4 py-2">Capital Cost Per Item</th>
                    <th class="border px-4 py-2">Total Capital Cost</th>
                    <th class="border px-4 py-2">Life Cycle</th>
                    <th class="border px-4 py-2">Annualised Cost</th>
                    <th class="border px-4 py-2">
                      Annualised Maintenance Cost
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          spaceAndProperty, index
                        ) in specialitySpaceAndPropertyFilter"
                        :key="index"
                      >
                        {{ spaceAndProperty }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          spaceAndPropertyQuantity, index
                        ) in specialitySpaceAndPropertyQuantityFilter"
                        :key="index"
                      >
                        {{ spaceAndPropertyQuantity | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          capitalCostPerItem, index
                        ) in specialityCapitalCostPerItemFilter"
                        :key="index"
                      >
                        {{ capitalCostPerItem | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          totalCapitalCost, index
                        ) in specialityTotalCapitalCostFilter"
                        :key="index"
                      >
                        {{ totalCapitalCost | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(lifeCycle, index) in specialityLifeCycleFilter"
                        :key="index"
                      >
                        {{ lifeCycle }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          annualisedCost, index
                        ) in specialityAnnualisedCostFilter"
                        :key="index"
                      >
                        {{ annualisedCost | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          annualisedMaintenanceCost, index
                        ) in specialityAnnualisedMaintenanceCostFilter"
                        :key="index"
                      >
                        {{ annualisedMaintenanceCost | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'workforce'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Workforce</th>
                    <th class="border px-4 py-2">Workforce Quantity</th>
                    <th class="border px-4 py-2">Cost Per Staff</th>
                    <th class="border px-4 py-2">Total Workforce Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          workforceName, index
                        ) in specialityWorkforceNameFilter"
                        :key="index"
                      >
                        {{ workforceName }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          financeName, index
                        ) in specialityWorkforceQuantityFilter"
                        :key="index"
                      >
                        {{ financeName | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          workforceCostPerStaff, index
                        ) in specialityWorkforceCostPerStaffFilter"
                        :key="index"
                      >
                        {{ workforceCostPerStaff | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          workforceTotalCost, index
                        ) in specialityWorkforceTotalWorkforceCostFilter"
                        :key="index"
                      >
                        {{ workforceTotalCost | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div v-if="stageSummary == 'buildingsFM'">
      <div class="route-content">
        <div v-if="specialityData != null">
          <!-- 1 open -->
          <table class="table-primary">
            <thead>
              <tr>
                <th class="border px-4 py-2">Area</th>
                <th class="border px-4 py-2">Total Floor Space</th>
                <th class="border px-4 py-2">Full Development Cost</th>
                <th class="border px-4 py-2">Net Build Cost</th>
                <th class="border px-4 py-2">Avg FM Cost</th>
                <th class="border px-4 py-2">FM Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div v-for="(jobPlan, index) in areaAreaFilter" :key="index">
                    {{ jobPlan }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanPatients, index) in areaTFSFilter"
                    :key="index"
                  >
                    {{ jobPlanPatients }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanperSessions, index) in areaNBCFilter"
                    :key="index"
                  >
                    {{ jobPlanperSessions }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanannSessions, index) in areaFDCFilter"
                    :key="index"
                  >
                    {{ jobPlanannSessions }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in areaAFMCostFilter"
                    :key="index"
                  >
                    {{ jobPlanweekSessions }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in areaFMCostFilter"
                    :key="index"
                  >
                    {{ jobPlanweekSessions }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Bar } from "vue-chartjs/legacy";
//import { Bubble } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "bar",
  components: {
    Bar,
    //Bubble,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stageSummary: "stage",
      selectedSpeciality: "AHPs",
      apiPayload: { speciality: "", apiString: "" },
      activeSummary: "",
      selectedSpecialityArea: "Patient Episodes",
      improvementSpecialityArea: "Diagnostics",
      specialityType: "Patient Episodes",
      improvementType: "Diagnostics",
      specialityApi: "ahpstwo-collection",
      population: "",
      firstUpdate: false,
      peps2: "8,580,427",
      peps1: "9,584,564",
      current_episodes: "7,508,402",
      assets2: "4,672",
      assets1: "5,219",
      current_assets: "4,134",
      workforce1: "14,374",
      workforce2: "12,921",
      current_workforce: "15,486",
      finance2: "1,816,961,854",
      finance1: "2,065,733,499",
      current_budget: "1,948,300,00"
    };
  },
  updated() {
    if (this.firstUpdate == false) {
      console.log("updated");
      this.fillData("workforceCost");
      this.population = this.getPopulation();
      this.firstUpdate = true;
    }
  },
  beforeMount() {
    console.log("before mounted");
    this.apiPayload.apiString = "grandsummarytwo-collection";
    this.getMongoDBData();
    this.fillData("assets");
  },
  mounted() {
    console.log("mounted");
    this.activeSummary = "workforceCost";
    this.apiPayload.speciality = "Grand Summary";
    this.apiPayload.apiString = "grandsummarytwo-collection";

    this.getMongoDBData();
    this.fillData("workforceCost");
  },
  watch: {
    selectedSpecialityArea: {
      handler: function (val) {
        console.log(
          "selectedSpecialityArea: Value Change: ",
          val.label,
          val.string
        );
        this.editSpecialityType(val.label);
        //console.log("speciality: ", this.specialityApi);
        this.apiPayload.apiString = this.specialityApi;
        this.getMongoDBData();
      },
      deep: true,
    },
    improvementSpecialityArea: {
      handler: function (val) {
        console.log(
          "improvementArea: Value Change: ",
          val.label,
          val.string
        );
        this.editImprovementType(val.label);
        //console.log("speciality: ", this.specialityApi);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      },
      deep: true,
    },
    activeSummary: {
      handler: function (newvalue) 
      {
        console.log("The new activeSummary value is ", newvalue);
        //this.fillData(newvalue);
        //console.log(
        //  "specialityOverviewFilter",
        //  this.specialityOverviewFilter()
        //);
      },
      deep: true,
    },
  },
  computed: {
    //store() {
    //  return this.$store.state.prototypedata;
    //},
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    specialityData() {
      console.log(
        "specialityData: ",
        this.$store.state.prototypedata.specialityData.length
      );
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return true;
      }
      return null;
    },
    getAreaIndex() {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "total_floor_space" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getWorkforceIndex() {
      var i = 0;
      var index = 0;

      //console.log("begin getWorkforceIndex");

      if (this.$store.state.prototypedata.specialityData.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          if (
            "workforce" in this.$store.state.prototypedata.specialityData[i]
          ) {
            index = i;
          }
        }
      }

      //console.log("end getWorkforceIndex");

      return index;
    },
    getPatientIndex() {
      var i = 0;
      var index = 0;

      //console.log("begin getPatientIndex");

      if (this.$store.state.prototypedata.specialityData.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          if (
            "patient_episodes" in
            this.$store.state.prototypedata.specialityData[i]
          ) {
            index = i;
          }
        }
      }

      //console.log("end getPatientIndex");

      return index;
    },
    getSpaceIndex() {
      var i = 0;
      var index = 0;

      //console.log("getSpaceIndex");

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "space_and_property" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getFinanceIndex() {
      var i = 0;
      var index = 0;

      //console.log("getFinanceIndex");

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if ("finance" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },
    getPopulationIndex() {
      var i = 0;
      var index = -1;

      //console.log("getFinanceIndex");

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if ("population" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },
    
    specialityWorkforceCostPerStaffFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].cost_per_staff;
      }
      return null;
    },
    specialityWorkforceTotalWorkforceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].total_workforce_cost;
      }
      return null;
    },
    // Workforce Summary Table -- Workforce column
    specialityWorkforceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].workforce;
      }
      return null;
    },
    // Workforce Table -- Quantity column
    specialityWorkforceQuantityFilter() 
    {
      var str = "";

      if (this.$store.state.prototypedata.specialityData.length > 0)
      {
        var i = 0;
        i = this.getWorkforceIndex;

        if (i >= 0)
        {
          str = this.$store.state.prototypedata.specialityData[i].workforce_quantity;
        }
      }
      return str;
    },
    workforceSummaryTableWorkforceFilter()
    {

      console.log("workforceSummaryTableWorkforceFilter");
      if (this.$store.state.prototypedata.specialityData.length > 0) 
      {
        var i = this.getDataSetIndex("cost_per_staff");

        if (i >= 0) {
          return this.$store.state.prototypedata.specialityData[i].workforce;
        }
      }
      return null;
    },
    workforceSummaryTableQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableQuantityFilter)
      );

      return str;
    },
    workforceSummaryTableQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("cost_per_staff")
        ].workforce_quantity;
      }
      return null;
    },
    workforceSummaryTableCostPerStaffFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("cost_per_staff")
        ].cost_per_staff;
      }
      return null;
    },
    workforceSummaryTableCostPerStaffFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableCostPerStaffFilter)
      );

      return str;
    },
    workforceSummaryTableTotalWorkforceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("cost_per_staff")
        ].total_workforce_cost;
      }
      return null;
    },
    workforceSummaryTableTotalWorkforceCostFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableTotalWorkforceCostFilter)
      );

      return str;
    },
    specialityPatientEpisodesTotalFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getPatientIndex
        ].patient_episodes_total;
      }
      return null;
    },
    specialitySpaceAndPropertyFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].space_and_property;
      }
      return null;
    },
    specialitySpaceAndPropertyQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].space_and_property_quantity;
      }
      return null;
    },
    specialityCapitalCostPerItemFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].capital_cost_per_item;
      }
      return null;
    },
    specialityTotalCapitalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].total_capital_cost;
      }
      return null;
    },
    specialityLifeCycleFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].life_cycle;
      }
      return null;
    },
    specialityAnnualisedCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].annualised_cost;
      }
      return null;
    },
    specialityAnnualisedMaintenanceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].annualised_maintenance_cost;
      }
      return null;
    },
    areaAreaFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .area;
      }
      return null;
    },
    areaTFSFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .total_floor_space;
      }
      return null;
    },
    areaNBCFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .net_build_cost;
      }
      return null;
    },
    areaFDCFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .full_dev_cost;
      }
      return null;
    },
    areaAFMCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .avg_fm_cost;
      }
      return null;
    },
    areaFMCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .fm_cost;
      }
      return null;
    },
    gfaFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getGFAIndex]
          .gfa;
      }
      return null;
    },
    specialityFinanceNameFilter() {
      console.log ('specialityFinanceNameFilter: ', this.$store.state.prototypedata.specialityData.length);
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getFinanceIndex
        ].finance;
      }
      return null;
    },
    specialityPatientEpisodesFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getPatientIndex
        ].patient_episodes;
      }

      return null;
    },

    specialityPatientEpisodesTotalFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.specialityPatientEpisodesTotalFilter1)
      );

      return str;
    },
    assetsFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].assets;
      }
      return null;
    },
    assetsQuantityFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].assets_quantity;
      }
      return null;
    },
    assetsQuantityFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsQuantityFilter1)
      );

      return str;
    },

    assetsCapitalCostPerItemFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].capital_cost_per_item;
      }
      return null;
    },
    assetsCapitalCostPerItemFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsCapitalCostPerItemFilter1)
      );

      return str;
    },
    assetsTotalCapitalCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].total_capital_cost;
      }
      return null;
    },
    assetsTotalCapitalCostFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsTotalCapitalCostFilter1)
      );

      return str;
    },
    assetsLifeCycleFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].life_cycle;
      }
      return null;
    },
    assetsLifeCycleFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsLifeCycleFilter1)
      );

      return str;
    },
    assetsAnnualisedCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].annualised_cost;
      }
      return null;
    },
    assetsAnnualisedCostFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsAnnualisedCostFilter1)
      );

      return str;
    },
    assetsAnnualisedMaintenanceCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].annualised_maintenance_cost;
      }
      return null;
    },
    assetsAnnualisedMaintenanceCostFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsAnnualisedMaintenanceCostFilter1)
      );

      return str;
    },
    assetsTotalCapitalCostOverallFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateAssetsTotalCapitalCost(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("annualised_cost")
          ].total_capital_cost
        );
      }
      return null;
    },
    assetsAnnualisedCostOverallFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateAssetsAnnualisedCost(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("annualised_cost")
          ].annualised_cost
        );
      }
      return null;
    },
    assetsAnnualisedMaintenanceCostOverallFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateAssetsAnnualisedMaintenanceCost(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("annualised_cost")
          ].annualised_maintenance_cost
        );
      }
      return null;
    },

    //-----------------------------------------------------------------------------------
    // SUMMARY ASSET
    //-----------------------------------------------------------------------------------
    // Grand Summary
    // Summary Asset Table
    // Asset Filter
    summaryAssetFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_assets")
        ].summary_assets;
      }
      return null;
    },
    // Grand Summary
    // Summary Asset Table
    // Assets Quantity Filter
    summaryAssetQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        var i = this.getDataSetIndex("summary_assets");

        if (i >= 0) {
          return this.$store.state.prototypedata.specialityData[i]
            .summary_assets_quantity;
        }
      }
      return null;
    },
    summaryAssetQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryAssetQuantityFilter)
      );

      return str;
    },
    // Grand Summary
    // Summary Asset Table
    // Asset Total Capital Cost Filter
    summaryAssetTotalCapitalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_assets")
        ].summary_assets_total_capital_cost;
      }
      return null;
    },
    specialityFinanceTotalFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getFinanceIndex
        ].finance_total;
      }
      return null;
    },
    summaryAssetTotalCapitalCostFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryAssetTotalCapitalCostFilter)
      );

      return str;
    },

    // Grand Summary
    // Summary Patient Table
    // Patient Episodes Filter
    summaryPatientFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 7) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_patient_episodes")
        ].summary_patient_episodes;
      }
      return null;
    },
    // Grand Summary
    // Summary Patient Table
    // Patient Quantity Filter
    summaryPatientQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 7) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_patient_episodes")
        ].summary_patient_quantity;
      }
      return null;
    },
    summaryPatientQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryPatientQuantityFilter)
      );

      return str;
    },
  },
  methods: {
    getTileData(tileNumber) {
      console.log("--------------------------------------");
      console.log("SummaryMain2: getTileData", tileNumber);

      let defaultValues = new Array();
      let index = -1;

      let currentvalue = "";

      defaultValues.push(this.peps2);          // Patient Episode Productivity
      defaultValues.push(this.assets2);              // Assets
      defaultValues.push(this.workforce2);          // Workforce
      defaultValues.push(this.finance2);      // Finance

      defaultValues.push(this.peps1);          // Patient Episode Productivity (Stage 1)
      defaultValues.push(this.assets1);              // Assets (Stage 1)
      defaultValues.push(this.workforce1);          // Workforce (Stage 1)
      defaultValues.push(this.finance1);      // Finance (Stage 1)

      defaultValues.push("TBD");        //      // Current Episodes
      defaultValues.push("TBD");        //      // Current Assets
      defaultValues.push("TBD");        //      // Current Workforce
      defaultValues.push("TBD");        //      // Current Budget

      index = this.getDataSetIndex("summary_overview");

      console.log("GetTileData - getDataSetIndex", index);

      currentvalue = defaultValues[tileNumber];

      if (index>= 0)
      {
        console.log(
        "length: ",
        this.$store.state.prototypedata.specialityData[index].summary_overview
          .length
      );
      }

      

      if (index >= 0) {
        //console.log("index > 0");
        if (
          tileNumber <=
          this.$store.state.prototypedata.specialityData[index].summary_overview
            .length
        ) {
          //console.log("tileNumber");
          if (
            this.$store.state.prototypedata.specialityData[index]
              .summary_overview[tileNumber] == null ||
            this.$store.state.prototypedata.specialityData[index]
              .summary_overview[tileNumber].length == 0
          ) {
            currentvalue = defaultValues[tileNumber];
          } else {
            currentvalue =
              this.$store.state.prototypedata.specialityData[index]
                .summary_overview[tileNumber];
          }
        }
      }

      console.log("currentvalue ", currentvalue);
      console.log("--------------------------------------");

      return currentvalue;
    },
    getPopulation()
    {
      var i = this.getPopulationIndex;
      console.log ("Stage2: getPopulation", i);

      if (i > -1)
      {
        if (this.$store.state.prototypedata.specialityData.length > 0) 
        {
            this.population = this.$store.state.prototypedata.specialityData[this.getPopulationIndex].population[0];
            console.log ("Stage2: getPopulation: ", this.population);
        }
      }

      //TODO: fix this
      return "995,900";
      
      //return this.population;
    },
    logData() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        console.log(
          "logData: length: ",
          this.$store.state.prototypedata.specialityData.length
        );
        for (
          var i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          console.log(
            "item[",
            i,
            "]=",
            this.$store.state.prototypedata.specialityData[i]
          );
        }
      }
    },
    getStageOneData ()
    {
         this.apiPayload.apiString = "grandsummaryone-collection";

        console.log("stage: getData");

        this.getMongoDBData();
    },    
    fetchData(speciality) {
      console.log("fetchData: ");
      this.apiPayload.apiString = speciality.apiString;
      this.apiPayload.speciality = "fetchData";

      this.specialityApi = speciality.apiString;

      this.getMongoDBData();
    },
    getMongoDBData() {
      this.$store.dispatch("GetData", this.apiPayload).then((data) => {
        console.log("data", data);
      });
    },
    specialityHeader(header) {
      return header;
    },
    editActiveStageSummary(activeSummary) {
      this.activeSummary = activeSummary;
    },
    fillData(tileValue) {
      console.log("SummaryMain2 - fillData:", tileValue);
      this.activeSummary = tileValue;
      console.log("summary2 - activeSummary", this.activeSummary);

      this.datacollection = {
        labels: this.getBarGraphLabels(),
        datasets: [
          {
            label: "Stage 1",
            data: this.getBarGraphDataValues1(),
            //data: [500, 500, 500],
            backgroundColor: ["rgba(255, 99, 132, 0.2)"],
            borderColor: ["rgb(255, 99, 132)"],
            borderWidth: 1,
          },
          {
            label: "Stage 2",
            data: this.getBarGraphDataValues2(),
            //data: [100, 100, 100],
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgb(75, 192, 192)"],
            borderWidth: 1,
          },
          {
            label: "Current",
            data: this.getBarGraphDataValues3(),
            //data: [200, 200, 200],
            backgroundColor: ["rgba(54, 162, 235, 0.2)"],
            borderColor: ["rgb(54, 162, 235)"],
            borderWidth: 1,
          },
        ],
      };
    },
    getBarGraphDataValues1() {
      var data = new Array();

      console.log("getBarGraphValues1");
      //var temp;
      //var str;

      //console.log ('DataValues: this.activeSummary = ', this.activeSummary);

      if (this.activeSummary == "workforceCost") {
        data.push("12921");
      } else if (this.activeSummary == "assets") {
        data.push("5219");
      } else if (this.activeSummary == "finance") {
        data.push("2065733499");
      } else if (this.activeSummary == "patientEpisode") {
        data.push("9584564");
      }

      //finance2: "1,816,961,854",
      //finance1: "2,065,733,499",


      return data;
    },
    getBarGraphDataValues2() {
      var data = new Array();

      console.log("getBarGraphValues2");

      if (this.activeSummary == "workforceCost") {
        data.push("14374");
      } else if (this.activeSummary == "assets") {
        data.push("4672");
      } else if (this.activeSummary == "finance") {
        data.push("1816961854");
      } else if (this.activeSummary == "patientEpisode") {
        data.push("8580427");
      }

      return data;
    },
    getBarGraphDataValues3() {
      var data = new Array();

      console.log("getBarGraphValues1");

      if (this.activeSummary == "workforceCost") {
        data.push("15486");
      } else if (this.activeSummary == "assets") {
        data.push("4134");
      } else if (this.activeSummary == "finance") {
        data.push("1948300300");
      } else if (this.activeSummary == "patientEpisode") {
        data.push("7508402");
      }

      return data;
    },
    getBarGraphLabels() {
      var data = new Array();
      console.log("getBarGraphLabels");
      //var temp;

      //console.log ('this.activeSummary = ', this.activeSummary);

      if (this.activeSummary == "workforceCost") {
        //temp = ['Workforce'];
        data.push("Workforce");
      } else if (this.activeSummary == "assets") {
        data.push("Assets");
      } else if (this.activeSummary == "finance") {
        data.push("Finance");
      } else if (this.activeSummary == "patientEpisode") {
        data.push("Patient Episodes");
      }

      return data;
    },
    getGraphTitle() {
      var str;

      console.log("getGraphTitle");

      if (this.activeSummary == "workforceCost") {
        str = "Workforce Quantity";
      } else if (this.activeSummary == "assets") {
        str = "Asset Quantity";
      } else if (this.activeSummary == "finance") {
        str = "Finance Quantity";
      } else if (this.activeSummary == "patientEpisode") {
        str = "Patient Episodes Quantity";
      }
      return str;
    },
    editSpecialityType(specialityTypeName) {
      if (specialityTypeName == "Patient Episodes") {
        this.specialityType = "patientEpisodes";
      }
      if (specialityTypeName == "Finance") {
        this.specialityType = "finance";
      }
      if (specialityTypeName == "Workforce") {
        this.specialityType = "workforce";
      }
      if (specialityTypeName == "Space & Property") {
        this.specialityType = "space";
      }

      console.log(
        "editSpecialityType (",
        specialityTypeName,
        ")=",
        this.specialityType
      );
    },
    getBarGraphDataValues() {
      var data = new Array();
      var temp;
      var str;

      console.log("getBarGraphDataValues");

      //console.log("DataValues: this.activeSummary = ", this.activeSummary);

      if (this.activeSummary == "workforceCost") {
        console.log("begin temp1");
        temp = this.workforceTableQuantityFilter;
        console.log("end temp1");
      } else if (this.activeSummary == "assets") {
        console.log("begin temp2");
        temp = this.summaryAssetQuantityFilter;
        console.log("end temp2");
      } else if (this.activeSummary == "finance") {
        console.log("begin temp3");
        temp = this.summaryAssetQuantityFilter;
        console.log("end temp3");
      } else if (this.activeSummary == "patientEpisode") {
        console.log("begin temp4");
        temp = this.summaryPatientQuantityFilter;
        console.log("end temp4");
      }

      //console.log("temp", temp);

      if (this.isEmpty(temp) == false || typeof temp !== "undefined") {
        console.log("temp", temp);
        for (var i = 0; i < temp.length; i++) {
          str = temp[i].replaceAll(",", "");
          data.push(str);
        }
      }

      console.log("getBarGraphDataValues:", data);

      return data;
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    calculateArray(data) {
      let arrayValuesFloatArray = new Array();
      let str = "";
      let parse = false;

      if (typeof data != "undefined") {
        //tempValue = 0;
        str = "";
        parse = false;

        for (var i = 0; i < data.length; i++) {
          //console.log("data[i] = ", data[i]);
          str = String(data[i]);
          str = str.replaceAll(",", "");
          //str = str.replaceAll(",", ".");

          //str = str.replaceAll(".", ",");

          if (str.includes(".")) {
            parse = true;
          }

          //console.log("str[i] = ", str);

          arrayValuesFloatArray.push(parseFloat(str));

          //console.log("arrayValuesFloatArray[i]=", arrayValuesFloatArray[i]);

          //tempValue = tempValue + str;
        }
      }

      //console.log("tempValue = ", tempValue);

      const total = arrayValuesFloatArray.reduce(function (a, b) {
        return a + b;
      });
      if (parse == true) {
        return total.toFixed(2);
      } else {
        return total;
      }
    },
    getDataSetIndex(item) {
      var i = 0;
      var id = -1;

      if (this.$store.state.prototypedata.specialityData.length > 8) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          if (item in this.$store.state.prototypedata.specialityData[i]) {
            id = i;
          }
        }
      }

      console.log("getDataSetIndex", item, id);

      return id;
    },
    summaryTotalQuantityFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateTotalQuantity(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("cost_per_staff")
          ].workforce_quantity
        );
      }
      return null;
    },
    // Summary Overview
    // Controls the information on the boxes within the Stage
    specialityOverviewFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_overview")
        ].summary_overview;
      }
      return null;
    },

    
    editImprovementType(specialityTypeName) {
      console.log ("editImprovement: ", specialityTypeName);
      if (specialityTypeName == "Diagnostics") {
        this.improvementType = "diagnostics";
      }
      if (specialityTypeName == "Outpatient Clinics") {
        this.improvementType = "OPD";
      }
      if (specialityTypeName == "OR") {
        this.improvementType = "OR";
      }
      if (specialityTypeName == "Emergency Room (ER)") {
        this.improvementType = "ER";
      }

      if (specialityTypeName == "Bed Flow") {
        this.improvementType = "bedflow";
      }

      console.log(
        "editImprovementType (",
        specialityTypeName,
        ")=",
        this.improvementType
      );
    },
    editStageSummary(stageSummary) {
      this.stageSummary = stageSummary;

      if (this.stageSummary == "stage") {
        this.apiPayload.apiString = "grandsummarytwo-collection";

        console.log("stage: getData");

        this.getMongoDBData();
        this.population = this.getPopulation();
        this.fillData("workforceCost");
      }

      if (this.stageSummary == "buildingsFM") {
        this.apiPayload.apiString = "fm_build2_collection";
        this.getMongoDBData();
      }

      if (this.stageSummary == "specialities") {
        console.log(
          " this.selectedSpecialityArea.selected",
          this.selectedSpecialityArea
        );

        this.editSpecialityType(this.selectedSpecialityArea);
        this.apiPayload.apiString = this.specialityApi;
        this.getMongoDBData();
      }

      if (this.stageSummary == "opImprov") {
        console.log(
          " this.improvementSpecialityAra.selected",
          this.improvementSpecialityArea
        );

        this.editImprovementType(this.improvementSpecialityArea);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      }
    },
  },
};
</script>
<style>
.chartcontainer {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 400px;
  display: inline-block;
  width: 350px; /* was 26 rem */
  margin-right: 20px;
  padding-bottom: 5px;
}
.title_text {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
</style>

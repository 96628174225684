import Shell from '../../../layouts/Shell.vue'
import BuildFM2Main from '../views/BuildFM2.vue'
 
const routes = [
    {
        path: '/',
        name: 'LayoutModule',
        component: Shell,
        children: [
        {
            path: '/buildFM2',
            name: 'BuildFM2Main',
            component: BuildFM2Main,
            meta: {
                requiresAuth: true
            }
        }]
    }
]

export default routes

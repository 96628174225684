<template>
  <div>
      <header class="route-header">
        <div class="route-header--left">
          <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
        </div>
        <div class="route-header--right">
          <h1 class="text-3xl">
            Live AI Optimization
          </h1>
        </div>
  
        <div class="validation-cards--item cert no-hover">
          <span class="card-header">Certification Status</span>
          <div class="card-icon" margin-top="0px">
            <img
              src="@/assets/img/circular-label-with-certified-stamp.png"
              alt=""
            />
            <span class="card-total">100% Validation</span>
          </div>
        </div>
      </header>
  
      <div class="route-header items-center secondary">
        <div class="route-header--box">
          <div class="box">
            <span class="box-header">Location</span>
            <span class="box-label">Surrey - United Kingdom</span>
          </div>
        </div>
      </div>
  
      <nav class="route-nav">
        <ul>
          <li class="active">
            <a href="#">Surrey</a>
            <jam-close />
          </li>
        </ul>
      </nav>
  
      <div class="route-content">
        <div class="stat-cards" v-if="workForceDataTwo !== null">
          <div
            class="stat-cards--item single"
            :class="activeSummary === 'patientEpisode60month' ? 'active' : ''"
            @click="fillData('patientEpisode60month')"
          >
            <span class="card-header">Patient Episode Productivity</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-patient.svg"
                alt="Patient Episodes"
              />
              <span class="card-total">{{ getTileData(0) }}</span>
            </div>
            <span class="card-info"></span>
          </div>
  
          <div
            class="stat-cards--item single"
            :class="activeSummary === 'assets60month' ? 'active' : ''"
            @click="fillData('assets60month')"
          >
            <span class="card-header">Assets</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <span class="card-total">{{ getTileData(1) }}</span>
            </div>
            <span class="card-info"></span>
          </div>
  
          <div
            class="stat-cards--item single"
            :class="activeSummary === 'workforce60month' ? 'active' : ''"
            @click="fillData('workforce60month')"
          >
            <span class="card-header">Workforce</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
              <span class="card-total">{{ getTileData(2) }}</span>
            </div>
            <span class="card-info"
              ></span
            >
          </div>
  
          <div
            class="stat-cards--item single"
            :class="activeSummary === 'finance60month' ? 'active' : ''"
            @click="fillData('finance60month')"
          >
            <span class="card-header">Finance</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <span class="card-total">{{ getTileData(3) }}</span>
            </div>
            <span class="card-info"></span>
          </div>

        <!-- div class="stat-cards--item single" 
        :class="activeSummary === 'patientEpisode12month' ? 'active' : ''" @click="fillData('patientEpisode12month')">
        <span class="card-header">Patient Episode Productivity</span>
        <div class="card-icon">
          <img src="@/assets/img/icons/icon-patient.svg" alt="Patient Episodes"/>        
          <span class="card-total">{{ getTileData(4) }}</span>
        </div>
        <span class="card-info"></span>
        </div>

        <div
            class="stat-cards--item single"
            :class="activeSummary === 'assets12month' ? 'active' : ''"
            @click="fillData('assets12month')"
          >
            <span class="card-header">Assets</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <span class="card-total">{{ getTileData(5) }}</span>
            </div>
            <span class="card-info"></span>
          </div>

          <div
            class="stat-cards--item single"
            :class="activeSummary === 'workforceCost12month' ? 'active' : ''"
            @click="fillData('workforceCost12month')"
          >
            <span class="card-header">Workforce</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
              <span class="card-total">{{ getTileData(6) }}</span>
            </div>
            <span class="card-info"
              ></span
            >
          </div>

          <div
            class="stat-cards--item single"
            :class="activeSummary === 'finance12month' ? 'active' : ''"
            @click="fillData('finance12month')"
          >
            <span class="card-header">Finance</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <span class="card-total">{{ getTileData(7) }}</span>
            </div>
            <span class="card-info"></span>
          </div>

          <div
            class="stat-cards--item single"
            :class="activeSummary === 'patientEpisode1month' ? 'active' : ''"
            @click="fillData('patientEpisode1month')"
          >
            <span class="card-header">Patient Episode Productivity</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-patient.svg"
                alt="Patient Episodes"
              />
              <span class="card-total">{{ getTileData(8) }}</span>
            </div>
            <span class="card-info"></span>
          </div>

          <div
            class="stat-cards--item single"
            :class="activeSummary === 'assets1month' ? 'active' : ''"
            @click="fillData('assets1month')"
          >
            <span class="card-header">Assets</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <span class="card-total">{{ getTileData(9) }}</span>
            </div>
            <span class="card-info"></span>
          </div>

          <div
            class="stat-cards--item single"
            :class="activeSummary === 'workforceCost1month' ? 'active' : ''"
            @click="fillData('workforceCost1month')"
          >
            <span class="card-header">Workforce</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
              <span class="card-total">{{ getTileData(10) }}</span>
            </div>
            <span class="card-info"
              ></span
            >
          </div>

          <div
            class="stat-cards--item single"
            :class="activeSummary === 'finance1month' ? 'active' : ''"
            @click="fillData('finance1month')"
          >
            <span class="card-header">Finance</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <span class="card-total">{{ getTileData(11) }}</span>
            </div>
            <span class="card-info"></span>
          </div -->

        <div v-if="activeSummary == 'assets60month'">
          <!-- start -->
          <h1 class="text-3xl my-6 active">Assets (5 Year) 60 Month Tracker</h1>
  
          <div class="chartcontainer" style="width: 410px; height:350px">
            <div class="title_text">UTILISATION</div>
            <LineChartGenerator
              :chart-options="chartOptions"
              :chart-data="dataAssetsUtilisationcollection60month"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="500"
              :height="300"
            >
          </div>
  
          <div class="chartcontainer" style="width: 410px; height:350px">
            <div class="title_text">BEDS</div>
            <LineChartGenerator
              :chart-options="chartOptions"
              :chart-data="dataBedscollection60month"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="500"
              :height="300"
            >
          </div>
  
          <div class="chartcontainer" style="width: 410px; height:350px">
            <div class="title_text">INTERVENTION ROOMS</div>
            <LineChartGenerator
              :chart-options="chartOptions"
              :chart-data="dataOperatingcollection60month"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="500"
              :height="300"
            >
          </div>
  
          <div>
            <h1><br /></h1>
          </div>
  
        
        </div>

        <div v-if="activeSummary == 'finance60month'">
          <!-- start -->
          <h1 class="text-3xl my-6 active">Finance (5 Year) 60 Month Tracker</h1>
  
          <div class="chartcontainer" style="width: 410px; height:350px">
            <div class="title_text">INCOME</div>
            <LineChartGenerator
              :chart-options="chartOptions"
              :chart-data="incomecollection60month"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="500"
              :height="300"
            >
          </div>
  
          <div class="chartcontainer" style="width: 410px; height:350px">
            <div class="title_text">COST</div>
            <LineChartGenerator
              :chart-options="chartOptions"
              :chart-data="costFinanceCollection60month"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="500"
              :height="300"
            >
          </div>
  
          <div class="chartcontainer" style="width: 410px; height:350px">
            <div class="title_text">SUPPLY CHAIN</div>
            <LineChartGenerator
              :chart-options="chartOptions"
              :chart-data="suppyChainFinanceCollection60month"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="500"
              :height="300"
            >
          </div>
  
          <div>
            <h1><br /></h1>
          </div>
  
        
        </div>
  
  
       
  
        <div v-if="activeSummary == 'patientEpisode60month'">
          <!-- start -->
          <h1 class="text-3xl my-6 active">Episodes (5 Year) 60 Month Tracker</h1>
  
          <div class="chartcontainer" style="width: 410px; height:350px">
            <div class="title_text">UTILISATION</div>
            <LineChartGenerator
              :chart-options="chartOptions"
              :chart-data="patientcollection60month"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="500"
              :height="300"
            >
          </div>      
  
          <div>
            <h1><br /></h1>
          </div>
  
        
        </div>
  
        <div v-if="activeSummary == 'workforce60month'">
          <!-- start -->
          <h1 class="text-3xl my-6 active">Workforce (5 Year) 60 Month Tracker</h1>
  
          <div class="chartcontainer" style="width: 410px; height:350px">
            <div class="title_text">WORKFORCE FTE</div>
            <LineChartGenerator
              :chart-options="chartOptions"
              :chart-data="workforceFTEcollection60month"  
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="500"
              :height="300"
            >
          </div>
  
          <div class="chartcontainer" style="width: 410px; height:350px">
            <div class="title_text">MEDICAL STAFF</div>
            <LineChartGenerator
              :chart-options="chartOptions"
              :chart-data="workforceMedicalStaffcollection60month"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="500"
              :height="300"
            >
          </div>
  
          <div class="chartcontainer" style="width: 410px; height:350px">
            <div class="title_text">NURSING</div>
            <LineChartGenerator
              :chart-options="chartOptions"
              :chart-data="workforceNursingcollection60month"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="500"
              :height="300"
            >
          </div>
  
          <div class="chartcontainer" style="width: 410px; height:350px">
            <div class="title_text">ADMINISTRATOR FTE</div>
            <LineChartGenerator
              :chart-options="chartOptions"
              :chart-data="workforceAdminCollection60month"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="500"
              :height="300"
            >
          </div>
  
          <div>
            <h1><br /></h1>
          </div>
  
        
        </div>

      

      

       

       
    </div>
    </div>
  </div>
  </template>
  <script>
  import { Line as LineChartGenerator } from "vue-chartjs/legacy";
  //import { Bar  } from "vue-chartjs/legacy";
  //import { Bubble } from "vue-chartjs/legacy";
  //import { Doughnut } from "vue-chartjs/legacy";
  //import { Pie } from 'vue-chartjs/legacy';
  //import { PolarArea } from 'vue-chartjs/legacy';
  //import { Radar } from 'vue-chartjs/legacy'
  //import { Scatter } from 'vue-chartjs/legacy'
  
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    ArcElement,
    RadialLinearScale
  } from "chart.js";
  
  
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    ArcElement,
    RadialLinearScale
  );
  
  
  
  export default {
    
    components: {
      LineChartGenerator,
      //Bar,
      //Bubble,
      //Doughnut,
      //Pie,
      //PolarArea,
      //Radar,
      //Scatter
  },
    props: {
      chartLineId:   {   type: String, default: "line-chart",},
      chartBarId:    {   type: String, default: "bar",},
      chartBubbleId: {   type: String, default: "bubble-chart",},
      chartDoughnutId: { type: String, default: "doughnut-chart",},
      chartRadarId: { type: String, default : "radar-chart",},
      chartPolarId:  { type: String, default: "polar-chart",},
      chartScatterId: { type: String, default: "scatter-chart",},
      chartPieId:    { type: String, default: "pie-chart",},
      datasetIdKey: {   type: String, default: "label",},
      width:        {   type: Number, default: 300,},   // 200
      height:       {   type: Number, default: 300, },  // 200
      cssClasses:   {   type: String, default: "", },
      styles:       {   type: Object, default: () => {},},
      plugins:      {   type: Array,  default: () => [], },
    },
    data() {
      return {
        activeSummary: "patientEpisodes",
        selectedSpeciality: "AHPs",
        dataLinecollection: "", 
        dataBarCollection: "",
        dataBubbleCollection: "",
        dataDoughnutCollection: "",
        chartDataPie: "",
        chartData: "",
        chartDataRadar: "",
        chartDataScatter: "",
      };
    },
    mounted() {
      console.log("summaryMain4.vue");  
    },
    computed: {
      store() {
        return this.$store.state.prototypedata;
      },
      specialityName() {
        return this.$store.state.prototypedata.specialityName;
      },
      specialityData() {
        if (this.$store.state.prototypedata.specialityData.length > 0) {
          return true;
        }
        return null;
      },
    },
    methods: {
     getTileData(tileNumber)
     {
      var Str = ""; 

      if (tileNumber == "0")        Str = "60 Month Tracker";
      if (tileNumber == "1")        Str = "60 Month Tracker";
      if (tileNumber == "2")        Str = "60 Month Tracker";
      if (tileNumber == "3")        Str = "60 Month Tracker";
      if (tileNumber == "4")        Str = "12 Month Tracker";
      if (tileNumber == "5")        Str = "12 Month Tracker";
      if (tileNumber == "6")        Str = "12 Month Tracker";
      if (tileNumber == "7")        Str = "12 Month Tracker";
      if (tileNumber == "8")        Str = "Optimization 1 Month";
      if (tileNumber == "9")        Str = "Optimization 1 Month";
      if (tileNumber == "10")        Str = "Optimization 1 Month";
      if (tileNumber == "11")        Str = "Optimization 1 Month";
    
      return Str;
     },
     getTargetOperatingRoomsUtilised(index)
     {
        let data = new Array ();

        if (index == 1)
        {
  
        // Data Point1
data.push (225.213381909764);
// Data Point2
data.push (225.213381909764);
// Data Point3
data.push (225.213381909764);
// Data Point4
data.push (225.213381909764);
// Data Point5
data.push (225.213381909764);
// Data Point6
data.push (225.213381909764);
// Data Point7
data.push (225.213381909764);
// Data Point8
data.push (225.213381909764);
// Data Point9
data.push (225.213381909764);
// Data Point10
data.push (225.213381909764);
// Data Point11
data.push (225.213381909764);
// Data Point12
data.push (225.213381909764);
// Data Point13
data.push (228.309776165292);
// Data Point14
data.push (228.309776165292);
// Data Point15
data.push (228.309776165292);
// Data Point16
data.push (228.309776165292);
// Data Point17
data.push (228.309776165292);
// Data Point18
data.push (228.309776165292);
// Data Point19
data.push (228.309776165292);
// Data Point20
data.push (228.309776165292);
// Data Point21
data.push (228.309776165292);
// Data Point22
data.push (228.309776165292);
// Data Point23
data.push (228.309776165292);
// Data Point24
data.push (228.309776165292);
// Data Point25
data.push (231.496138197184);
// Data Point26
data.push (231.496138197184);
// Data Point27
data.push (231.496138197184);
// Data Point28
data.push (231.496138197184);
// Data Point29
data.push (231.496138197184);
// Data Point30
data.push (231.496138197184);
// Data Point31
data.push (231.496138197184);
// Data Point32
data.push (231.496138197184);
// Data Point33
data.push (231.496138197184);
// Data Point34
data.push (231.496138197184);
// Data Point35
data.push (231.496138197184);
// Data Point36
data.push (231.496138197184);
// Data Point37
data.push (234.740240044838);
// Data Point38
data.push (234.740240044838);
// Data Point39
data.push (234.740240044838);
// Data Point40
data.push (234.740240044838);
// Data Point41
data.push (234.740240044838);
// Data Point42
data.push (234.740240044838);
// Data Point43
data.push (234.740240044838);
// Data Point44
data.push (234.740240044838);
// Data Point45
data.push (234.740240044838);
// Data Point46
data.push (234.740240044838);
// Data Point47
data.push (234.740240044838);
// Data Point48
data.push (234.740240044838);
// Data Point49
data.push (238.042696638554);
// Data Point50
data.push (238.042696638554);
// Data Point51
data.push (238.042696638554);
// Data Point52
data.push (238.042696638554);
// Data Point53
data.push (238.042696638554);
// Data Point54
data.push (238.042696638554);
// Data Point55
data.push (238.042696638554);
// Data Point56
data.push (238.042696638554);
// Data Point57
data.push (238.042696638554);
// Data Point58
data.push (238.042696638554);
// Data Point59
data.push (238.042696638554);
// Data Point60
data.push (238.042696638554);


        }
  
  
        return data;
     },
     getOperatingRoomsVariance(index)
     {
        let data = new Array ();

        if (index == 1)
        {
         // Data Point1
data.push (14);
// Data Point2
data.push (14);
// Data Point3
data.push (14);
// Data Point4
data.push (14);
// Data Point5
data.push (14);
// Data Point6
data.push (14);
// Data Point7
data.push (14);
// Data Point8
data.push (14);
// Data Point9
data.push (14);
// Data Point10
data.push (14);
// Data Point11
data.push (14);
// Data Point12
data.push (14);
// Data Point13
data.push (14);
// Data Point14
data.push (14);
// Data Point15
data.push (14);
// Data Point16
data.push (14);
// Data Point17
data.push (14);
// Data Point18
data.push (14);
// Data Point19
data.push (14);
// Data Point20
data.push (14);
// Data Point21
data.push (14);
// Data Point22
data.push (14);
// Data Point23
data.push (14);
// Data Point24
data.push (14);
// Data Point25
data.push (14);
// Data Point26
data.push (14);
// Data Point27
data.push (14);
// Data Point28
data.push (14);
// Data Point29
data.push (14);
// Data Point30
data.push (14);
// Data Point31
data.push (14);
// Data Point32
data.push (14);
// Data Point33
data.push (14);
// Data Point34
data.push (14);
// Data Point35
data.push (14);
// Data Point36
data.push (14);
// Data Point37
data.push (14);
// Data Point38
data.push (14);
// Data Point39
data.push (14);
// Data Point40
data.push (14);
// Data Point41
data.push (14);
// Data Point42
data.push (14);
// Data Point43
data.push (14);
// Data Point44
data.push (14);
// Data Point45
data.push (14);
// Data Point46
data.push (14);
// Data Point47
data.push (14);
// Data Point48
data.push (14);
// Data Point49
data.push (14);
// Data Point50
data.push (14);
// Data Point51
data.push (14);
// Data Point52
data.push (14);
// Data Point53
data.push (14);
// Data Point54
data.push (14);
// Data Point55
data.push (14);
// Data Point56
data.push (14);
// Data Point57
data.push (14);
// Data Point58
data.push (14);
// Data Point59
data.push (14);
// Data Point60
data.push (14);

        }
  
  
        return data;
     },
     getActualBedsOccupied(index)
     {

        let data = new Array ();

        if (index == 1)
        {
          data.push (1756);
        }  
        
        return data;
     },
     getActualOperatingRoomsUtilised(index)
     {
  
        let data = new Array ();
  
        if (index == 1)
        {
          data.push (14); 
        }
  
        return data;
     },
     getActualBedsVariance(index)
     {
      let data = new Array ();

      if (index == 1)
      {
         // Data Point1
        data.push (1756);
// Data Point2
data.push (1764.78);
// Data Point3
data.push (1773.6039);
// Data Point4
data.push (1782.4719195);
// Data Point5
data.push (1791.3842790975);
// Data Point6
data.push (1800.34120049299);
// Data Point7
data.push (1809.34290649545);
// Data Point8
data.push (1818.38962102793);
// Data Point9
data.push (1827.48156913307);
// Data Point10
data.push (1836.61897697873);
// Data Point11
data.push (1845.80207186363);
// Data Point12
data.push (1855.03108222294);
// Data Point13
data.push (1864.30623763406);
// Data Point14
data.push (1873.62776882223);
// Data Point15
data.push (1882.99590766634);
// Data Point16
data.push (1892.41088720467);
// Data Point17
data.push (1901.87294164069);
// Data Point18
data.push (1911.3823063489);
// Data Point19
data.push (1920.93921788064);
// Data Point20
data.push (1930.54391397005);
// Data Point21
data.push (1940.1966335399);
// Data Point22
data.push (1949.89761670759);
// Data Point23
data.push (1959.64710479113);
// Data Point24
data.push (1969.44534031509);
// Data Point25
data.push (1979.29256701666);
// Data Point26
data.push (1989.18902985175);
// Data Point27
data.push (1999.134975001);
// Data Point28
data.push (2009.13064987601);
// Data Point29
data.push (2019.17630312539);
// Data Point30
data.push (2029.27218464102);
// Data Point31
data.push (2039.41854556422);
// Data Point32
data.push (2049.61563829204);
// Data Point33
data.push (2059.8637164835);
// Data Point34
data.push (2070.16303506592);
// Data Point35
data.push (2080.51385024125);
// Data Point36
data.push (2090.91641949245);
// Data Point37
data.push (2101.37100158992);
// Data Point38
data.push (2111.87785659787);
// Data Point39
data.push (2122.43724588086);
// Data Point40
data.push (2133.04943211026);
// Data Point41
data.push (2143.71467927081);
// Data Point42
data.push (2154.43325266716);
// Data Point43
data.push (2165.2054189305);
// Data Point44
data.push (2176.03144602515);
// Data Point45
data.push (2186.91160325528);
// Data Point46
data.push (2197.84616127155);
// Data Point47
data.push (2208.83539207791);
// Data Point48
data.push (2219.8795690383);
// Data Point49
data.push (2230.97896688349);
// Data Point50
data.push (2242.13386171791);
// Data Point51
data.push (2253.3445310265);
// Data Point52
data.push (2264.61125368163);
// Data Point53
data.push (2275.93430995004);
// Data Point54
data.push (2287.31398149979);
// Data Point55
data.push (2298.75055140729);
// Data Point56
data.push (2310.24430416432);
// Data Point57
data.push (2321.79552568515);
// Data Point58
data.push (2333.40450331357);
// Data Point59
data.push (2345.07152583014);
// Data Point60
data.push (2356.79688345929);
      }
  
  
      return data;
     },
     getActualIncome(index)
     {
        let data = new Array ();
        if (index == 1)
        {
            data.push ("162358333");
        }

        return data;

     },
     getTargetIncome(index)
     {
       let data = new Array ();

       if (index == 1)
       {
      // Data Point1
data.push (157312493.210576);
// Data Point2
data.push (157312493.210576);
// Data Point3
data.push (157312493.210576);
// Data Point4
data.push (157312493.210576);
// Data Point5
data.push (157312493.210576);
// Data Point6
data.push (157312493.210576);
// Data Point7
data.push (157312493.210576);
// Data Point8
data.push (157312493.210576);
// Data Point9
data.push (157312493.210576);
// Data Point10
data.push (157312493.210576);
// Data Point11
data.push (157312493.210576);
// Data Point12
data.push (157312493.210576);
// Data Point13
data.push (159594117.202117);
// Data Point14
data.push (159594117.202117);
// Data Point15
data.push (159594117.202117);
// Data Point16
data.push (159594117.202117);
// Data Point17
data.push (159594117.202117);
// Data Point18
data.push (159594117.202117);
// Data Point19
data.push (159594117.202117);
// Data Point20
data.push (159594117.202117);
// Data Point21
data.push (159594117.202117);
// Data Point22
data.push (159594117.202117);
// Data Point23
data.push (159594117.202117);
// Data Point24
data.push (159594117.202117);
// Data Point25
data.push (161939684.202548);
// Data Point26
data.push (161939684.202548);
// Data Point27
data.push (161939684.202548);
// Data Point28
data.push (161939684.202548);
// Data Point29
data.push (161939684.202548);
// Data Point30
data.push (161939684.202548);
// Data Point31
data.push (161939684.202548);
// Data Point32
data.push (161939684.202548);
// Data Point33
data.push (161939684.202548);
// Data Point34
data.push (161939684.202548);
// Data Point35
data.push (161939684.202548);
// Data Point36
data.push (161939684.202548);
// Data Point37
data.push (164329617.850938);
// Data Point38
data.push (164329617.850938);
// Data Point39
data.push (164329617.850938);
// Data Point40
data.push (164329617.850938);
// Data Point41
data.push (164329617.850938);
// Data Point42
data.push (164329617.850938);
// Data Point43
data.push (164329617.850938);
// Data Point44
data.push (164329617.850938);
// Data Point45
data.push (164329617.850938);
// Data Point46
data.push (164329617.850938);
// Data Point47
data.push (164329617.850938);
// Data Point48
data.push (164329617.850938);
// Data Point49
data.push (166764524.555732);
// Data Point50
data.push (166764524.555732);
// Data Point51
data.push (166764524.555732);
// Data Point52
data.push (166764524.555732);
// Data Point53
data.push (166764524.555732);
// Data Point54
data.push (166764524.555732);
// Data Point55
data.push (166764524.555732);
// Data Point56
data.push (166764524.555732);
// Data Point57
data.push (166764524.555732);
// Data Point58
data.push (166764524.555732);
// Data Point59
data.push (166764524.555732);
// Data Point60
data.push (166764524.555732);


       }

       return data;

     },
  
     getTargetBedsOcuppied(index)
     {
       let data = new Array ();
  
       if (index == 1)
        {
         // Data Point1
data.push (2254.24661418849);
// Data Point2
data.push (2254.24661418849);
// Data Point3
data.push (2254.24661418849);
// Data Point4
data.push (2254.24661418849);
// Data Point5
data.push (2254.24661418849);
// Data Point6
data.push (2254.24661418849);
// Data Point7
data.push (2254.24661418849);
// Data Point8
data.push (2254.24661418849);
// Data Point9
data.push (2254.24661418849);
// Data Point10
data.push (2254.24661418849);
// Data Point11
data.push (2254.24661418849);
// Data Point12
data.push (2254.24661418849);
// Data Point13
data.push (2290.06896969308);
// Data Point14
data.push (2290.06896969308);
// Data Point15
data.push (2290.06896969308);
// Data Point16
data.push (2290.06896969308);
// Data Point17
data.push (2290.06896969308);
// Data Point18
data.push (2290.06896969308);
// Data Point19
data.push (2290.06896969308);
// Data Point20
data.push (2290.06896969308);
// Data Point21
data.push (2290.06896969308);
// Data Point22
data.push (2290.06896969308);
// Data Point23
data.push (2290.06896969308);
// Data Point24
data.push (2290.06896969308);
// Data Point25
data.push (2326.88507733245);
// Data Point26
data.push (2326.88507733245);
// Data Point27
data.push (2326.88507733245);
// Data Point28
data.push (2326.88507733245);
// Data Point29
data.push (2326.88507733245);
// Data Point30
data.push (2326.88507733245);
// Data Point31
data.push (2326.88507733245);
// Data Point32
data.push (2326.88507733245);
// Data Point33
data.push (2326.88507733245);
// Data Point34
data.push (2326.88507733245);
// Data Point35
data.push (2326.88507733245);
// Data Point36
data.push (2326.88507733245);
// Data Point37
data.push (2364.45471947282);
// Data Point38
data.push (2364.45471947282);
// Data Point39
data.push (2364.45471947282);
// Data Point40
data.push (2364.45471947282);
// Data Point41
data.push (2364.45471947282);
// Data Point42
data.push (2364.45471947282);
// Data Point43
data.push (2364.45471947282);
// Data Point44
data.push (2364.45471947282);
// Data Point45
data.push (2364.45471947282);
// Data Point46
data.push (2364.45471947282);
// Data Point47
data.push (2364.45471947282);
// Data Point48
data.push (2364.45471947282);
// Data Point49
data.push (2402.79062227654);
// Data Point50
data.push (2402.79062227654);
// Data Point51
data.push (2402.79062227654);
// Data Point52
data.push (2402.79062227654);
// Data Point53
data.push (2402.79062227654);
// Data Point54
data.push (2402.79062227654);
// Data Point55
data.push (2402.79062227654);
// Data Point56
data.push (2402.79062227654);
// Data Point57
data.push (2402.79062227654);
// Data Point58
data.push (2402.79062227654);
// Data Point59
data.push (2402.79062227654);
// Data Point60
data.push (2402.79062227654);

        }

  
  return data;
     },
     getTargetAssetsUtilised (index)
     {
      let data = new Array ();

      if (index == 1)
      {
       // Data Point1
data.push (4671.87025453636);
// Data Point2
data.push (4671.87025453636);
// Data Point3
data.push (4671.87025453636);
// Data Point4
data.push (4671.87025453636);
// Data Point5
data.push (4671.87025453636);
// Data Point6
data.push (4671.87025453636);
// Data Point7
data.push (4671.87025453636);
// Data Point8
data.push (4671.87025453636);
// Data Point9
data.push (4671.87025453636);
// Data Point10
data.push (4671.87025453636);
// Data Point11
data.push (4671.87025453636);
// Data Point12
data.push (4671.87025453636);
// Data Point13
data.push (4738.31452059238);
// Data Point14
data.push (4738.31452059238);
// Data Point15
data.push (4738.31452059238);
// Data Point16
data.push (4738.31452059238);
// Data Point17
data.push (4738.31452059238);
// Data Point18
data.push (4738.31452059238);
// Data Point19
data.push (4738.31452059238);
// Data Point20
data.push (4738.31452059238);
// Data Point21
data.push (4738.31452059238);
// Data Point22
data.push (4738.31452059238);
// Data Point23
data.push (4738.31452059238);
// Data Point24
data.push (4738.31452059238);
// Data Point25
data.push (4806.64657819116);
// Data Point26
data.push (4806.64657819116);
// Data Point27
data.push (4806.64657819116);
// Data Point28
data.push (4806.64657819116);
// Data Point29
data.push (4806.64657819116);
// Data Point30
data.push (4806.64657819116);
// Data Point31
data.push (4806.64657819116);
// Data Point32
data.push (4806.64657819116);
// Data Point33
data.push (4806.64657819116);
// Data Point34
data.push (4806.64657819116);
// Data Point35
data.push (4806.64657819116);
// Data Point36
data.push (4806.64657819116);
// Data Point37
data.push (4876.24589517514);
// Data Point38
data.push (4876.24589517514);
// Data Point39
data.push (4876.24589517514);
// Data Point40
data.push (4876.24589517514);
// Data Point41
data.push (4876.24589517514);
// Data Point42
data.push (4876.24589517514);
// Data Point43
data.push (4876.24589517514);
// Data Point44
data.push (4876.24589517514);
// Data Point45
data.push (4876.24589517514);
// Data Point46
data.push (4876.24589517514);
// Data Point47
data.push (4876.24589517514);
// Data Point48
data.push (4876.24589517514);
// Data Point49
data.push (4947.12849425946);
// Data Point50
data.push (4947.12849425946);
// Data Point51
data.push (4947.12849425946);
// Data Point52
data.push (4947.12849425946);
// Data Point53
data.push (4947.12849425946);
// Data Point54
data.push (4947.12849425946);
// Data Point55
data.push (4947.12849425946);
// Data Point56
data.push (4947.12849425946);
// Data Point57
data.push (4947.12849425946);
// Data Point58
data.push (4947.12849425946);
// Data Point59
data.push (4947.12849425946);
// Data Point60
data.push (4947.12849425946);


      }

      return data;
     },
     getActualAssetsUtilised (index)
     {
        let data = new Array ();
  
        if (index == 1)
        {
          data.push (4134);  
        }
  
        return data;
     },
     getAssetsVariance (index)
     {
        let data = new Array ();

        if (index == 1)
        {
  
       // Data Point1
data.push (4134);
// Data Point2
data.push (4140.201);
// Data Point3
data.push (4146.4113015);
// Data Point4
data.push (4152.63091845225);
// Data Point5
data.push (4158.85986482993);
// Data Point6
data.push (4165.09815462717);
// Data Point7
data.push (4171.34580185912);
// Data Point8
data.push (4177.6028205619);
// Data Point9
data.push (4183.86922479275);
// Data Point10
data.push (4190.14502862994);
// Data Point11
data.push (4196.43024617288);
// Data Point12
data.push (4202.72489154214);
// Data Point13
data.push (4209.02897887946);
// Data Point14
data.push (4215.34252234777);
// Data Point15
data.push (4221.6655361313);
// Data Point16
data.push (4227.99803443549);
// Data Point17
data.push (4234.34003148715);
// Data Point18
data.push (4240.69154153438);
// Data Point19
data.push (4247.05257884668);
// Data Point20
data.push (4253.42315771495);
// Data Point21
data.push (4259.80329245152);
// Data Point22
data.push (4266.1929973902);
// Data Point23
data.push (4272.59228688629);
// Data Point24
data.push (4279.00117531662);
// Data Point25
data.push (4285.41967707959);
// Data Point26
data.push (4291.84780659521);
// Data Point27
data.push (4298.2855783051);
// Data Point28
data.push (4304.73300667256);
// Data Point29
data.push (4311.19010618257);
// Data Point30
data.push (4317.65689134185);
// Data Point31
data.push (4324.13337667886);
// Data Point32
data.push (4330.61957674388);
// Data Point33
data.push (4337.11550610899);
// Data Point34
data.push (4343.62117936816);
// Data Point35
data.push (4350.13661113721);
// Data Point36
data.push (4356.66181605392);
// Data Point37
data.push (4363.196808778);
// Data Point38
data.push (4369.74160399116);
// Data Point39
data.push (4376.29621639715);
// Data Point40
data.push (4382.86066072175);
// Data Point41
data.push (4389.43495171283);
// Data Point42
data.push (4396.0191041404);
// Data Point43
data.push (4402.61313279661);
// Data Point44
data.push (4409.21705249581);
// Data Point45
data.push (4415.83087807455);
// Data Point46
data.push (4422.45462439166);
// Data Point47
data.push (4429.08830632825);
// Data Point48
data.push (4435.73193878774);
// Data Point49
data.push (4442.38553669592);
// Data Point50
data.push (4449.04911500097);
// Data Point51
data.push (4455.72268867347);
// Data Point52
data.push (4462.40627270648);
// Data Point53
data.push (4469.09988211554);
// Data Point54
data.push (4475.80353193871);
// Data Point55
data.push (4482.51723723662);
// Data Point56
data.push (4489.24101309248);
// Data Point57
data.push (4495.97487461211);
// Data Point58
data.push (4502.71883692403);
// Data Point59
data.push (4509.47291517942);
// Data Point60
data.push (4516.23712455219);
        }
        return data;
     },
     getTotalWorkforceFTEEmployed (index)
     {
       let data = new Array ();

       if (index == 1)
       {

      // Data Point1
data.push (12920.7420862607);
// Data Point2
data.push (12920.7420862607);
// Data Point3
data.push (12920.7420862607);
// Data Point4
data.push (12920.7420862607);
// Data Point5
data.push (12920.7420862607);
// Data Point6
data.push (12920.7420862607);
// Data Point7
data.push (12920.7420862607);
// Data Point8
data.push (12920.7420862607);
// Data Point9
data.push (12920.7420862607);
// Data Point10
data.push (12920.7420862607);
// Data Point11
data.push (12920.7420862607);
// Data Point12
data.push (12920.7420862607);
// Data Point13
data.push (13099.1740331516);
// Data Point14
data.push (13099.1740331516);
// Data Point15
data.push (13099.1740331516);
// Data Point16
data.push (13099.1740331516);
// Data Point17
data.push (13099.1740331516);
// Data Point18
data.push (13099.1740331516);
// Data Point19
data.push (13099.1740331516);
// Data Point20
data.push (13099.1740331516);
// Data Point21
data.push (13099.1740331516);
// Data Point22
data.push (13099.1740331516);
// Data Point23
data.push (13099.1740331516);
// Data Point24
data.push (13099.1740331516);
// Data Point25
data.push (13282.7212828395);
// Data Point26
data.push (13282.7212828395);
// Data Point27
data.push (13282.7212828395);
// Data Point28
data.push (13282.7212828395);
// Data Point29
data.push (13282.7212828395);
// Data Point30
data.push (13282.7212828395);
// Data Point31
data.push (13282.7212828395);
// Data Point32
data.push (13282.7212828395);
// Data Point33
data.push (13282.7212828395);
// Data Point34
data.push (13282.7212828395);
// Data Point35
data.push (13282.7212828395);
// Data Point36
data.push (13282.7212828395);
// Data Point37
data.push (13469.7024009817);
// Data Point38
data.push (13469.7024009817);
// Data Point39
data.push (13469.7024009817);
// Data Point40
data.push (13469.7024009817);
// Data Point41
data.push (13469.7024009817);
// Data Point42
data.push (13469.7024009817);
// Data Point43
data.push (13469.7024009817);
// Data Point44
data.push (13469.7024009817);
// Data Point45
data.push (13469.7024009817);
// Data Point46
data.push (13469.7024009817);
// Data Point47
data.push (13469.7024009817);
// Data Point48
data.push (13469.7024009817);
// Data Point49
data.push (13660.1609585037);
// Data Point50
data.push (13660.1609585037);
// Data Point51
data.push (13660.1609585037);
// Data Point52
data.push (13660.1609585037);
// Data Point53
data.push (13660.1609585037);
// Data Point54
data.push (13660.1609585037);
// Data Point55
data.push (13660.1609585037);
// Data Point56
data.push (13660.1609585037);
// Data Point57
data.push (13660.1609585037);
// Data Point58
data.push (13660.1609585037);
// Data Point59
data.push (13660.1609585037);
// Data Point60
data.push (13660.1609585037);



       }
  
       return data;
     },
     getWorkforceFTEVariance (index)
     {
       let data = new Array ();


       if (index == 1)
       {
           // Data Point1
data.push (15486);
// Data Point2
data.push (15486);
// Data Point3
data.push (15486);
// Data Point4
data.push (15486);
// Data Point5
data.push (15331.14);
// Data Point6
data.push (15177.8286);
// Data Point7
data.push (15177.8286);
// Data Point8
data.push (15177.8286);
// Data Point9
data.push (15177.8286);
// Data Point10
data.push (15177.8286);
// Data Point11
data.push (15177.8286);
// Data Point12
data.push (15177.8286);
// Data Point13
data.push (15177.8286);
// Data Point14
data.push (15026.050314);
// Data Point15
data.push (15026.050314);
// Data Point16
data.push (15026.050314);
// Data Point17
data.push (15026.050314);
// Data Point18
data.push (15026.050314);
// Data Point19
data.push (15026.050314);
// Data Point20
data.push (14950.92006243);
// Data Point21
data.push (14876.1654621178);
// Data Point22
data.push (14801.7846348073);
// Data Point23
data.push (14727.7757116332);
// Data Point24
data.push (14654.1368330751);
// Data Point25
data.push (14580.8661489097);
// Data Point26
data.push (14507.9618181651);
// Data Point27
data.push (14435.4220090743);
// Data Point28
data.push (14363.2448990289);
// Data Point29
data.push (14291.4286745338);
// Data Point30
data.push (14219.9715311611);
// Data Point31
data.push (14148.8716735053);
// Data Point32
data.push (14078.1273151378);
// Data Point33
data.push (14007.7366785621);
// Data Point34
data.push (13937.6979951693);
// Data Point35
data.push (13868.0095051934);
// Data Point36
data.push (13798.6694576675);
// Data Point37
data.push (13729.6761103791);
// Data Point38
data.push (13661.0277298272);
// Data Point39
data.push (13592.7225911781);
// Data Point40
data.push (13524.7589782222);
// Data Point41
data.push (13457.1351833311);
// Data Point42
data.push (13469.7024009817);
// Data Point43
data.push (13469.7024009817);
// Data Point44
data.push (13469.7024009817);
// Data Point45
data.push (13469.7024009817);
// Data Point46
data.push (13469.7024009817);
// Data Point47
data.push (13469.7024009817);
// Data Point48
data.push (13469.7024009817);
// Data Point49
data.push (13660.1609585037);
// Data Point50
data.push (13660.1609585037);
// Data Point51
data.push (13660.1609585037);
// Data Point52
data.push (13660.1609585037);
// Data Point53
data.push (13660.1609585037);
// Data Point54
data.push (13660.1609585037);
// Data Point55
data.push (13660.1609585037);
// Data Point56
data.push (13660.1609585037);
// Data Point57
data.push (13660.1609585037);
// Data Point58
data.push (13660.1609585037);
// Data Point59
data.push (13660.1609585037);
// Data Point60
data.push (13660.1609585037);


       }
  
       return data;
     },
     getActualWorkforceFTEEmployed (index)
     {
        let data = new Array ();

        if (index == 1)
        {
            data.push (15486); 
        }
  
  
        return data;
     },
     getActualMedicalFTEEmployed (index)
     {
        let data = new Array ();

        if ( index == 1)
        {
            data.push (2894);  
        }  
  
        return data;
     },
     getMedicalFTEVariance(index)
     {
       let data = new Array ();
  
      if (index == 1)
      {
     // Data Point1
data.push (2894.3);
// Data Point2
data.push (2894.3);
// Data Point3
data.push (2894.3);
// Data Point4
data.push (2894.3);
// Data Point5
data.push (2894.3);
// Data Point6
data.push (2894.3);
// Data Point7
data.push (2894.3);
// Data Point8
data.push (2894.3);
// Data Point9
data.push (2894.3);
// Data Point10
data.push (2894.3);
// Data Point11
data.push (2894.3);
// Data Point12
data.push (2894.3);
// Data Point13
data.push (2879.8285);
// Data Point14
data.push (2879.8285);
// Data Point15
data.push (2879.8285);
// Data Point16
data.push (2879.8285);
// Data Point17
data.push (2879.8285);
// Data Point18
data.push (2879.8285);
// Data Point19
data.push (2879.8285);
// Data Point20
data.push (2862.549529);
// Data Point21
data.push (2862.549529);
// Data Point22
data.push (2862.549529);
// Data Point23
data.push (2862.549529);
// Data Point24
data.push (2862.549529);
// Data Point25
data.push (2862.549529);
// Data Point26
data.push (2862.549529);
// Data Point27
data.push (2862.549529);
// Data Point28
data.push (2862.549529);
// Data Point29
data.push (2836.786583239);
// Data Point30
data.push (2836.786583239);
// Data Point31
data.push (2811.25550398985);
// Data Point32
data.push (2811.25550398985);
// Data Point33
data.push (2785.95420445394);
// Data Point34
data.push (2785.95420445394);
// Data Point35
data.push (2785.95420445394);
// Data Point36
data.push (2785.95420445394);
// Data Point37
data.push (2785.95420445394);
// Data Point38
data.push (2785.95420445394);
// Data Point39
data.push (2785.95420445394);
// Data Point40
data.push (2785.95420445394);
// Data Point41
data.push (2785.95420445394);
// Data Point42
data.push (2785.95420445394);
// Data Point43
data.push (2785.95420445394);
// Data Point44
data.push (2785.95420445394);
// Data Point45
data.push (2785.95420445394);
// Data Point46
data.push (2785.95420445394);
// Data Point47
data.push (2772.02443343167);
// Data Point48
data.push (2772.02443343167);
// Data Point49
data.push (2758.16431126451);
// Data Point50
data.push (2758.16431126451);
// Data Point51
data.push (2744.37348970819);
// Data Point52
data.push (2744.37348970819);
// Data Point53
data.push (2730.65162225965);
// Data Point54
data.push (2730.65162225965);
// Data Point55
data.push (2716.99836414835);
// Data Point56
data.push (2716.99836414835);
// Data Point57
data.push (2703.41337232761);
// Data Point58
data.push (2703.41337232761);
// Data Point59
data.push (2689.89630546597);
// Data Point60
data.push (2689.89630546597);


       }
  
  
       return data;
     },
     getTargetMedcialFTEEmployed(index)
     {
       let data = new Array ();
  
       if (index == 1)
       {
         // Data Point1
data.push (2411.40342071463);
// Data Point2
data.push (2411.40342071463);
// Data Point3
data.push (2411.40342071463);
// Data Point4
data.push (2411.40342071463);
// Data Point5
data.push (2411.40342071463);
// Data Point6
data.push (2411.40342071463);
// Data Point7
data.push (2411.40342071463);
// Data Point8
data.push (2411.40342071463);
// Data Point9
data.push (2411.40342071463);
// Data Point10
data.push (2411.40342071463);
// Data Point11
data.push (2411.40342071463);
// Data Point12
data.push (2411.40342071463);
// Data Point13
data.push (2445.71423222381);
// Data Point14
data.push (2445.71423222381);
// Data Point15
data.push (2445.71423222381);
// Data Point16
data.push (2445.71423222381);
// Data Point17
data.push (2445.71423222381);
// Data Point18
data.push (2445.71423222381);
// Data Point19
data.push (2445.71423222381);
// Data Point20
data.push (2445.71423222381);
// Data Point21
data.push (2445.71423222381);
// Data Point22
data.push (2445.71423222381);
// Data Point23
data.push (2445.71423222381);
// Data Point24
data.push (2445.71423222381);
// Data Point25
data.push (2481.00029261169);
// Data Point26
data.push (2481.00029261169);
// Data Point27
data.push (2481.00029261169);
// Data Point28
data.push (2481.00029261169);
// Data Point29
data.push (2481.00029261169);
// Data Point30
data.push (2481.00029261169);
// Data Point31
data.push (2481.00029261169);
// Data Point32
data.push (2481.00029261169);
// Data Point33
data.push (2481.00029261169);
// Data Point34
data.push (2481.00029261169);
// Data Point35
data.push (2481.00029261169);
// Data Point36
data.push (2481.00029261169);
// Data Point37
data.push (2516.95738060518);
// Data Point38
data.push (2516.95738060518);
// Data Point39
data.push (2516.95738060518);
// Data Point40
data.push (2516.95738060518);
// Data Point41
data.push (2516.95738060518);
// Data Point42
data.push (2516.95738060518);
// Data Point43
data.push (2516.95738060518);
// Data Point44
data.push (2516.95738060518);
// Data Point45
data.push (2516.95738060518);
// Data Point46
data.push (2516.95738060518);
// Data Point47
data.push (2516.95738060518);
// Data Point48
data.push (2516.95738060518);
// Data Point49
data.push (2553.59457415261);
// Data Point50
data.push (2553.59457415261);
// Data Point51
data.push (2553.59457415261);
// Data Point52
data.push (2553.59457415261);
// Data Point53
data.push (2553.59457415261);
// Data Point54
data.push (2553.59457415261);
// Data Point55
data.push (2553.59457415261);
// Data Point56
data.push (2553.59457415261);
// Data Point57
data.push (2553.59457415261);
// Data Point58
data.push (2553.59457415261);
// Data Point59
data.push (2553.59457415261);
// Data Point60
data.push (2553.59457415261);


       }
  
  
       return data;
     },
     getActualNursingFTEEmployed (index)
     {
        let data = new Array ();

        if (index == 1)
        { 
            data.push (4880);
        }
  
  
        return data;
     },
     getTargetNursingFTEEmployed (index)
     {
        let data = new Array ();

        if (index == 1)
        {
          

              // Data Point1
data.push (6627.37528665492);
// Data Point2
data.push (6627.37528665492);
// Data Point3
data.push (6627.37528665492);
// Data Point4
data.push (6627.37528665492);
// Data Point5
data.push (6627.37528665492);
// Data Point6
data.push (6627.37528665492);
// Data Point7
data.push (6627.37528665492);
// Data Point8
data.push (6627.37528665492);
// Data Point9
data.push (6627.37528665492);
// Data Point10
data.push (6627.37528665492);
// Data Point11
data.push (6627.37528665492);
// Data Point12
data.push (6627.37528665492);
// Data Point13
data.push (6725.24465942492);
// Data Point14
data.push (6725.24465942492);
// Data Point15
data.push (6725.24465942492);
// Data Point16
data.push (6725.24465942492);
// Data Point17
data.push (6725.24465942492);
// Data Point18
data.push (6725.24465942492);
// Data Point19
data.push (6725.24465942492);
// Data Point20
data.push (6725.24465942492);
// Data Point21
data.push (6725.24465942492);
// Data Point22
data.push (6725.24465942492);
// Data Point23
data.push (6725.24465942492);
// Data Point24
data.push (6725.24465942492);
// Data Point25
data.push (6825.92461387791);
// Data Point26
data.push (6825.92461387791);
// Data Point27
data.push (6825.92461387791);
// Data Point28
data.push (6825.92461387791);
// Data Point29
data.push (6825.92461387791);
// Data Point30
data.push (6825.92461387791);
// Data Point31
data.push (6825.92461387791);
// Data Point32
data.push (6825.92461387791);
// Data Point33
data.push (6825.92461387791);
// Data Point34
data.push (6825.92461387791);
// Data Point35
data.push (6825.92461387791);
// Data Point36
data.push (6825.92461387791);
// Data Point37
data.push (6928.62099982837);
// Data Point38
data.push (6928.62099982837);
// Data Point39
data.push (6928.62099982837);
// Data Point40
data.push (6928.62099982837);
// Data Point41
data.push (6928.62099982837);
// Data Point42
data.push (6928.62099982837);
// Data Point43
data.push (6928.62099982837);
// Data Point44
data.push (6928.62099982837);
// Data Point45
data.push (6928.62099982837);
// Data Point46
data.push (6928.62099982837);
// Data Point47
data.push (6928.62099982837);
// Data Point48
data.push (6928.62099982837);
// Data Point49
data.push (7033.36444011032);
// Data Point50
data.push (7033.36444011032);
// Data Point51
data.push (7033.36444011032);
// Data Point52
data.push (7033.36444011032);
// Data Point53
data.push (7033.36444011032);
// Data Point54
data.push (7033.36444011032);
// Data Point55
data.push (7033.36444011032);
// Data Point56
data.push (7033.36444011032);
// Data Point57
data.push (7033.36444011032);
// Data Point58
data.push (7033.36444011032);
// Data Point59
data.push (7033.36444011032);
// Data Point60
data.push (7033.36444011032);


        }
  
  
  
        return data;
     },
     getNursingFTEVariance (index)
     {
        let data = new Array ();

        if (index == 1)
        {

 // Data Point1
data.push (4880);
// Data Point2
data.push (4880);
// Data Point3
data.push (4880);
// Data Point4
data.push (4926.36);
// Data Point5
data.push (4941.13908);
// Data Point6
data.push (4955.96249724);
// Data Point7
data.push (4970.83038473172);
// Data Point8
data.push (5018.05327338667);
// Data Point9
data.push (5065.72477948384);
// Data Point10
data.push (5113.84916488894);
// Data Point11
data.push (5162.43073195539);
// Data Point12
data.push (5170.17437805332);
// Data Point13
data.push (5180.51472680943);
// Data Point14
data.push (5201.23678571666);
// Data Point15
data.push (5222.04173285953);
// Data Point16
data.push (5242.92989979097);
// Data Point17
data.push (5263.90161939013);
// Data Point18
data.push (5284.95722586769);
// Data Point19
data.push (5306.09705477116);
// Data Point20
data.push (5327.32144299025);
// Data Point21
data.push (5348.63072876221);
// Data Point22
data.push (5370.02525167726);
// Data Point23
data.push (5391.50535268397);
// Data Point24
data.push (5413.0713740947);
// Data Point25
data.push (5434.72365959108);
// Data Point26
data.push (5456.46255422945);
// Data Point27
data.push (5478.28840444636);
// Data Point28
data.push (5500.20155806415);
// Data Point29
data.push (5522.20236429641);
// Data Point30
data.push (5544.29117375359);
// Data Point31
data.push (5566.46833844861);
// Data Point32
data.push (5588.7342118024);
// Data Point33
data.push (5611.08914864961);
// Data Point34
data.push (5633.53350524421);
// Data Point35
data.push (5656.06763926519);
// Data Point36
data.push (5678.69190982225);
// Data Point37
data.push (5701.40667746154);
// Data Point38
data.push (5724.21230417138);
// Data Point39
data.push (5747.10915338807);
// Data Point40
data.push (5770.09759000162);
// Data Point41
data.push (5793.17798036163);
// Data Point42
data.push (5816.35069228307);
// Data Point43
data.push (5839.61609505221);
// Data Point44
data.push (5862.97455943241);
// Data Point45
data.push (5886.42645767014);
// Data Point46
data.push (5909.97216350082);
// Data Point47
data.push (5933.61205215483);
// Data Point48
data.push (5957.34650036345);
// Data Point49
data.push (5981.1758863649);
// Data Point50
data.push (6005.10058991036);
// Data Point51
data.push (6029.12099227);
// Data Point52
data.push (6053.23747623908);
// Data Point53
data.push (6077.45042614404);
// Data Point54
data.push (6101.76022784861);
// Data Point55
data.push (6126.16726876001);
// Data Point56
data.push (6150.67193783505);
// Data Point57
data.push (6175.27462558639);
// Data Point58
data.push (6199.97572408874);
// Data Point59
data.push (6224.77562698509);
// Data Point60
data.push (6249.67472949303);


        }
  
  
  
        return data;
     },
     getTargetAdministratorFTEEmployed (index)
     {
        let data = new Array ();

        if (index == 1)
        { 
          // Data Point1
data.push (1216.90438801991);
// Data Point2
data.push (1216.90438801991);
// Data Point3
data.push (1216.90438801991);
// Data Point4
data.push (1216.90438801991);
// Data Point5
data.push (1216.90438801991);
// Data Point6
data.push (1216.90438801991);
// Data Point7
data.push (1216.90438801991);
// Data Point8
data.push (1216.90438801991);
// Data Point9
data.push (1216.90438801991);
// Data Point10
data.push (1216.90438801991);
// Data Point11
data.push (1216.90438801991);
// Data Point12
data.push (1216.90438801991);
// Data Point13
data.push (1231.70656705003);
// Data Point14
data.push (1231.70656705003);
// Data Point15
data.push (1231.70656705003);
// Data Point16
data.push (1231.70656705003);
// Data Point17
data.push (1231.70656705003);
// Data Point18
data.push (1231.70656705003);
// Data Point19
data.push (1231.70656705003);
// Data Point20
data.push (1231.70656705003);
// Data Point21
data.push (1231.70656705003);
// Data Point22
data.push (1231.70656705003);
// Data Point23
data.push (1231.70656705003);
// Data Point24
data.push (1231.70656705003);
// Data Point25
data.push (1246.94985669318);
// Data Point26
data.push (1246.94985669318);
// Data Point27
data.push (1246.94985669318);
// Data Point28
data.push (1246.94985669318);
// Data Point29
data.push (1246.94985669318);
// Data Point30
data.push (1246.94985669318);
// Data Point31
data.push (1246.94985669318);
// Data Point32
data.push (1246.94985669318);
// Data Point33
data.push (1246.94985669318);
// Data Point34
data.push (1246.94985669318);
// Data Point35
data.push (1246.94985669318);
// Data Point36
data.push (1246.94985669318);
// Data Point37
data.push (1262.43653367457);
// Data Point38
data.push (1262.43653367457);
// Data Point39
data.push (1262.43653367457);
// Data Point40
data.push (1262.43653367457);
// Data Point41
data.push (1262.43653367457);
// Data Point42
data.push (1262.43653367457);
// Data Point43
data.push (1262.43653367457);
// Data Point44
data.push (1262.43653367457);
// Data Point45
data.push (1262.43653367457);
// Data Point46
data.push (1262.43653367457);
// Data Point47
data.push (1262.43653367457);
// Data Point48
data.push (1262.43653367457);
// Data Point49
data.push (1278.16774738089);
// Data Point50
data.push (1278.16774738089);
// Data Point51
data.push (1278.16774738089);
// Data Point52
data.push (1278.16774738089);
// Data Point53
data.push (1278.16774738089);
// Data Point54
data.push (1278.16774738089);
// Data Point55
data.push (1278.16774738089);
// Data Point56
data.push (1278.16774738089);
// Data Point57
data.push (1278.16774738089);
// Data Point58
data.push (1278.16774738089);
// Data Point59
data.push (1278.16774738089);
// Data Point60
data.push (1278.16774738089);


        }
  
  
        return data;
     },
     getActualAdministratorFTEEmployed (index)
     {
        let data = new Array ();

        if (index == 1)
        {
           data.push (4927);     
             
        }
    
        return data;
     }, 
     getAdministratorFTEVariance (index)
     {
        let data = new Array ();

        if (index == 1)
        {
          // Data Point1
data.push (4927);
// Data Point2
data.push (4927);
// Data Point3
data.push (4927);
// Data Point4
data.push (4927);
// Data Point5
data.push (4927);
// Data Point6
data.push (4927);
// Data Point7
data.push (4927);
// Data Point8
data.push (4927);
// Data Point9
data.push (4902.365);
// Data Point10
data.push (4902.365);
// Data Point11
data.push (4877.853175);
// Data Point12
data.push (4877.853175);
// Data Point13
data.push (4853.463909125);
// Data Point14
data.push (4853.463909125);
// Data Point15
data.push (4829.19658957937);
// Data Point16
data.push (4829.19658957937);
// Data Point17
data.push (4805.05060663148);
// Data Point18
data.push (4805.05060663148);
// Data Point19
data.push (4781.02535359832);
// Data Point20
data.push (4781.02535359832);
// Data Point21
data.push (4757.12022683033);
// Data Point22
data.push (4757.12022683033);
// Data Point23
data.push (4757.12022683033);
// Data Point24
data.push (4757.12022683033);
// Data Point25
data.push (4757.12022683033);
// Data Point26
data.push (4757.12022683033);
// Data Point27
data.push (4757.12022683033);
// Data Point28
data.push (4757.12022683033);
// Data Point29
data.push (4757.12022683033);
// Data Point30
data.push (4720.49040108374);
// Data Point31
data.push (4720.49040108374);
// Data Point32
data.push (4684.14262499539);
// Data Point33
data.push (4684.14262499539);
// Data Point34
data.push (4648.07472678293);
// Data Point35
data.push (4648.07472678293);
// Data Point36
data.push (4612.2845513867);
// Data Point37
data.push (4612.2845513867);
// Data Point38
data.push (4576.76996034102);
// Data Point39
data.push (4576.76996034102);
// Data Point40
data.push (4541.52883164639);
// Data Point41
data.push (4541.52883164639);
// Data Point42
data.push (4506.55905964272);
// Data Point43
data.push (4506.55905964272);
// Data Point44
data.push (4471.85855488347);
// Data Point45
data.push (4471.85855488347);
// Data Point46
data.push (4437.42524401086);
// Data Point47
data.push (4437.42524401086);
// Data Point48
data.push (4403.25706963198);
// Data Point49
data.push (4403.25706963198);
// Data Point50
data.push (4369.35199019581);
// Data Point51
data.push (4369.35199019581);
// Data Point52
data.push (4335.7079798713);
// Data Point53
data.push (4335.7079798713);
// Data Point54
data.push (4302.3230284263);
// Data Point55
data.push (4302.3230284263);
// Data Point56
data.push (4269.19514110741);
// Data Point57
data.push (4269.19514110741);
// Data Point58
data.push (4236.32233852088);
// Data Point59
data.push (4236.32233852088);
// Data Point60
data.push (4203.70265651427);


        }
  
  
        return data;
     },
     getPatientEpisodeProductivityUtilisationTarget(index)  // 1 60 Month //2 12 Month // 3 1 Month
     {
      let data = new Array ();

      if (index == 1)
      {
        // Data Point1
data.push (715035.692337709);
// Data Point2
data.push (715035.692337709);
// Data Point3
data.push (715035.692337709);
// Data Point4
data.push (715035.692337709);
// Data Point5
data.push (715035.692337709);
// Data Point6
data.push (715035.692337709);
// Data Point7
data.push (715035.692337709);
// Data Point8
data.push (715035.692337709);
// Data Point9
data.push (715035.692337709);
// Data Point10
data.push (715035.692337709);
// Data Point11
data.push (715035.692337709);
// Data Point12
data.push (715035.692337709);
// Data Point13
data.push (724726.413621681);
// Data Point14
data.push (724726.413621681);
// Data Point15
data.push (724726.413621681);
// Data Point16
data.push (724726.413621681);
// Data Point17
data.push (724726.413621681);
// Data Point18
data.push (724726.413621681);
// Data Point19
data.push (724726.413621681);
// Data Point20
data.push (724726.413621681);
// Data Point21
data.push (724726.413621681);
// Data Point22
data.push (724726.413621681);
// Data Point23
data.push (724726.413621681);
// Data Point24
data.push (724726.413621681);
// Data Point25
data.push (734691.957622223);
// Data Point26
data.push (734691.957622223);
// Data Point27
data.push (734691.957622223);
// Data Point28
data.push (734691.957622223);
// Data Point29
data.push (734691.957622223);
// Data Point30
data.push (734691.957622223);
// Data Point31
data.push (734691.957622223);
// Data Point32
data.push (734691.957622223);
// Data Point33
data.push (734691.957622223);
// Data Point34
data.push (734691.957622223);
// Data Point35
data.push (734691.957622223);
// Data Point36
data.push (734691.957622223);
// Data Point37
data.push (744831.445613209);
// Data Point38
data.push (744831.445613209);
// Data Point39
data.push (744831.445613209);
// Data Point40
data.push (744831.445613209);
// Data Point41
data.push (744831.445613209);
// Data Point42
data.push (744831.445613209);
// Data Point43
data.push (744831.445613209);
// Data Point44
data.push (744831.445613209);
// Data Point45
data.push (744831.445613209);
// Data Point46
data.push (744831.445613209);
// Data Point47
data.push (744831.445613209);
// Data Point48
data.push (744831.445613209);
// Data Point49
data.push (755146.652899829);
// Data Point50
data.push (755146.652899829);
// Data Point51
data.push (755146.652899829);
// Data Point52
data.push (755146.652899829);
// Data Point53
data.push (755146.652899829);
// Data Point54
data.push (755146.652899829);
// Data Point55
data.push (755146.652899829);
// Data Point56
data.push (755146.652899829);
// Data Point57
data.push (755146.652899829);
// Data Point58
data.push (755146.652899829);
// Data Point59
data.push (755146.652899829);
// Data Point60
data.push (755146.652899829);    
      }

      if (index == 2)
      {
          for (var i = 0; i< 52; i++)
          {
            data.push(i);
          }

      }

      if (index == 3)
      {
        for (i= 200; i< 254; i++)
        {
            data.push (i);

        }

      }
      
      return data;
     },
     getPatientEpisodeProductivityUtilisationActual (index)
     {
      let data = new Array ();

      

      if (index == 1)      
      {  
        data.push ("625700");  
      }

      /*var value = 449677;

      if (index == 2)
      {
        for (var i = 0; i< 52; i++)
        {
          if ( i < 40)
          {
            data.push (value)
          }
          else
          {
            value = value + i;
            data.push (Math.floor(Math.random() * 35) + value);
          }
        
        }
      }*/
  
  
      return data;
     },
     getPatientEpisodeProductivityUtilisationVariance(index)
     {
        let data = new Array ();



        if (index == 1)
        {
            // Data Point1
data.push (625700.166666667);
// Data Point2
data.push (625700.166666667);
// Data Point3
data.push (625700.166666667);
// Data Point4
data.push (626951.567);
// Data Point5
data.push (628205.470134);
// Data Point6
data.push (629461.881074268);
// Data Point7
data.push (630720.804836416);
// Data Point8
data.push (631982.246446089);
// Data Point9
data.push (633246.210938981);
// Data Point10
data.push (634512.703360859);
// Data Point11
data.push (635781.728767581);
// Data Point12
data.push (637053.292225116);
// Data Point13
data.push (638327.398809566);
// Data Point14
data.push (639604.053607185);
// Data Point15
data.push (640883.2617144);
// Data Point16
data.push (642165.028237829);
// Data Point17
data.push (643770.440808423);
// Data Point18
data.push (645379.866910444);
// Data Point19
data.push (646993.31657772);
// Data Point20
data.push (648610.799869165);
// Data Point21
data.push (651529.548468576);
// Data Point22
data.push (654461.431436684);
// Data Point23
data.push (657406.50787815);
// Data Point24
data.push (660364.837163601);
// Data Point25
data.push (664657.208605165);
// Data Point26
data.push (668977.480461098);
// Data Point27
data.push (673325.834084095);
// Data Point28
data.push (677702.452005642);
// Data Point29
data.push (682107.517943678);
// Data Point30
data.push (686541.216810312);
// Data Point31
data.push (691003.734719579);
// Data Point32
data.push (695495.258995257);
// Data Point33
data.push (700015.978178726);
// Data Point34
data.push (704566.082036888);
// Data Point35
data.push (709145.761570127);
// Data Point36
data.push (713755.209020333);
// Data Point37
data.push (718394.617878965);
// Data Point38
data.push (723064.182895178);
// Data Point39
data.push (727764.100083997);
// Data Point40
data.push (728855.746234123);
// Data Point41
data.push (729949.029853474);
// Data Point42
data.push (731043.953398254);
// Data Point43
data.push (732140.519328352);
// Data Point44
data.push (733238.730107344);
// Data Point45
data.push (734338.588202505);
// Data Point46
data.push (735440.096084809);
// Data Point47
data.push (736543.256228936);
// Data Point48
data.push (737648.07111328);
// Data Point49
data.push (738754.54321995);
// Data Point50
data.push (739862.67503478);
// Data Point51
data.push (740972.469047332);
// Data Point52
data.push (742083.927750903);
// Data Point53
data.push (743197.053642529);
// Data Point54
data.push (755146.652899829);
// Data Point55
data.push (755146.652899829);
// Data Point56
data.push (755146.652899829);
// Data Point57
data.push (755146.652899829);
// Data Point58
data.push (755146.652899829);
// Data Point59
data.push (755146.652899829);
// Data Point60
data.push (755146.652899829);




        }

       
      return data;
     },
    
     getSixtyMonthLabels()
      {
        let data = new Array ();
  
        for (var i = 1; i<=60; i++)
        {
          data.push (i.toString());
        }
  
        console.log (data);
  
        return data;
  
      },    
      getFiftyTwoLabels()
      {
        let data = new Array ();
  
        for (var i = 1; i<=52; i++)
        {
          data.push (i.toString());
        }
  
        console.log (data);
  
        return data;
  
      },    
      getVarianceTotalIncome1 ()
      {
          let data = new Array ();
  
          for (var i=0; i <60; i++)
          {
            data.push (0);
          }
  
          return data;
      },    
     getVarianceTotalIncome (index)
     {
         let data = new Array ();

         if (index == 1)
         { 
             data.push (145);
         }
  
         return data;
     },
     getActualTotalIncome (index)
     {
        let data = new Array ();

        if (index == 1)
        {  
            data.push (12); 
        }
  
        return data;
     },
     getTargetCostBudgget (index)
     {
        let data = new Array ();

        if (index == 1)
        {
         // Data Point1
data.push (106065770.959328);
// Data Point2
data.push (106065770.959328);
// Data Point3
data.push (106065770.959328);
// Data Point4
data.push (106065770.959328);
// Data Point5
data.push (106065770.959328);
// Data Point6
data.push (106065770.959328);
// Data Point7
data.push (106065770.959328);
// Data Point8
data.push (106065770.959328);
// Data Point9
data.push (106065770.959328);
// Data Point10
data.push (106065770.959328);
// Data Point11
data.push (106065770.959328);
// Data Point12
data.push (106065770.959328);
// Data Point13
data.push (107527811.71942);
// Data Point14
data.push (107527811.71942);
// Data Point15
data.push (107527811.71942);
// Data Point16
data.push (107527811.71942);
// Data Point17
data.push (107527811.71942);
// Data Point18
data.push (107527811.71942);
// Data Point19
data.push (107527811.71942);
// Data Point20
data.push (107527811.71942);
// Data Point21
data.push (107527811.71942);
// Data Point22
data.push (107527811.71942);
// Data Point23
data.push (107527811.71942);
// Data Point24
data.push (107527811.71942);
// Data Point25
data.push (109053951.630627);
// Data Point26
data.push (109053951.630627);
// Data Point27
data.push (109053951.630627);
// Data Point28
data.push (109053951.630627);
// Data Point29
data.push (109053951.630627);
// Data Point30
data.push (109053951.630627);
// Data Point31
data.push (109053951.630627);
// Data Point32
data.push (109053951.630627);
// Data Point33
data.push (109053951.630627);
// Data Point34
data.push (109053951.630627);
// Data Point35
data.push (109053951.630627);
// Data Point36
data.push (109053951.630627);
// Data Point37
data.push (110584847.139072);
// Data Point38
data.push (110584847.139072);
// Data Point39
data.push (110584847.139072);
// Data Point40
data.push (110584847.139072);
// Data Point41
data.push (110584847.139072);
// Data Point42
data.push (110584847.139072);
// Data Point43
data.push (110584847.139072);
// Data Point44
data.push (110584847.139072);
// Data Point45
data.push (110584847.139072);
// Data Point46
data.push (110584847.139072);
// Data Point47
data.push (110584847.139072);
// Data Point48
data.push (110584847.139072);
// Data Point49
data.push (112133197.66979);
// Data Point50
data.push (112133197.66979);
// Data Point51
data.push (112133197.66979);
// Data Point52
data.push (112133197.66979);
// Data Point53
data.push (112133197.66979);
// Data Point54
data.push (112133197.66979);
// Data Point55
data.push (112133197.66979);
// Data Point56
data.push (112133197.66979);
// Data Point57
data.push (112133197.66979);
// Data Point58
data.push (112133197.66979);
// Data Point59
data.push (112133197.66979);
// Data Point60
data.push (112133197.66979);


        }  
  
        return data;
     }, 
     getActualCostBase (index)
     {
        let data = new Array ();

        if (index == 1)
        {
          data.push (106065771); 
        }
  
        return data;
     },
     getVarianceCost(index)
     {      
        let data = new Array ();

        if (index == 1)
        {
         // Data Point1
data.push (106065770.959328);
// Data Point2
data.push (106065770.959328);
// Data Point3
data.push (106065770.959328);
// Data Point4
data.push (106065770.959328);
// Data Point5
data.push (106065770.959328);
// Data Point6
data.push (106065770.959328);
// Data Point7
data.push (106065770.959328);
// Data Point8
data.push (106065770.959328);
// Data Point9
data.push (106065770.959328);
// Data Point10
data.push (106065770.959328);
// Data Point11
data.push (106065770.959328);
// Data Point12
data.push (106065770.959328);
// Data Point13
data.push (107527811.71942);
// Data Point14
data.push (107527811.71942);
// Data Point15
data.push (107527811.71942);
// Data Point16
data.push (107527811.71942);
// Data Point17
data.push (107527811.71942);
// Data Point18
data.push (107527811.71942);
// Data Point19
data.push (107527811.71942);
// Data Point20
data.push (107527811.71942);
// Data Point21
data.push (107527811.71942);
// Data Point22
data.push (107527811.71942);
// Data Point23
data.push (107527811.71942);
// Data Point24
data.push (107527811.71942);
// Data Point25
data.push (109053951.630627);
// Data Point26
data.push (109053951.630627);
// Data Point27
data.push (109053951.630627);
// Data Point28
data.push (109053951.630627);
// Data Point29
data.push (109053951.630627);
// Data Point30
data.push (109053951.630627);
// Data Point31
data.push (109053951.630627);
// Data Point32
data.push (109053951.630627);
// Data Point33
data.push (109053951.630627);
// Data Point34
data.push (109053951.630627);
// Data Point35
data.push (109053951.630627);
// Data Point36
data.push (109053951.630627);
// Data Point37
data.push (110584847.139072);
// Data Point38
data.push (110584847.139072);
// Data Point39
data.push (110584847.139072);
// Data Point40
data.push (110584847.139072);
// Data Point41
data.push (110584847.139072);
// Data Point42
data.push (110584847.139072);
// Data Point43
data.push (110584847.139072);
// Data Point44
data.push (110584847.139072);
// Data Point45
data.push (110584847.139072);
// Data Point46
data.push (110584847.139072);
// Data Point47
data.push (110584847.139072);
// Data Point48
data.push (110584847.139072);
// Data Point49
data.push (112133197.66979);
// Data Point50
data.push (112133197.66979);
// Data Point51
data.push (112133197.66979);
// Data Point52
data.push (112133197.66979);
// Data Point53
data.push (112133197.66979);
// Data Point54
data.push (112133197.66979);
// Data Point55
data.push (112133197.66979);
// Data Point56
data.push (112133197.66979);
// Data Point57
data.push (112133197.66979);
// Data Point58
data.push (112133197.66979);
// Data Point59
data.push (112133197.66979);
// Data Point60
data.push (112133197.66979);


        }
  
  
        return data;
     },
     getTargetSupplyChain(index)
     {
        let data = new Array ();
        
        
        if (index == 1)
        {

          // Data Point1
data.push (14560711.9975026);
// Data Point2
data.push (14560711.9975026);
// Data Point3
data.push (14560711.9975026);
// Data Point4
data.push (14560711.9975026);
// Data Point5
data.push (14560711.9975026);
// Data Point6
data.push (14560711.9975026);
// Data Point7
data.push (14560711.9975026);
// Data Point8
data.push (14560711.9975026);
// Data Point9
data.push (14560711.9975026);
// Data Point10
data.push (14560711.9975026);
// Data Point11
data.push (14560711.9975026);
// Data Point12
data.push (14560711.9975026);
// Data Point13
data.push (14760622.9787448);
// Data Point14
data.push (14760622.9787448);
// Data Point15
data.push (14760622.9787448);
// Data Point16
data.push (14760622.9787448);
// Data Point17
data.push (14760622.9787448);
// Data Point18
data.push (14760622.9787448);
// Data Point19
data.push (14760622.9787448);
// Data Point20
data.push (14760622.9787448);
// Data Point21
data.push (14760622.9787448);
// Data Point22
data.push (14760622.9787448);
// Data Point23
data.push (14760622.9787448);
// Data Point24
data.push (14760622.9787448);
// Data Point25
data.push (14961384.6132176);
// Data Point26
data.push (14961384.6132176);
// Data Point27
data.push (14961384.6132176);
// Data Point28
data.push (14961384.6132176);
// Data Point29
data.push (14961384.6132176);
// Data Point30
data.push (14961384.6132176);
// Data Point31
data.push (14961384.6132176);
// Data Point32
data.push (14961384.6132176);
// Data Point33
data.push (14961384.6132176);
// Data Point34
data.push (14961384.6132176);
// Data Point35
data.push (14961384.6132176);
// Data Point36
data.push (14961384.6132176);
// Data Point37
data.push (15170509.9365915);
// Data Point38
data.push (15170509.9365915);
// Data Point39
data.push (15170509.9365915);
// Data Point40
data.push (15170509.9365915);
// Data Point41
data.push (15170509.9365915);
// Data Point42
data.push (15170509.9365915);
// Data Point43
data.push (15170509.9365915);
// Data Point44
data.push (15170509.9365915);
// Data Point45
data.push (15170509.9365915);
// Data Point46
data.push (15170509.9365915);
// Data Point47
data.push (15170509.9365915);
// Data Point48
data.push (15170509.9365915);
// Data Point49
data.push (15378645.0963301);
// Data Point50
data.push (15378645.0963301);
// Data Point51
data.push (15378645.0963301);
// Data Point52
data.push (15378645.0963301);
// Data Point53
data.push (15378645.0963301);
// Data Point54
data.push (15378645.0963301);
// Data Point55
data.push (15378645.0963301);
// Data Point56
data.push (15378645.0963301);
// Data Point57
data.push (15378645.0963301);
// Data Point58
data.push (15378645.0963301);
// Data Point59
data.push (15378645.0963301);
// Data Point60
data.push (15378645.0963301);

         }
  
        return data;
     },
     getThirdLabel ()
     {
        return "Optimized trajectories";
     },
    
     getActualSupplyChain(index)
     {
        let data = new Array ();

        if (index == 1)
        {  

          data.push (14560712);
         }
  
  
        return data;
     },
     getVarianceIncome(index)
     {
      let data = new Array ();

      if (index == 1)
      {
          // Data Point1
data.push (162358333.333333);
// Data Point2
data.push (162358333.333333);
// Data Point3
data.push (162358333.333333);
// Data Point4
data.push (162358333.333333);
// Data Point5
data.push (162358333.333333);
// Data Point6
data.push (162358333.333333);
// Data Point7
data.push (162358333.333333);
// Data Point8
data.push (162358333.333333);
// Data Point9
data.push (162358333.333333);
// Data Point10
data.push (162358333.333333);
// Data Point11
data.push (162358333.333333);
// Data Point12
data.push (162358333.333333);
// Data Point13
data.push (161221825);
// Data Point14
data.push (161221825);
// Data Point15
data.push (161221825);
// Data Point16
data.push (161221825);
// Data Point17
data.push (161221825);
// Data Point18
data.push (161221825);
// Data Point19
data.push (161221825);
// Data Point20
data.push (161221825);
// Data Point21
data.push (161221825);
// Data Point22
data.push (161221825);
// Data Point23
data.push (161221825);
// Data Point24
data.push (161221825);
// Data Point25
data.push (161221825);
// Data Point26
data.push (161221825);
// Data Point27
data.push (161221825);
// Data Point28
data.push (161221825);
// Data Point29
data.push (161221825);
// Data Point30
data.push (161221825);
// Data Point31
data.push (161221825);
// Data Point32
data.push (161221825);
// Data Point33
data.push (161221825);
// Data Point34
data.push (161221825);
// Data Point35
data.push (161221825);
// Data Point36
data.push (161221825);
// Data Point37
data.push (164329617.850938);
// Data Point38
data.push (164329617.850938);
// Data Point39
data.push (164329617.850938);
// Data Point40
data.push (164329617.850938);
// Data Point41
data.push (164329617.850938);
// Data Point42
data.push (164329617.850938);
// Data Point43
data.push (164329617.850938);
// Data Point44
data.push (164329617.850938);
// Data Point45
data.push (164329617.850938);
// Data Point46
data.push (164329617.850938);
// Data Point47
data.push (164329617.850938);
// Data Point48
data.push (164329617.850938);
// Data Point49
data.push (166764524.555732);
// Data Point50
data.push (166764524.555732);
// Data Point51
data.push (166764524.555732);
// Data Point52
data.push (166764524.555732);
// Data Point53
data.push (166764524.555732);
// Data Point54
data.push (166764524.555732);
// Data Point55
data.push (166764524.555732);
// Data Point56
data.push (166764524.555732);
// Data Point57
data.push (166764524.555732);
// Data Point58
data.push (166764524.555732);
// Data Point59
data.push (166764524.555732);
// Data Point60
data.push (166764524.555732);


      }

      return data;
     },
     getVarianceSupplyChain(index)
     {
        let data = new Array ();

        if (index == 1)
        {
            // Data Point1
data.push (14560711.9975026);
// Data Point2
data.push (14560711.9975026);
// Data Point3
data.push (14560711.9975026);
// Data Point4
data.push (14560711.9975026);
// Data Point5
data.push (14560711.9975026);
// Data Point6
data.push (14560711.9975026);
// Data Point7
data.push (14560711.9975026);
// Data Point8
data.push (14560711.9975026);
// Data Point9
data.push (14560711.9975026);
// Data Point10
data.push (14560711.9975026);
// Data Point11
data.push (14560711.9975026);
// Data Point12
data.push (14560711.9975026);
// Data Point13
data.push (14760622.9787448);
// Data Point14
data.push (14760622.9787448);
// Data Point15
data.push (14760622.9787448);
// Data Point16
data.push (14760622.9787448);
// Data Point17
data.push (14760622.9787448);
// Data Point18
data.push (14760622.9787448);
// Data Point19
data.push (14760622.9787448);
// Data Point20
data.push (14760622.9787448);
// Data Point21
data.push (14760622.9787448);
// Data Point22
data.push (14760622.9787448);
// Data Point23
data.push (14760622.9787448);
// Data Point24
data.push (14760622.9787448);
// Data Point25
data.push (14961384.6132176);
// Data Point26
data.push (14961384.6132176);
// Data Point27
data.push (14961384.6132176);
// Data Point28
data.push (14961384.6132176);
// Data Point29
data.push (14961384.6132176);
// Data Point30
data.push (14961384.6132176);
// Data Point31
data.push (14961384.6132176);
// Data Point32
data.push (14961384.6132176);
// Data Point33
data.push (14961384.6132176);
// Data Point34
data.push (14961384.6132176);
// Data Point35
data.push (14961384.6132176);
// Data Point36
data.push (14961384.6132176);
// Data Point37
data.push (15170509.9365915);
// Data Point38
data.push (15170509.9365915);
// Data Point39
data.push (15170509.9365915);
// Data Point40
data.push (15170509.9365915);
// Data Point41
data.push (15170509.9365915);
// Data Point42
data.push (15170509.9365915);
// Data Point43
data.push (15170509.9365915);
// Data Point44
data.push (15170509.9365915);
// Data Point45
data.push (15170509.9365915);
// Data Point46
data.push (15170509.9365915);
// Data Point47
data.push (15170509.9365915);
// Data Point48
data.push (15170509.9365915);
// Data Point49
data.push (15378645.0963301);
// Data Point50
data.push (15378645.0963301);
// Data Point51
data.push (15378645.0963301);
// Data Point52
data.push (15378645.0963301);
// Data Point53
data.push (15378645.0963301);
// Data Point54
data.push (15378645.0963301);
// Data Point55
data.push (15378645.0963301);
// Data Point56
data.push (15378645.0963301);
// Data Point57
data.push (15378645.0963301);
// Data Point58
data.push (15378645.0963301);
// Data Point59
data.push (15378645.0963301);
// Data Point60
data.push (15378645.0963301);


        }
  
      if (index == 2)
      {
          for (var i= 0; i < 52; i++)
          {
            data.push(i);
          }

      }
  
        return data;
  
     },
     
  
  
     fillData(tileValue) 
      {
        this.activeSummary = tileValue;
  
        this.activeTitle = "Assets (5 Year) 60 Month Tracker";

        this.incomecollection60month = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              label: 'Target Total Income',
              data:  this.getTargetIncome(1),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,            },
            {
              label: 'Actual Total Income',
              data: this.getActualIncome(1),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getVarianceIncome(1),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"],              
              tension: 0.1,
            }
          ],
        };
  
        this.dataBedscollection60month = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              label: 'Target Beds Occupied',
              data:  this.getTargetBedsOcuppied(1),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,            },
            {
              label: 'Actual Beds Occupied',
              data: this.getActualBedsOccupied(1),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getActualBedsVariance(1),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"],              
              tension: 0.1,
            }
          ],
        };


       /* this.dataBedscollection12month = {
            labels:  this.getFiftyTwoLabels(),
            datasets: [
            {
              label: 'Target Beds Occupied',
              data:  this.getTargetBedsOcuppied(2),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,            },
            {
              label: 'Actual Beds Occupied',
              data: this.getActualBedsOccupied(2),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getActualBedsVariance(2),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"],              
              tension: 0.1,
            }
          ],
        };*/

/*        this.dataBedscollection1month = {
            labels:  this.getFiftyTwoLabels(),
            datasets: [
            {
              label: 'Target Beds Occupied',
              data:  this.getTargetBedsOcuppied(3),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,            },
            {
              label: 'Actual Beds Occupied',
              data: this.getActualBedsOccupied(3),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getActualBedsVariance(3),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"],              
              tension: 0.1,
            }
          ],
        };*/
  
  
        this.dataAssetsUtilisationcollection60month = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              label: 'Target Assets Utilised',
              data:  this.getTargetAssetsUtilised(1),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,            },
            {
              label: 'Actual Assets Utilised',
              data: this.getActualAssetsUtilised(1),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getAssetsVariance(1),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"],              
              tension: 0.1,
            }
          ],
        };

     /*   this.dataAssetsUtilisationcollection12month = {
            labels:  this.getFiftyTwoLabels(),
            datasets: [
            {
              label: 'Target Assets Utilised',
              data:  this.getTargetAssetsUtilised(2),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,            },
            {
              label: 'Actual Assets Utilised',
              data: this.getActualAssetsUtilised(2),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getAssetsVariance(2),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"],              
              tension: 0.1,
            }
          ],
        };*/

        /*this.dataAssetsUtilisationcollection1month = {
            labels:  this.getFiftyTwoLabels(),
            datasets: [
            {
              label: 'Target Assets Utilised',
              data:  this.getTargetAssetsUtilised(3),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,            },
            {
              label: 'Actual Assets Utilised',
              data: this.getActualAssetsUtilised(3),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getAssetsVariance(3),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"],              
              tension: 0.1,
            }
          ],
        };*/

        /*this.dataAssetsUtilisationcollection12month = {
            labels:  this.getFiftyTwoLabels(),
            datasets: [
            {
              label: 'Target Assets Utilised',
              data:  this.getTargetAssetsUtilised(2),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,            },
            {
              label: 'Actual Assets Utilised',
              data: this.getActualAssetsUtilised(2),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getAssetsVariance(2),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"],              
              tension: 0.1,
            }
          ],
        };*/
  
        this.dataOperatingcollection60month = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              label: 'Target Intervention Rooms Utilised',
              data:  this.getTargetOperatingRoomsUtilised(1),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Actual Intervention Rooms Utilised',
              data: this.getActualOperatingRoomsUtilised(1),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getOperatingRoomsVariance(1),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"],              
              tension: 0.1,
            }
          ],
        };

        /*this.dataOperatingcollection12month = {
            labels:  this.getFiftyTwoLabels(),
            datasets: [
            {
              label: 'Target Operating Rooms Utilised',
              data:  this.getTargetOperatingRoomsUtilised(2),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Actual Operating Rooms Utilised',
              data: this.getActualOperatingRoomsUtilised(2),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getOperatingRoomsVariance(2),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"],              
              tension: 0.1,
            }
          ],
        };


        this.dataOperatingcollection1month = {
            labels:  this.getFiftyTwoLabels(),
            datasets: [
            {
              label: 'Target Operating Rooms Utilised',
              data:  this.getTargetOperatingRoomsUtilised(3),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Actual Operating Rooms Utilised',
              data: this.getActualOperatingRoomsUtilised(3),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getOperatingRoomsVariance(3),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"],              
              tension: 0.1,
            }
          ],
        };
      
  */
    
          this.patientcollection60month = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              type: 'line',
              label: 'Target Episode Productivity',
              data:  this.getPatientEpisodeProductivityUtilisationTarget(1),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], 
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              type: 'line',
              label: 'Actual Episode Productivity',
              data: this.getPatientEpisodeProductivityUtilisationActual(1),              
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], 
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              type: 'line',
              label: this.getThirdLabel(),
              data: this.getPatientEpisodeProductivityUtilisationVariance(1),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], 
              borderColor: ["rgb(255, 205, 86)"],              
              tension: 0.1,
            }
          ],
        };
      

    /*     this.patientcollection12month = {
            labels:  this.getFiftyTwoLabels (),
            datasets: [
            {
              label: 'Target Episode Productivity',
              data:  this.getPatientEpisodeProductivityUtilisationTarget(2),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Actual Episode Productivity',
              data: this.getPatientEpisodeProductivityUtilisationActual(2),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getPatientEpisodeProductivityUtilisationVariance(2),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"],              
              tension: 0.1,
            }
          ],
        };

        this.patientcollection1month = {
            labels:  this.getFiftyTwoLabels (),
            datasets: [
            {
              label: 'Target Episode Productivity',
              data:  this.getPatientEpisodeProductivityUtilisationTarget(3),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Actual Episode Productivity',
              data: this.getPatientEpisodeProductivityUtilisationActual(3),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getPatientEpisodeProductivityUtilisationVariance(3),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"],              
              tension: 0.1,
            }
          ],
        };*/
      
  
       this.workforceFTEcollection60month = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              label: 'Total Workforce FTE Employed',
              data:  this.getTotalWorkforceFTEEmployed(1),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Actual Workforce FTE Employed',
              data: this.getActualWorkforceFTEEmployed(1),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getWorkforceFTEVariance(1),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"], 
              tension: 0.1,
            }
          ],
        };

        /*this.dataWorkforceFTEcollection12month = {
            labels:  this.getFiftyTwoLabels(),
            datasets: [
            {
              label: 'Total Workforce FTE Employed',
              data:  this.getTotalWorkforceFTEEmployed(2),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Actual Workforce FTE Employed',
              data: this.getActualWorkforceFTEEmployed(2),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getWorkforceFTEVariance(2),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"], 
              tension: 0.1,
            }
          ],
        };

        this.dataWorkforceFTEcollection1month = {
            labels:  this.getFiftyTwoLabels(),
            datasets: [
            {
              label: 'Total Workforce FTE Employed',
              data:  this.getTotalWorkforceFTEEmployed(3),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Actual Workforce FTE Employed',
              data: this.getActualWorkforceFTEEmployed(3),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getWorkforceFTEVariance(3),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"], 
              tension: 0.1,
            }
          ],
        };*/
  
  
  
        this.workforceMedicalStaffcollection60month = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              label: 'Target Medical Staff FTE Employed',
              data:  this.getTargetMedcialFTEEmployed(1),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Actual Medical Staff FTE Employed',
              data: this.getActualMedicalFTEEmployed(1),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getMedicalFTEVariance(1),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"], 
              tension: 0.1,
            }
          ],
        };

       
  
        this.workforceNursingcollection60month = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              label: 'Target Nursing Staff FTE Employed',
              data:  this.getTargetNursingFTEEmployed(1),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Actual Nursing Staff FTE Employed',
              data: this.getActualNursingFTEEmployed(1),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getNursingFTEVariance(1),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"], 
              tension: 0.1,
            }
          ],
        };


     
        // workforceAdministrorcollection
        this.workforceAdminCollection60month = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              label: 'Target Administrtor Staff FTE Employed',
              data:  this.getTargetAdministratorFTEEmployed(1),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Actual Administrator Staff FTE Employed',
              data: this.getActualAdministratorFTEEmployed(1),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getAdministratorFTEVariance(1),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"], 
              tension: 0.1,
            }
          ],
        };

       
  
        this.costFinanceCollection60month = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              label: 'Target Total Cost Budget',
              data:  this.getTargetCostBudgget(1),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Actual Total Cost-Base',
              data: this.getActualCostBase(1),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getVarianceCost(1),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"], 
              tension: 0.1,
            }
          ],
        };
  
        this.suppyChainFinanceCollection60month = {
            labels:  this.getSixtyMonthLabels(),
            datasets: [
            {
              label: 'Target Supply Chain Cost',
              data:  this.getTargetSupplyChain(1),
              backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
              borderColor: ["rgb(255, 99, 132)"],
              tension: 0.1,
            },
            {
              label: 'Actual Supply Chain Cost',
              data: this.getActualSupplyChain(1),
              backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 1
              borderColor: ["rgb(54, 162, 235)"],
              pointHoverRadius: 8,
              pointRadius: 8,
            },
            {
              label: this.getThirdLabel(),
              data: this.getVarianceSupplyChain(1),
              backgroundColor: ["rgba(255, 205, 86, 0.2)"], // line 1
              borderColor: ["rgb(255, 205, 86)"], 
              tension: 0.1,
            }
          ],
        };        
    },
      fetchData(speciality) {
        console.log("SpecialitiesMain.Vue: fetchData (", speciality);
         }
    },
  };
  </script>
  <style>
  .chartcontainer {
    border: 1px solid;
    border-color: #e5e9f2;
    height: 400px;
    display: block;
    width: 300px; /* was 26 rem */
    margin-right: 20px;
    padding-bottom: 5px;
    padding-top: 0px;
    margin-bottom: 20px;
  }
  .title_text {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  </style>
  
